<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <div class="row">
      <div class="col-sm-12 text-center"><span style="font-weight: bold; font-size: 3em;">Claim Creation Page</span>
      </div>
    </div>
  </card>
  <card>
    <div class="row">
      <div class="col-sm-4">
        <label>I am the: *</label>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-sm-4">
        <select class="form-control" type="select" v-model="claim.customer.claimant">
          <option value="Customer">Customer</option>
          <option value="Service Advisor">Service Advisor</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        Customer Information
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <fg-input label="First Name" placeholder="First Name" v-model="claim.customer.first"></fg-input>
      </div>
      <div class="col-sm-3">
        <fg-input label="Last Name" placeholder="Last Name" v-model="claim.customer.last"></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <fg-input label="Address1" placeholder="Address 1" v-model="claim.customer.address1"></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <fg-input label="Address2" placeholder="Address 2" v-model="claim.customer.address2"></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <fg-input label="City" placeholder="City 2" v-model="claim.customer.city"></fg-input>
      </div>
      <div class="col-sm-2">
        <label>State/Province: &nbsp;</label>
        <select class="form-control" v-model="claim.customer.state">
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <fg-input label="Zip/Postal Code" placeholder="Zip/Postal Code" v-model="claim.customer.zip"></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <fg-input label="Country" placeholder="Country" v-model="claim.customer.country"></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <fg-input label="Phone" placeholder="Phone" v-model="claim.customer.home"></fg-input>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-sm-6">
        <fg-input label="Email" placeholder="Email" v-model="claim.customer.email"></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        Vehicle Information
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <fg-input label="VIN" placeholder="VIN" v-model="claim.car.vin"></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <fg-input label="Make" placeholder="Make" v-model="claim.car.make"></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <fg-input label="Model" placeholder="Model" v-model="claim.car.model"></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <fg-input label="Year" placeholder="Year" v-model="claim.car.year"></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <fg-input label="Xzilon Contract Number" placeholder="Xzilon Contract Number" v-model="claim.car.warranty_num"></fg-input>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-sm-6">
        <fg-input label="Car Mileage" placeholder="Car Mileage" v-model="claim.car.mileage"></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        Claim Description Information
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        Select Damage Type
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        Select all that apply
      </div>
    </div>
    <div class="row" v-for="damagetype in damageTypes" v-bind:key="`A-${damagetype.id}`">
      <div class="col-sm-2">
        <label>{{ damagetype.name }}:&nbsp;</label>
      </div>
      <div class="col-sm-4">
        <input type="checkbox" v-model="claim.damageTypesSelected" :value="damagetype.id">
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-sm-6">
        Select Damage Location
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        Select all that apply
      </div>
    </div>
    <div class="row" v-for="damagelocation in damageLocations" v-bind:key="`B-${damagelocation.id}`">
      <div class="col-sm-2">
        <label>{{ damagelocation.name }}:&nbsp;</label>
      </div>
      <div class="col-sm-4">
        <input type="checkbox" v-model="claim.damageLocationsSelected" :value="damagelocation.id">
      </div>
    </div>
    <div class="row pt-5 pb-5">
      <div class="col-sm-4">
        <label>Select Claim Date:</label>
        <datepicker
          :value="claim.currentdate"
          @input="logdateSelected"
          :bootstrap-styling="true"
        >
          <div slot="beforeCalendarHeader" class="calender-header">
          </div>
        </datepicker>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <label>Enter the damage description:</label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <textarea class="form-control" cols="50" rows="12" v-model="claim.damageDescription"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <fg-input label="Signature" v-model="claim.signature" placeholder="Signature"></fg-input>
      </div>
    </div>
    <div class="col-sm-6 text-center">
      <button class="btn btn-primary" @click="createClaim">Create Claim</button>
    </div>
  </card>

  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.basic">
    <div class="text-center">
      <span>Success: {{ success_text }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-success" @click="closeModal('basic')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { Dialog } from 'element-ui';
import ClaimsAPIService from "../../../../servicehandlers/ClaimsHandler";
const claimAPIService = new ClaimsAPIService();

export default {
  name: "ClaimCreation",
  components: {
    [Dialog.name]: Dialog,
    Datepicker
  },
  data() {
    return {
      claim: {
        damageDescription: null,
        damageTypesSelected: [],
        damageLocationsSelected: [],
        currentdate: null,
        signature: null,
        customer: {
          claimant: 'Customer',
          first: null,
          billing_first: null,
          billing_last: null,
          address1: null,
          address2: null,
          city: null,
          state: null,
          zip: null,
          country: null,
          email: null,
          home: null,
          cell: null,
          addresstype: null,
          contact_number: null,
          contact_preference: null,
        },
        car: {
          vin: null,
          make: null,
          model: null,
          year: null,
          mileage: null,
          newused: null,
          warranty_num: null,
        }
      },
      damageTypes: [],
      damageLocations: [],
      error_txt: null,
      success_text: null,
      spinning: false,
      modals: {
        basic: false,
        error: false,
      },
    };
  },
  mounted() {
    this.getDamageTypes();
    this.getDamageLocations();
  },
  methods: {
    openErrorModal(error) {
      this.error_txt = 'Error: ' + error;
      this.openModal('error');
    },

    createClaim() {
      const claim = {
        api_key: process.env.VUE_APP_CLAIM_API_KEY,
        first: this.claim.customer.first,
        last: this.claim.customer.last,
        claimant: this.claim.customer.claimant,
        phone: this.claim.customer.home,
        email: this.claim.customer.email,
        address: this.claim.customer.address1,
        vin: this.claim.car.vin,
        make: this.claim.car.make,
        model: this.claim.car.model,
        mileage: this.claim.car.mileage,
        year: this.claim.car.year,
        contract_no: this.claim.car.warranty_num,
        damage_date: moment(this.claim.currentdate).utc().format('MM/DD/YYYY'),
        damage_description: this.claim.damageDescription,
        damageTypes: this.claim.damageTypesSelected,
        damageLocations: this.claim.damageLocationsSelected,
        claimtype: 'UNUSED',
        signature: this.claim.signature,
        city: this.claim.customer.city,
        state: this.claim.customer.state,
        zip: this.claim.customer.zip,
      };

      if (!this.claim.customer.first) {
        this.openErrorModal('No customer first name supplied');
        return;
      }
      if (!this.claim.customer.last) {
        this.openErrorModal('No customer last name supplied');
        return;
      }
      if (!this.claim.customer.email) {
        this.openErrorModal('No customer email address supplied');
        return;
      }
      if (!this.claim.customer.address1) {
        this.openErrorModal('No customer address supplied');
        return;
      }
      if (!this.claim.car.vin) {
        this.openErrorModal('No car vin supplied');
        return;
      }
      if (!this.claim.car.make) {
        this.openErrorModal('No car make supplied');
        return;
      }
      if (!this.claim.car.model) {
        this.openErrorModal('No car model supplied');
        return;
      }
      if (!this.claim.car.year) {
        this.openErrorModal('No car year supplied');
        return;
      }
      if (!this.claim.car.mileage) {
        this.openErrorModal('No car year supplied');
        return;
      }
      if (!this.claim.currentdate) {
        this.openErrorModal('No claim date was supplied');
        return;
      }
      if (!this.claim.damageDescription) {
        this.openErrorModal('No damage description was supplied');
        return;
      }
      if (!this.claim.signature) {
        this.openErrorModal('No signature was supplied');
        return;
      }
      return claimAPIService.createClaim(claim, this.$router)
      .then((response) => {
        this.success_text = 'Successfully created claim';
        this.openModal('basic');
        this.claim = {
          damageDescription: null,
            damageTypesSelected: [],
            damageLocationsSelected: [],
            currentdate: null,
            signature: null,
            customer: {
            claimant: 'Customer',
              first: null,
              billing_first: null,
              billing_last: null,
              address1: null,
              address2: null,
              city: null,
              state: null,
              zip: null,
              country: null,
              email: null,
              home: null,
              cell: null,
              addresstype: null,
              contact_number: null,
              contact_preference: null,
          },
          car: {
            vin: null,
              make: null,
              model: null,
              year: null,
              mileage: null,
              newused: null,
              warranty_num: null,
          }
        };
      })
      .catch((error) => {
        this.error_txt = 'Failed to create claim: ' + error;
        this.openModal('error');
      });
    },
    logdateSelected(date) {
      this.claim.currentdate = date;
    },
    customFormatter(date) {
      return moment(date).local().format('YYYY-MM-DD');
    },
    getDamageTypes() {
      return claimAPIService.getDamageTypes(this.$router)
      .then((response) => {
        this.damageTypes = response;
      })
      .catch((error) => {
        this.error_txt = 'Failed to get damage types: ' + error;
        this.openModal('error');
      });
    },
    getDamageLocations() {
      return claimAPIService.getDamageLocations(this.$router)
      .then((response) => {
        this.damageLocations = response;
      })
      .catch((error) => {
        this.error_txt = 'Failed to get damage locations: ' + error;
        this.openModal('error');
      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  },
}
</script>

<style scoped>

</style>
