<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <div class="row">
      <div class="col-sm-12 text-center">
        <span style="font-size: 2em; font-weight: bold;">Scope Creation</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <label>Scope Name:</label>
        <fg-input placeholder="Scope Name" v-model="scope_name"></fg-input>
      </div>
      <div class="col-sm-6">
        <label>Scope Description:</label>
        <fg-input placeholder="Scope Description" v-model="scope_description"></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4"></div>
      <div class="col-sm-4 text-center">
        <button class="btn btn-primary" @click="addScope">Create Scope</button>
      </div>
      <div class="col-sm-4"></div>
    </div>
  </card>
  <card>
    <div class="col-sm-12 text-center">
      <span style="font-size: 2em; font-weight: bold;">Scope Association</span>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <label>Find Auth Group:</label>
        <model-select class="form-control select-primary" :options="authGroups"
                      v-model="selected_authgroup_id"
                      placeholder="Select an Auth Group"
                      @input="selectAuthgroup">
        </model-select>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-sm-12">
        <label>Select the scope(s) to add to the group:</label>
        <multi-select :options="scopes"
                      :selected-options="selected_scope_ids"
                      @select="onSelect"
                      placeholder="Select scopes to add to authgroup">
        </multi-select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4"><button class="btn btn-primary" @click="saveScopes">Save Scopes</button></div>
      <div class="col-sm-4"></div>
      <div class="col-sm-4"><button class="btn btn-primary" @click="clear">Clear</button></div>
    </div>
  </card>
  <card>
    <div class="row">
      <div class="col-sm-12 text-center">
        <span style="font-size: 2em; font-weight: bold;">Scope Deletion</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4"><span style="font-weight: bold; font-size: 1em;">Name</span></div>
      <div class="col-sm-4"><span style="font-weight: bold; font-size: 1em;">Description</span></div>
      <div class="col-sm-4"><span style="font-weight: bold; font-size: 1em;">Action</span></div>
    </div>
    <div class="row" v-for="scope in scopes" v-bind:key="scope.id">
      <div class="col-sm-4"><label>{{ scope.name }}</label></div>
      <div class="col-sm-4"><label>{{ scope.description }}</label></div>
      <div class="col-sm-4"><button class="btn btn-danger" @click="confirmDelete(scope.id, scope.name)">-</button></div>
    </div>
  </card>

  <el-dialog
    center
    title="Confirm Deletion of Scope"
    :visible.sync="modals.basic">
    <span>Are you sure you want to delete the scope: {{ delete_scope_name }}?</span>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal('basic')">Cancel</button>&nbsp;
              <button class="btn btn-success" @click="deleteScope()">Confirm</button>
            </span>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>Success: {{ success_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { Dialog } from 'element-ui';
import { ModelSelect, MultiSelect } from 'vue-search-select';
import { ScopesAPIService } from 'src/servicehandlers/archived_handlers/ScopesAPIService';
import { AuthGroupAPIService } from 'src/servicehandlers/archived_handlers/AuthGroupAPIService';
const scopesAPIService = new ScopesAPIService();
const authGroupAPIService = new AuthGroupAPIService();

export default {
  components: {
    [Dialog.name]: Dialog,
    ModelSelect,
    MultiSelect
  },
  name: "AuthGroupsScopesAdmin",
  data() {
    return {
      spinning: false,
      scope_name: null,
      authGroups: [],
      scope_description: null,
      delete_scope_id: null,
      delete_scope_name: null,
      selected_authgroup_id: null,
      last_selected_item: null,
      selected_scope_ids: [],
      modals: {
        error: false,
        success: false,
        basic: false,
      },
      scopes: [],
      success_txt: '',
      error_txt: null,
    };
  },
  created() {
    this.getAllScopes();
    this.getAllAuthgroups();
  },
  methods: {
    saveScopes() {
      this.spinning = true;
      if (!this.selected_authgroup_id) {
        this.spinning = false;
        this.error_txt = 'No authgroup was selected!';
        this.openModal('error');
        return;
      }
      if (this.selected_scope_ids.length <= 0) {
        this.spinning = false;
        this.error_txt = 'No scopes were selected!';
        this.openModal('error');
        return;
      }

      return scopesAPIService.addScopesToAuthgroup(this.selected_authgroup_id, this.selected_scope_ids, this.$router)
      .then(() => {
        this.clear();
        this.getAllScopes();
        this.getAllAuthgroups();
        this.spinning = false;
        this.success_txt = 'Successfully added scopes to authgroup!';
        this.openModal('success');
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'An error occurred while adding scopes to groups: ' + error;
        this.openModal('error');
      });
    },
    clear() {
      this.selected_authgroup_id = null;
      this.selected_scope_ids = [];
    },

    selectAuthgroup() {
      // get the full user by ID with scopes, etc, from the DB
      this.spinning = true;
      return authGroupAPIService.getAuthGroupsWithScope(this.selected_authgroup_id, this.$router)
      .then((selectedAuthgroup) => {
        this.spinning = false;
        const allScopes = selectedAuthgroup.scopes;
        for (const scope of allScopes) {
          scope.text = scope.name;
          scope.value = scope.id;
        }
        this.selected_scope_ids = allScopes;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting scopes for auth group: ' + error;
        this.openModal('error');
      });
    },
    getAllAuthgroups() {
      this.spinning = true;
      return authGroupAPIService.getAuthGroups(this.$router)
      .then((agps) => {
        this.authGroups = agps;
        for (const group of this.authGroups) {
          group.text = group.name;
          group.value = group.id;
        }
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = error;
        this.spinning = false;
        this.openModal('error');
      });
    },
    onSelect (items, lastSelectItem) {
      this.selected_scope_ids = items
      this.lastSelectItem = lastSelectItem
    },
    confirmDelete(scope_id, scope_name,) {
      this.delete_scope_id = scope_id;
      this.delete_scope_name = scope_name;
      this.openModal('basic');
    },
    deleteScope() {
      this.closeModal('basic');
      this.spinning = true;
      if (!this.delete_scope_id) {
        this.spinning = false;
        this.error_txt = 'No scope was selected';
        this.openModal('error');
        return;
      }

      return scopesAPIService.deleteScope(this.delete_scope_id)
      .then(() => {
        this.getAllScopes();
        this.getAllAuthgroups();
        this.spinning = false;
        this.success_txt = 'Successfully deleted scope!';
        this.openModal('success');
        this.delete_scope_id = null;
        this.delete_scope_name = null;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Scope deletion failed: ' + error;
        this.openModal('error');
      });
    },
    addScope() {
      this.spinning = true;
      if (!this.scope_name) {
        this.spinning = false;
        this.error_txt = 'No scope name set';
        this.openModal('error');
        return;
      }
      if (!this.scope_description) {
        this.spinning = false;
        this.error_txt = 'No scope description set';
        this.openModal('error');
        return;
      }

      const scopeData = {
        name: this.scope_name,
        description: this.scope_description,
      };

      return scopesAPIService.createScope(scopeData, this.$router)
      .then(() => {
        this.getAllScopes();
        this.getAllAuthgroups();
        this.spinning = false;
        this.success_txt = 'Successfully created scope!';
        this.openModal('success');
        this.scope_name = null;
        this.scope_description = null;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Scope creation failed: ' + error;
        this.openModal('error');
      });
    },

    getAllScopes() {
      this.spinning = true;
      return scopesAPIService.getScopes(this.$router)
      .then((response) => {
        this.spinning = false;
        this.scopes = response;
        for (const scope of this.scopes) {
          scope.text = scope.name;
          scope.value = scope.id;
        }
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting scopes!: ' + error;
        this.openModal('error');
      });
    },

    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
  }
}
</script>

<style scoped>

</style>
