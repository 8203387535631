<template>
  <div>
    <card v-if="spinning">
      <b-row class="text-center">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4 text-center">
          <label>Start Date</label>
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="Start Date"
            v-model="start_date">
          </datepicker>
        </div>
        <div class="col-sm-4 text-center">
          <label>End Date</label>
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="End Date"
            v-model="end_date">
          </datepicker>
        </div>
        <div class="col-sm-2">
          <label>Claim Status</label>
          <select v-model="selected_claim_status" class="form-control">
            <option value="ALL" selected>ALL</option>
            <option value="Approved">Approved</option>
            <option value="Authorized">Authorized</option>
            <option value="Pending">Pending</option>
            <option value="Denied">Denied</option>
            <option value="Paid">Paid</option>
            <option value="Closed">Closed</option>
          </select>
        </div>
        <div class="col-sm-2">
          <label>Claim Activity</label>
          <select name="Claim Activity" v-model="selected_claim_activity" class="form-control">
            <option selected="selected" value="ALL">ALL</option>
            <option value="C001">C001&nbsp;-&nbsp;Never&nbsp;Called&nbsp;Back</option>
            <option value="C002">C002&nbsp;-&nbsp;Customer&nbsp;Decline&nbsp;Repair</option>
            <option value="CLAIMACTAWAITINGW9">CLAIMACTAWAITINGW9&nbsp;-&nbsp;Awaiting&nbsp;W-9</option>
            <option value="CLAIMACTAWAITINV">CLAIMACTAWAITINV&nbsp;-&nbsp;Awaiting&nbsp;Invoice</option>
            <option value="CLAIMACTINVRECEIVED">CLAIMACTINVRECEIVED&nbsp;-&nbsp;Invoice&nbsp;received</option>
            <option value="CLAIMACTNEW">CLAIMACTNEW&nbsp;-&nbsp;New</option>
            <option value="CLAIMACTSCHEDULED">CLAIMACTSCHEDULED&nbsp;-&nbsp;Scheduled</option>
            <option value="CLMWAITREVIEW">CLMWAITREVIEW&nbsp;-&nbsp;Claim&nbsp;Awaiting&nbsp;Review</option>
            <option value="Denied">Denied&nbsp;-&nbsp;Denied</option>
            <option value="H001">H001&nbsp;-&nbsp;Waiting&nbsp;for&nbsp;Parts</option>
            <option value="INITIALINQUIRY">INITIALINQUIRY&nbsp;-&nbsp;Initial&nbsp;Inquiry</option>
            <option value="INSPECCOMPLT">INSPECCOMPLT&nbsp;-&nbsp;Inspection&nbsp;Complete</option>
            <option value="NOACTIVITY">NOACTIVITY&nbsp;-&nbsp;No&nbsp;Activity</option>
            <option value="NOTCOVERED">NOTCOVERED&nbsp;-&nbsp;Not&nbsp;Covered</option>
            <option value="P001">P001&nbsp;-&nbsp;Waiting&nbsp;for&nbsp;Approval</option>
            <option value="P002">P002&nbsp;-&nbsp;Verify&nbsp;Information</option>
            <option value="P003">P003&nbsp;-&nbsp;Waiting&nbsp;For&nbsp;Inspection</option>
            <option value="P004">P004&nbsp;-&nbsp;Requires&nbsp;Authorization</option>
            <option value="PAYMENTISSUED">PAYMENTISSUED&nbsp;-&nbsp;Payment&nbsp;Issued</option>
            <option value="REQPMT">REQPMT&nbsp;-&nbsp;Request&nbsp;Payment</option>
            <option value="SA">SA&nbsp;-&nbsp;Claim&nbsp;self-authorized</option>
            <option value="UPLOADROAPPR">UPLOADROAPPR&nbsp;-&nbsp;Upload&nbsp;RO&nbsp;for&nbsp;Approval
            </option>
            <option value="W002">W002&nbsp;-&nbsp;Close&nbsp;Due&nbsp;no&nbsp;Issues</option>
            <option value="WAITSUBATTCH">
              WAITSUBATTCH&nbsp;-&nbsp;Waiting&nbsp;for&nbsp;Sublet&nbsp;attachment
            </option>
          </select>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-sm-12 text-center">
          <button class="btn btn-primary" @click="getOpenClaims">Get Open Claims</button>
        </div>
      </div>
    </card>
    <card class="card-plain" v-if="is_ran">
      <div slot="header">
        <h4 class="card-title">Open Claim Results</h4>
        <p class="card-category">Here is a list of all open claims </p><button class="btn btn-primary" @click="downloadClaims">Download Excel</button>
      </div>
      <div class="table-striped table-full-width table-responsive">
        <el-table :data="reportData">
          <el-table-column width="120" label="Work Order Date" property="Work Order Date"></el-table-column>
          <el-table-column width="120" label="Inserted Date" property="Inserted Date"></el-table-column>
          <el-table-column width="120" label="Opened Date" property="Opened Date"></el-table-column>
          <el-table-column width="120" label="Claim Status" property="Claim Status"></el-table-column>
          <el-table-column width="120" label="Claim Number" property="Claim Number"></el-table-column>
          <el-table-column width="120" label="Contract Number" property="Contract Number"></el-table-column>
          <el-table-column width="120" label="Dealer Name" property="Dealer Name"></el-table-column>
          <el-table-column width="250" label="Customer" property="Customer"></el-table-column>
          <el-table-column width="250" label="VIN" property="VIN"></el-table-column>
          <el-table-column width="120" label="Claim Odometer" property="Claim Odometer"></el-table-column>
          <el-table-column width="200" label="Activity Code" property="Activity Code"></el-table-column>
          <el-table-column width="120" label="Assigned" property="Assigned"></el-table-column>
          <el-table-column width="250" class="long" label="Payee" property="Payee"></el-table-column>
          <el-table-column width="120" label="Authorized Total" property="Authorized Total"></el-table-column>
          <el-table-column width="120" label="Paid Total" property="Paid Total"></el-table-column>
          <el-table-column width="120" label="Claim Odom - Sale Odom"
                           property="Claim Odom - Sale Odom"></el-table-column>
          <el-table-column width="120" label="RO Number" property="RO Number"></el-table-column>
          <el-table-column width="120" label="Updated" property="Updated"></el-table-column>
          <el-table-column width="120" label="Updated Time" property="Last Updated Time"></el-table-column>
        </el-table>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{ success_txt }}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import moment from 'moment';
import Datepicker from 'vue-moment-datepicker';
import ReportsAPIHandler from "../../../../../servicehandlers/ReportsAPIHandler";
import FileAPIService from "../../../../../servicehandlers/FileHandler";
import { Dialog } from 'element-ui';

const reportsAPIHandler = new ReportsAPIHandler();
const filesAPIService = new FileAPIService();

export default {
  components: {
    Datepicker,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  name: "PCMIOpenClaims",
  data() {
    return {
      spinning: false,
      reportData: [],
      is_ran: false,
      totalCount: 0,
      start_date: '2008-01-01',
      end_date: null,
      success_txt: null,
      error_txt: null,
      carrier: 'ALL',
      selected_claim_status: 'ALL',
      selected_claim_activity: 'ALL',
      filename: null,
      modals: {
        basic: false,
        error: false,
        success: false,
        files: false,
      },
    }
  },
  methods: {
    downloadClaims() {
      this.spinning = true;
      return filesAPIService.getFile(this.filename, this.$router)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'open_claims_file.xlsx'); // Set the download attribute to the filename you want
        document.body.appendChild(link);
        link.click();
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'File Error: ' + error;
        this.openModal('error');
      });
    },
    getOpenClaims() {
      this.is_ran = false;
      this.spinning = true;
      const parms = {};
      let start_date = null;
      if (this.start_date) {
        start_date = moment(this.start_date).format('MM-DD-YYYY 00:00:00');
        parms.start_date = start_date;
      }
      let end_date = null;
      if (this.end_date) {
        end_date = moment(this.end_date).format('MM-DD-YYYY 23:59:59');
        parms.end_date = end_date;
      }

      if (this.selected_claim_status !== 'ALL') {
        parms.claim_status = this.selected_claim_status;
      }
      if (this.selected_claim_activity !== 'ALL') {
        parms.claim_activity = this.selected_claim_activity;
      }
      reportsAPIHandler.getOpenClaims(parms, this.$route)
      .then((resp) => {
        this.reportData = [];
        this.filename = resp.filename;
        const keys = Object.keys(resp.parms);
        for (const key of keys) {
          const item = resp.parms[key];
          item['Customer'] = item['First'] + ' ' + item['Last'];
          item['Updated'] = item['Updated First'] + ' ' + item['Updated Last'];
          item['Assigned'] = item['Assigned First'] + ' ' + item['Assigned Last'];
          this.reportData.push(item);
        }
        this.is_ran = true;
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'error: ' + error;
        this.openModal('error');
      });
    },
    customFormatter(date) {
      return moment(date).utc().format('YYYY-MM-DD');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>

</style>
