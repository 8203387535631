import { CommonAPIService } from '../CommonHandler';

export class OrderAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getOrders(parms, router) {
    const url = `/api/orders/getbytype`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  updateOrder(order_id, parms, router) {
    const url = `/api/orders/${order_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }

  deleteOrder(order_id, router) {
    const url = `/api/orders/${order_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  getOrder(id, router) {
    const url = `/api/orders/${id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  createOrder(parms, router) {
    const url = '/api/orders';
    return this.CommonAPIService.postCall(url, parms, router);
  }
}

export default OrderAPIService;
