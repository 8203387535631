import { CommonAPIService } from '../CommonHandler';

export class UserAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getUserList(router) {
    const url = '/api/users';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getEmailAdminList(router) {
    const url = '/api/users/adminlist';
    return this.CommonAPIService.getCall(url, '', router);
  }

  createUser(parms, router) {
    const url = '/api/users';
    return this.CommonAPIService.postCall(url, parms, router);
  }

  getUserById(user_id, router) {
    const url = `/api/users/${user_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getSingleUser(user_id, router) {
    const url = `/api/users/${user_id}/single`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getUserWithAuthgroups(user_id, router) {
    const url = `/api/users/${user_id}/authgroups`;
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default UserAPIService;
