import CommonAPIService from '../CommonHandler';

export class AuthGroupAPIService {
  constructor() {
    this.search = '';
    this.firm = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getAuthGroups(router) {
    const url = '/api/authgroups';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getActiveAuthGroups(router) {
    const url = '/api/authgroups?active=true';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getAuthGroupsById(id, router) {
    const url = `/api/authgroups/${id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getAuthGroupsWithScope(id, router) {
    const url = `/api/authgroups/${id}/scopes`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addAuthGroup(router, parms) {
    const url = `/api/authgroups`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  deleteAuthGroup(router, id) {
    const url = `/api/authgroups/${id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  updateAuthGroup(router, id, parms) {
    const url = `/api/authgroups/${id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }

  searchAuthGroups(router, search) {
    const url = `/api/authgroups?search=${search}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addUserToAuthGroups(userid, groups, router) {
    const url = `/api/authgroups/${userid}/user`;
    return this.CommonAPIService.postCall(url, groups, router);
  }

  removeUserFromAuthGroup(router, userid, groupid) {
    const url = `/api/authgroups/${groupid}/users/${userid}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  addScopeToAuthGroup(router, scopeid, groupid) {
    const url = `/api/authgroups/${groupid}/scopes/${scopeid}`;
    return this.CommonAPIService.putCall(url, null, router);
  }

  removeScopeFromAuthGroup(router, scopeid, groupid) {
    const url = `/api/authgroups/${groupid}/scopes/${scopeid}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }
}

export default AuthGroupAPIService;
