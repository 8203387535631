import { CommonAPIService } from './CommonHandler';

export class FileAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  uploadFile(parms, router) {
    const url = '/api/files';
    return this.CommonAPIService.fileCall(url, parms, router);
  }

  getFile(filename, router) {
    const url = `/api/files/${filename}/file`;
    return this.CommonAPIService.newFileCall(url, '', router);
  }

  deleteFile(id, filename, router) {
    const url = `/api/claims/${id}/${filename}/delete`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }

  uploadClaimsFile(id, parms, router) {
    const url = `/api/claims/${id}/upload`;
    return this.CommonAPIService.fileCall(url, parms, router);
  }
}

export default FileAPIService;
