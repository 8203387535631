<template>
  <div>
    <card v-if="spinning">
      <b-row class="text-center">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4 text-center">
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="Start Date"
            v-model="start_date">
          </datepicker>
        </div>
        <div class="col-sm-4 text-center">
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="End Date"
            v-model="end_date">
          </datepicker>
        </div>
        <div class="col-sm-4 text-center">
          <label>Select an Insurance Carrier:</label>
          <select class="form-control" v-model="carrier">
            <option value="ALL">ALL</option>
            <option value="D-FI">US Bank</option>
            <option value="VS">Virginia Surety</option>
            <option value="JMA">JMA</option>
            <option value="ORC">Old Republic</option>
            <option value="NC">NOVA</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8 text-center">
          <button class="btn btn-primary" @click="getContractData">Get Contract Data</button>
        </div>
      </div>
    </card>
    <card v-if="is_ran">
      <div class="row">
        <div class="col-sm-8 text-center">
          <download-excel
            class="btn btn-primary"
            :data="reportData"
            type="xls"
            worksheet="Contract Data"
            name="ContractData.xls">

            Download Excel
          </download-excel>
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{ success_txt }}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vue-moment-datepicker';
import ReportsAPIHandler from "../../../../../servicehandlers/ReportsAPIHandler";
import { Dialog } from 'element-ui';

const reportsAPIHandler = new ReportsAPIHandler();

export default {
  components: {
    Datepicker,
    [Dialog.name]: Dialog,
  },
  name: "PCMIContractData",
  data() {
    return {
      spinning: false,
      reportData: [],
      is_ran: false,
      totalCount: 0,
      start_date: null,
      end_date: null,
      success_txt: null,
      error_txt: null,
      carrier: 'ALL',
      modals: {
        basic: false,
        error: false,
        success: false,
        files: false,
      },
      json_fields: {
        'Contract Number': 'contract_number',
        'Coverage Code': 'coverage_code',
        'Plan Code': 'planCode',
        'Rate Book': 'rateBook',
        'Seller #': 'dealer_number',
        'Seller Name': 'dealer_name',
        'Seller Address 1': 'seller_address1',
        'Seller Address 2': 'seller_address2',
        'Seller Phone Number': 'seller_phone_number',
        'Seller City': 'seller_city',
        'Financed Amount': 'financed_amount',
        'Seller State': 'seller_state',
        'Seller Zip Code': 'seller_zip',
        'Agent #': 'agent_number',
        'Agent Name': 'agent_name',
        'Insurance Carrier': 'insurer_code',
        'Contract Type': 'contract_type',
        'Program Description': 'program_description',
        'Form #': 'form_number',
        'New/Used': 'new_used',
        'Term Months': 'term_months',
        'Mileage': 'mileage',
        'Deductible Amount': 'deductible_amount',
        'Contract Expiration Date': 'contract_expiration_date',
        'Contract Term Mileage': 'contract_term_mileage',
        'VIN': 'vin',
        'Vehicle Year': 'vehicle_year',
        'Make': 'vehicle_make',
        'Model': 'vehicle_model',
        'Service Date': 'service_date',
        'Contract Entry Date': 'contract_entry_date',
        'Sale Date': 'contract_sale_date',
        'Date Cancelled': 'date_cancelled',
        'Date Contract Cancelled Effective': 'date_contract_cancelled_effective',
        'Sale Odometer': 'sale_odometer',
        'Cancellation Odometer': 'cancelation_odometer',
        'nCancellationFactor': 'cancelation_factor',
        'Transaction Type': 'transaction_type',
        'Activation Date': 'activation_date',
        'Last Name': 'contract_last_name',
        'First Name': 'contract_first_name',
        'Address1': 'address1',
        'Address2': 'address2',
        'City': 'city',
        'State': 'state',
        'Zip Code': 'zip',
        'Home Phone': 'home_phone',
        'Work Phone': 'work_phone',
        'Email': 'email',
        'Retail Rate': 'retail_rate',
        'Rate Type': 'rate_type',
        'Cancel Dealer Remit': 'cancel_dealer_remit',
        'Cancellation Retail Rate': 'cancellation_retail_rate',
        'Regulated Reserve': 'Regulated Reserve',
        'Over Reserve': 'Over Reserve',
        'Direct Write': 'Direct Write',
        'CLP': 'CLP',
        'Cost': 'Cost',
        'Kits': 'Kits',
        'Extra Admin': 'Extra Admin',
        'Caring Brands': 'Caring Brands',
        'Agent Commission': 'Agent Commission',
        'Agent 2 Commission': 'Agent 2 Commission',
        'Agent 3 Commission': 'Agent 3 Commission',
        'Override Commission': 'Override Commission',
        'Regional Commission': 'Regional Commission',
      }
    }
  },
  methods: {
    getContractData() {
      if (!this.start_date) {
        this.error_txt = 'No start date!';
        this.openModal('error');
        return;
      }
      if (!this.end_date) {
        this.error_txt = 'No end date!';
        this.openModal('error');
        return;
      }
      if (!this.carrier) {
        this.error_txt = 'No carrier!';
        this.openModal('error');
        return;
      }
      this.is_ran = false;
      this.spinning = true;
      const start_date = moment(this.start_date).format('MM-DD-YYYY 00:00:00');
      const end_date = moment(this.end_date).format('MM-DD-YYYY 23:59:59');
      const parms = {
        start_date: start_date,
        end_date: end_date,
        carrier: this.carrier,
      };
      reportsAPIHandler.getContractData(parms, this.$route)
      .then((resp) => {
        this.reportData = [];
        const keys = Object.keys(resp);
        for (const key of keys) {
          const item = resp[key];
          if (!item['Over Reserve']) {
            item['Over Reserve'] = 0.00;
          }
          if (!item['CLP']) {
            item['CLP'] = 0.00;
          }
          if (!item['Cost']) {
            item['Cost'] = 0.00;
          }
          if (!item['Regulated Reserve']) {
            item['Regulated Reserve'] = 0.00;
          }
          if (!item['Extra Admin']) {
            item['Extra Admin'] = 0.00;
          }
          if (!item['Caring Brands']) {
            item['Caring Brands'] = 0.00;
          }
          if (!item['Direct Write']) {
            item['Direct Write'] = 0.00;
          }
          if (!item['Agent Commission']) {
            item['Agent Commission'] = 0.00;
          }
          if (!item['Agent 2 Commission']) {
            item['Agent 2 Commission'] = 0.00;
          }
          if (!item['Agent 2 Commission']) {
            item['Agent 2 Commission'] = 0.00;
          }
          if (!item['Kits']) {
            item['Kits'] = 0.00;
          }
          if (!item['Agent 3 Commission']) {
            item['Agent 3 Commission'] = 0.00;
          }
          if (!item['Override Commission']) {
            item['Override Commission'] = 0.00;
          }
          if (!item['Regional Commission']) {
            item['Regional Commission'] = 0.00;
          }
          this.reportData.push(item);
        }
        this.is_ran = true;
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'error: ' + error;
        this.openModal('error');
      });
    },
    customFormatter(date) {
      return moment(date).utc().format('YYYY-MM-DD');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>

</style>
