<template>
<div>
  <card v-if="spinning">
    <b-row class="text-center">
      <b-col>
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </b-col>
    </b-row>
  </card>
  <card>
    <div class="row pb-3">
      <div class="col-sm-4"></div>
      <div class="col-sm-4 text-center">
        <label>Select an Insurance Carrier:</label>
        <select class="form-control" v-model="carrier">
          <option value="ALL">ALL</option>
          <option value="D-FI">US Bank</option>
          <option value="VS">Virginia Surety</option>
          <option value="JMA">JMA</option>
          <option value="ORC">Old Republic</option>
          <option value="NC">NOVA</option>
        </select>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div class="row pb-3">
      <div class="col-sm-2"></div>
      <div class="col-sm-4 text-center">
        <datepicker
          :bootstrap-styling="true"
          :format="customFormatter"
          placeholder="Start Date"
          v-model="start_date">
        </datepicker>
      </div>
      <div class="col-sm-4 text-center">
        <datepicker
          :bootstrap-styling="true"
          :format="customFormatter"
          placeholder="End Date"
          v-model="end_date">
        </datepicker>
      </div>
      <div class="col-sm-2"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-center">
        <button class="btn btn-primary" @click="getRollingReport">Get Duplicate VINS</button>
      </div>
    </div>
  </card>
  <card v-if="is_ran">
    <div class="row pb-3">
      <div class="col-sm-12 text-center">
        <download-excel
          class="btn btn-primary"
          :data="reportData"
          :fields="json_fields"
          worksheet="Duplicate VINS"
          name="DuplicateVins.xls">

          Download Excel
        </download-excel>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-sm-4">Transaction Type</div>
      <div class="col-sm-4">VIN</div>
      <div class="col-sm-4">Count</div>
    </div>
    <div class="row text-center" v-for="reportItem in reportData" v-bind:key="reportItem.id">
      <div class="col-sm-4">{{ reportItem['Transaction Type'] }}</div>
      <div class="col-sm-4">{{ reportItem['VIN'] }}</div>
      <div class="col-sm-4">{{ reportItem['Count'] }}</div>
    </div>
  </card>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <span>{{success_txt}}</span>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
  </el-dialog>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vue-moment-datepicker';
import ReportsAPIHandler from "../../../../../servicehandlers/ReportsAPIHandler";
const reportsAPIHandler = new ReportsAPIHandler();
import { Dialog } from 'element-ui';

export default {
  components: {
    Datepicker,
    [Dialog.name]: Dialog,
  },
  name: "PCMIDuplicateVIN",
  data() {
    return {
      spinning: false,
      reportData: [],
      is_ran: false,
      totalCount: 0,
      start_date: null,
      end_date: null,
      success_txt: null,
      error_txt: null,
      carrier: null,
      modals: {
        basic: false,
        error: false,
        success: false,
        files: false,
      },
      json_fields: {
        'Transaction Type': 'Transaction Type',
        VIN: 'VIN',
        Count: 'Count',
      },
    }
  },
  methods: {
    getRollingReport() {
      if (!this.start_date) {
        this.error_txt = 'No start date';
        this.openModal('error');
        return;
      }
      if (!this.end_date) {
        this.error_txt = 'No end date';
        this.openModal('error');
        return;
      }
      if (!this.carrier) {
        this.error_txt = 'No carrier selected';
        this.openModal('error');
        return;
      }
      this.spinning = true;
      const start_date = moment(this.start_date).format('YYYY-MM-DD');
      const end_date = moment(this.end_date).format('YYYY-MM-DD');
      reportsAPIHandler.getDuplicateVINS(start_date, end_date, this.carrier, this.$route)
      .then((resp) => {
        this.reportData = [];
        this.totalCount = 0;
        this.is_ran = true;
        let count = 1;
        for (const item of resp) {
          item.id = count;
          this.totalCount++;
          count++;
          this.reportData.push(item);
        }
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'error: ' + error;
        this.openModal('error');
      });
    },
    customFormatter(date) {
      return moment(date).utc().format('YYYY-MM-DD');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>

</style>
