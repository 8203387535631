<template>
  <div>
  <h1>{{ newTitle }}</h1>
    <b-container v-if="spinning">
      <b-row>
        <b-col><b-spinner variant="primary" label="Spinning"></b-spinner></b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col>Total Purchased Amount: {{ totalAmount }}</b-col>
      </b-row>
    </b-container>
  <b-container class="pl-1 pr-1 p-0">
    <b-row>
      <b-col>
        <b-pagination
          :total-rows="totalContracts"
          :per-page="perPage"
          v-model="currentPage">
        </b-pagination>
        <b-table
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :fields="fields"
          :small="true"
          :responsive="false"
          :striped="true"
          :items="contracts"
        >
          <template v-slot:cell(purchase_date)="row">
            <b-row>
              <b-col>
                <datepicker
                  :clear-button="true"
                  :bootstrap-styling="true"
                  @input="setItem(row.item.id,
                         'purchase_date',
                          row.item.purchase_date)"
                  placeholder="Purchase Date"
                  v-model="row.item.purchase_date">
                </datepicker>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <datepicker
                  :clear-button="true"
                  :bootstrap-styling="true"
                  style="width: 10em;"
                  @input="setItem(row.item.id,
                         'date_completed',
                         row.item.date_completed)"
                  placeholder="Date Completed"
                  v-model="row.item.date_completed">
                </datepicker>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <datepicker
                  :clear-button="true"
                  :bootstrap-styling="true"
                  style="width: 10em;"
                  @input="setItem(row.item.id,
                        'payment_date',
                        row.item.payment_date)"
                  placeholder="Payment Date"
                  v-model="row.item.payment_date">
                </datepicker>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <datepicker
                  :clear-button="true"
                  :bootstrap-styling="true"
                  style="width: 10em;"
                  @input="setItem(row.item.id,
                        'initial_contact',
                        row.item.initial_contact)"
                  placeholder="Initial Contact"
                  v-model="row.item.initial_contact">
                </datepicker>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(car.make)="row">
            <b-row>
              <b-col>
                <input type="text"
                       name="make"
                       @input="setItem(row.item.car.id,
                             'make',
                             row.item.car.make,
                             'cars')"
                       placeholder="make"
                       v-model="row.item.car.make">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <input type="text"
                       name="model"
                       @input="setItem(row.item.car.id,
                             'model',
                             row.item.car.model,
                             'cars')"
                       placeholder="model"
                       v-model="row.item.car.model">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <input type="text"
                       name="vin"
                       @input="setItem(row.item.car.id,
                             'vin',
                             row.item.car.vin,
                             'cars')"
                       placeholder="vin"
                       v-model="row.item.car.vin">
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(customer.first)="row">
            <b-row>
              <b-col>
                <input type="text"
                       name="first"
                       @input="setItem(row.item.customer.id,
                             'first',
                             row.item.customer.first,
                             'customers')"
                       placeholder="first"
                       v-model="row.item.customer.first">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <input type="text"
                       name="last"
                       @input="setItem(row.item.customer.id,
                             'last',
                             row.item.customer.last,
                             'customers')"
                       placeholder="last"
                       v-model="row.item.customer.last">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <input type="text"
                       name="phone"
                       @input="setItem(row.item.customer.id,
                              'home',
                               row.item.customer.home,
                               'customers')"
                       placeholder="phone"
                       v-model="row.item.customer.home">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <input type="text"
                       name="email"
                       @input="setItem(row.item.customer.id,
                              'email',
                               row.item.customer.email,
                               'customers')"
                       placeholder="email"
                       v-model="row.item.customer.email">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <input type="text"
                       name="city"
                       @input="setItem(row.item.customer.id,
                              'city',
                              row.item.customer.city)"
                       placeholder="city"
                       v-model="row.item.customer.city">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <input type="text"
                       name="city"
                       @input="setItem(row.item.customer.id,
                              'zip',
                              row.item.customer.zip)"
                       placeholder="city"
                       v-model="row.item.customer.zip">
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(comments)="row">
            <div>
              <b-button variant="primary"
                        v-b-modal="'myModal' + row.item.id">Show Comments</b-button>
              <b-modal :id="'myModal' + row.item.id"
                       @ok="setItem(row.item.id,
                              'comments',
                              row.item.comments)"
                       size="lg"
                       title="Comments" centered
              >
                 <textarea rows="10" cols="50"
                           name="comments"
                           placeholder="comments"
                           v-model="row.item.comments">
                </textarea>
              </b-modal>
            </div>
          </template>
          <template v-slot:cell(plan)="row">
            <b-row>
              <b-col>
                <v-select
                  :options="plans"
                  label="name"
                  :searchable="true"
                  :reduce="plans => plans.id"
                  @input="setItem(row.item.id, 'plan_id', row.item.plan_id)"
                  v-model="row.item.plan_id">
                </v-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div v-if="plans[row.item.plan_id - 1] && plans[row.item.plan_id - 1].install">
                Install: {{ plans[row.item.plan_id - 1].install }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <input type="text" id="total"
                       @input="setItem(row.item.id,
                              'total',
                              row.item.total)"
                       placeholder="Xzilon Amount"
                       v-model="row.item.total">
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(status)="row">
            <v-select
              :options="status"
              label="name"
              :searchable="true"
              :reduce="status => status.id"
              @input="setItem(row.item.id, 'status_id', row.item.status_id)"
              v-model="row.item.status_id">
            </v-select>
          </template>
          <template v-slot:cell(detailers)="row">
            <v-select
              :options="detailers"
              label="business"
              :searchable="true"
              :reduce="detailers => detailers.id"
              @input="setItem(row.item.id, 'detailer_id', row.item.detailer_id)"
              v-model="row.item.detailer_id">
            </v-select>
            <br>
            <button
              @click="showModal(row.item)"
            >
              <font-awesome-icon icon="envelope"></font-awesome-icon></button>
            <br>
            <table>
              <tr>
                <td>
                  <label for="emailed">Emailed</label>
                </td>
                <td>
                  <input
                    @change="setItem(row.item.id, 'emailed', row.item.emailed)"
                    id="emailed"
                    type="checkbox"
                    v-model="row.item.emailed">
                </td>
              </tr>
            </table>
          </template>
          <template v-slot:cell(schedulers)="row">
            <v-select
              :options="schedulers"
              label="first"
              :searchable="true"
              :reduce="schedulers => schedulers.id"
              @input="setItem(row.item.id, 'scheduler_id', row.item.scheduler_id)"
              v-model="row.item.scheduler_id">
            </v-select>
            <br>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
    <div>
      <email-modal
        v-if="isModalVisible"
        :title="modalTitle"
        :id="modalId"
        :message="message"
        :subject="subject"
        @close="closeModal"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import EmailModal from 'src/pages/Dashboard/Components/EmailModal';
import Datepicker from 'vue-moment-datepicker';
import { ContractAPIService } from 'src/servicehandlers/ContractHandler';

const contractAPIService = new ContractAPIService();

export default {
  name: 'Contracts',
  components: {
    EmailModal,
    Datepicker,
  },
  props: {
    title: String,
    search_status: String,
    dealer_name: String,
    plans: Array,
    status: Array,
    detailers: Array,
    schedulers: Array,
    default() {
      return [];
    },
  },
  data() {
    return {
      spinning: false,
      loading: false,
      modalTitle: null,
      newTitle: this.title,
      modalId: null,
      totalContracts: 0,
      contracts: [],
      currentPage: 1, // for pagination
      perPage: 20, // for pagination
      sortBy: null, // for the b-table
      sortDesc: false, // for the b-table
      filter: null, // for the b-table
      message: null,
      subject: null,
      modalShow: false,
      isModalVisible: false,
      fields: [
        { key: 'purchase_date', label: 'Dates', sortable: true },
        { key: 'contract_number', label: 'Contract #', sortable: true },
        { key: 'customer.first', label: 'Customer', sortable: true },
        { key: 'car.make', label: 'Car', sortable: true },
        { key: 'comments', label: 'Comments', sortable: true },
        { key: 'detailers', label: 'Detailers', sortable: true },
        { key: 'schedulers', label: 'Schedulers', sortable: true },
        { key: 'plan', label: 'Plan', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
    };
  },

  computed: {
    // a computed getter
    totalAmount() {
      let total = 0.00;
      for (let i = 0; i < this.contracts.length; i += 1) {
        if (this.contracts[i].total) {
          total += parseFloat(this.contracts[i].total);
        }
      }
      return total;
    },
  },

  watch: {
    currentPage() {
      this.getAutolandContracts();
    },
  },

  created() {
    this.getAutolandContracts();
  },

  methods: {
    getArrayVal(arr, id, column) {
      let rv = '';
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i].id === id) {
          const item = arr[i];
          rv = item[column];
          break;
        }
      }
      return rv;
    },
    showModal(item) {
      this.newTitle = 'Email Detailer';
      this.id = item.id;
      this.message = 'CAR INFO: \n';
      this.message += `Car Make: ${item.car.make}\n`;
      this.message += `Car Model: ${item.car.model}\n`;
      this.message += `Car VIN: ${item.car.vin}\n\n`;
      this.message += 'Customer Information:\n';
      this.message += `First: ${item.customer.first}\n`;
      this.message += `Last: ${item.customer.last}\n`;
      this.message += `Email: ${item.customer.email}\n`;
      this.message += `Phone: ${item.customer.home}\n`;
      this.message += `Address: ${item.customer.address1}\n`;
      this.message += `City: ${item.customer.city}\n`;
      this.message += `State: ${item.customer.state}\n`;
      this.message += `Zip: ${item.customer.zip}\n\n`;

      const plan = this.getArrayVal(this.plans, item.plan_id, 'name');
      this.message += `Plan: ${plan}\n`;

      this.subject = 'Xzilon Inc Detailing Job';
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },

    setItem(id, item, itemValue, table = 'contracts') {
      let value = itemValue;
      if (item === 'payment_date' || item === 'date_completed' || item === 'purchase_date'
        || item === 'initial_contact') {
        value = moment(itemValue).format('YYYY-MM-DD');
      }
      const parms = {
        id,
        item,
        value,
      };
      if (table === 'contracts') {
        contractAPIService.update(parms, this.$router)
          .then(() => {
            if (item === 'status_id') {
              this.getAutolandContracts();
            }
          })
          .catch((error) => {
            // console.log('ERROR UPDATING check log item: ', error);
          });
      } else if (table === 'cars') {
        contractAPIService.updateCar(parms, this.$router)
          .then(() => {
            if (item === 'status_id') {
              this.getAutolandContracts();
            }
          })
          .catch((error) => {
            // console.log('ERROR UPDATING check log item: ', error);
          });
      } else if (table === 'customers') {
        contractAPIService.updateCustomer(parms, this.$router)
          .then(() => {
            if (item === 'status_id') {
              this.getAutolandContracts();
            }
          })
          .catch((error) => {
            // console.log('ERROR UPDATING check log item: ', error);
          });
      }
    },

    getAutolandContracts() {
      this.spinning = true;
      const searchItems = {
        page: this.currentPage,
        perPage: this.perPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        status: this.search_status,
        dealer_name: this.dealer_name,
      };
      contractAPIService.getAutolandContracts(this.$router, searchItems)
        .then((results) => {
          this.contracts = results.data;
          this.totalContracts = results.count;
          for (let i = 0; i < this.contracts.length; i += 1) {
            if (this.contracts[i].payment_date) {
              const paymentDate = this.contracts[i].payment_date;
              this.contracts[i].payment_date = moment(paymentDate, 'YYYY-MM-DD').toDate();
            }
            if (this.contracts[i].date_completed) {
              const dateCompleted = this.contracts[i].date_completed;
              this.contracts[i].date_completed = moment(dateCompleted, 'YYYY-MM-DD').toDate();
            }
            if (this.contracts[i].purchase_date) {
              const purchasedate = this.contracts[i].purchase_date;
              this.contracts[i].purchase_date = moment(purchasedate, 'YYYY-MM-DD').toDate();
            }
            if (this.contracts[i].initial_contact) {
              const initialcontact = this.contracts[i].initial_contact;
              this.contracts[i].purchase_date = moment(initialcontact, 'YYYY-MM-DD').toDate();
            }
          }
          this.spinning = false;
        })
        .catch((error) => {
          this.spinning = false;
          // console.log('ERROR: ', error);
        });
    },
  },
};
</script>

<style>
  span.input-group-text {
    width: 2em;
  }
</style>
