import { render, staticRenderFns } from "./PCMIOpenClaims.vue?vue&type=template&id=2acfdc3c&scoped=true&"
import script from "./PCMIOpenClaims.vue?vue&type=script&lang=js&"
export * from "./PCMIOpenClaims.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2acfdc3c",
  null
  
)

export default component.exports