<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <div class="row">
      <div class="col-sm-12 text-center">User Admin Page</div>
    </div>
  </card>
  <card>
    <div class="row">
      <div class="col-sm-4">
        <label>Find User:</label>
          <model-select class="form-control select-primary" :options="users"
                        v-model="selected_user_id"
                        placeholder="Select a User"
                        @input="selectUser">
          </model-select>
        <button class="btn btn-primary" @click="clear">Clear</button>
      </div>
      <div class="col-sm-4">
        <fg-input label="Username:" placeholder="Username" v-model="username"></fg-input>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div class="row">
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <fg-input label="First Name:" placeholder="First Name" v-model="first_name"></fg-input>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div class="row">
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <fg-input label="Last Name:" placeholder="Last Name" v-model="last_name"></fg-input>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div class="row">
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <fg-input label="Password:" placeholder="Password" type="password" v-model="password"></fg-input>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div class="row pb-5">
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <label>Enabled:</label>
        <input type="checkbox" class="form-control" v-model="enabled">
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div class="row">
      <div class="col-sm-5"></div>
      <div class="col-sm-2 text-center">
        <button class="btn btn-default" @click="createuser">Create or Update User</button>
      </div>
      <div class="col-sm-5"></div>
    </div>
  </card>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>Success: {{ success_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { Dialog } from 'element-ui';
import { ModelSelect } from 'vue-search-select';
import { UserAPIService } from 'src/servicehandlers/archived_handlers/UsersAPIService';
const usersAPIService = new UserAPIService();

export default {
  name: "UserAdmin",
  components: {
    [Dialog.name]: Dialog,
    ModelSelect
  },
  data() {
    return {
      modals: {
        error: false,
        success: false,
      },
      selected_user_id: null,
      error_txt: null,
      success_txt: null,
      spinning: false,
      username: null,
      first_name: null,
      last_name: null,
      password: null,
      enabled: true,
      users: [],
    }
  },
  mounted() {
    this.getAllUsers();
  },
  methods: {
    getAllUsers() {
      return usersAPIService.getEmailAdminList(this.$router)
      .then((allUsers) => {
        for (const user of allUsers) {
          user.text = user.full_name;
          user.value = user.id;
        }
        this.users = allUsers;
      })
      .catch((error) => {
        this.error_txt = error;
        this.openModal('error');
      });
    },
    clear() {
      this.first_name = null;
      this.selected_user_id = null;
      this.last_name = null;
      this.password = null;
      this.username = null;
      this.enabled = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    selectUser() {
      // console.log('In select user');
      return usersAPIService.getSingleUser(this.selected_user_id)
      .then((user) => {
        this.username = user.username;
        this.first_name = user.first;
        this.last_name = user.last;
        this.enabled = user.enabled;
      })
      .catch((error) => {
        this.error_txt = error;
        this.openModal('error');
      });
    },
    createuser() {
      this.spinning = true;
      if (!this.username) {
        this.spinning = false;
        this.error_txt = 'No username passed in';
        this.openModal('error');
        return;
      }
      if (!this.last_name) {
        this.spinning = false;
        this.error_txt = 'No last name passed in';
        this.openModal('error');
        return;
      }
      if (!this.first_name) {
        this.spinning = false;
        this.error_txt = 'No first name passed in';
        this.openModal('error');
        return;
      }
      if (!this.selected_user_id && !this.password) {
        this.spinning = false;
        this.error_txt = 'No password was set';
        this.openModal('error');
        return;
      }
      const parms = {
        username: this.username,
        password: this.password,
        first: this.first_name,
        last: this.last_name,
        enabled: this.enabled,
      };
      if (this.selected_user_id) {
        parms.id = this.selected_user_id;
      }
      return usersAPIService.createUser(parms, this.$router)
      .then((userResult) => {
        this.spinning = false;
        this.success_txt = 'Successfully updated or created user: ';
        this.openModal('success');
        this.username = null;
        this.password = null;
        this.first_name = null;
        this.last_name = null;
        this.selected_user_id = null;
        this.enabled = true;
        this.spinning = false;
        this.getAllUsers();
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error creating user: ' + error;
        this.openModal('error');
      });
    }
  }
}
</script>

<style scoped>

</style>
