import { CommonAPIService } from '../CommonHandler';

export class OrderStatusAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getOrderStatuses(router) {
    const url = '/api/orderstatus';
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default OrderStatusAPIService;
