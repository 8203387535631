<template>
<div>
  <card v-if="spinning">
    <b-row>
      <b-col class="text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </b-col>
    </b-row>
  </card>
  <card>
    <div class="row">
      <div class="col-md-12 text-center">
        <span style="font-weight: bold; font-size: 2em;">Order Search Page</span>
      </div>
    </div>
  </card>
  <card>
    <div class="row">
      <div class="col-sm-3">
        <div class="row">
          <div class="col-md-3">
            <label>Status Order</label>
          </div>
          <div class="col-md-9">
            <v-select
              :options="status"
              label="name"
              :searchable="true"
              :reduce="status => status.id"
              v-model="searchItem.order_status_id">
            </v-select>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <div class="col-sm-3">
            <label>First Name</label>
          </div>
          <div class="col-sm-9">
            <input type="text" v-model="searchItem.first_name" placeholder="First Name">
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <div class="col-sm-3">
            <label>Last Name</label>
          </div>
          <div class="col-sm-9">
            <input type="text" v-model="searchItem.last_name" placeholder="Last Name">
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <div class="col-sm-3">
            <label>Phone</label>
          </div>
          <div class="col-sm-9">
            <input type="text" v-model="searchItem.phone" placeholder="Phone">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 text-center"></div>
      <div class="col-sm-4 text-center">
        <button class="btn-primary btn" @click="getOrders">Search Orders</button>
      </div>
      <div class="col-sm-4 text-center"></div>
    </div>
  </card>
  <card>
    <div class="row" v-for="order in orders" v-bind:key="order.id">
      <div class="col-sm-12">Item</div>
    </div>
  </card>
  <el-dialog
    center
    :before-close="handleClose"
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { Dialog } from 'element-ui';
import { OrderStatusAPIService } from 'src/servicehandlers/archived_handlers/OrderStatusHandler';
import OrdersAPIService from 'src/servicehandlers/archived_handlers/OrderHandler';
const orderAPIService = new OrdersAPIService();
const orderStatusAPIService = new OrderStatusAPIService();

export default {
  name: "OrderSearch",
  components: {
    [Dialog.name]: Dialog
  },
  data() {
    return {
      spinning: false,
      orders: [],
      status: [],
      page: 1,
      sortBy: null, // for the b-table
      sortDesc: false, // for the b-table
      filter: null, // for the b-table
      totalOrders: 0,
      searchItem: {
        order_status_id: null,
        first_name: null,
        last_name: null,
      },
      currentPage: 1,
      perPage: 50,
      fields: [
        { key: 'id', label: 'Order', sortable: false },
        { key: 'customer', label: 'Billing', sortable: false },
        { key: 'productOrders', label: 'Products', sortable: false },
        { key: 'note', label: 'Instructions', sortable: false },
        { key: 'order_status_id', label: 'Order Status', sortable: false },
      ],
      orderStatus: [],
      error_txt: null,
      modals: {
        error: false,
      },
    };
  },
  methods: {
    setItem(id, item, itemValue, table = 'orders') {
      const parms = {
        [item]: itemValue,
      };
      if (table === 'orders') {
        orderAPIService.updateOrder(id, parms, this.$router)
        .then(() => {
          if (item === 'order_status_id') {
            this.getOrders();
          }
        })
        .catch((error) => {
          this.error_txt = 'Error Updating Order Item: ' + error;
          this.openModal('error');
        });
      } else if (table === 'customers') {
        orderAPIService.updateCar(parms, this.$router)
        .then(() => {
          if (item === 'status_id') {
            this.getOrders();
          }
        })
        .catch((error) => {
          this.error_txt = 'Error Updating Order Item: ' + error;
          this.openModal('error');
        });
      }
    },

    getArrayVal(arr, id, column) {
      let rv = '';
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i].id === id) {
          const item = arr[i];
          rv = item[column];
          break;
        }
      }
      return rv;
    },

    refresh() {
      this.getOrders();
    },

    getOrders() {
      this.spinning = true;
      const orderSort = {
        currentPage: this.currentPage,
        perPage: this.perPage,
      };
      if (this.status_id) {
        orderSort.where = {
          status_id: this.status_id
        };
      }
      return orderAPIService.getOrders(orderSort, this.$route)
      .then((response) => {
        // console.log('Response: ', response);
        this.orders = response.orders;
        this.totalOrders = response.count;
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Error getting orders: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {}
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true
    },
  },
  mounted() {
    this.spinning = true;
    orderStatusAPIService.getOrderStatuses(this.$router)
    .then((res) => {
      // this.orderStatus = statuses.data;
      const statuses = [];
      for (let i = 0; i < res.length; i += 1) {
        statuses.push({ id: res[i].id, name: res[i].name });
      }
      this.status = statuses;
      this.orderStatus = statuses;
      this.spinning = false;
    })
    .catch((error) => {
      this.spinning = false;
      // console.log('Failed to get statuses: ', error);
    });
  },
}
</script>

<style scoped>

</style>
