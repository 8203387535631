<template>
  <div>
    <card v-if="spinning">
      <b-row>
        <b-col class="text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </card>
    <card>
      <span style="font-size: 2em">Orders</span>
      <card>
      </card>
      <b-row>
        <b-col>
          <button class="btn btn-primary" @click="refresh">Refresh</button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            :total-rows="totalOrders"
            :per-page="perPage"
            v-model="currentPage">
          </b-pagination>
          <b-table
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :fields="fields"
            :small="true"
            :responsive="false"
            :striped="true"
            :items="orders"
          >
            <template v-slot:cell(productOrders)="row">
              <b-col>
                <ul>
                  <li v-for="product in row.item.products" v-bind:key="product.id">
                    {{ product.sku }} Quantity: {{ product.product_order.quantity }}
                  </li>
                </ul>
              </b-col>
            </template>
            <template v-slot:cell(customer)="row">
              <b-col>
                Billing Name: {{ row.item.customer.billing_name }}
              </b-col>
              <b-col>
                Billing Email: {{ row.item.customer.billing_email }}
              </b-col>
              <b-col>
                Billing Phone: {{ row.item.customer.billing_phone }}
              </b-col>
              <b-col>
                Billing Address: {{ row.item.customer.billing_address1 }}
              </b-col>
              <b-col>
                Billing City: {{ row.item.customer.billing_city }}
              </b-col>
              <b-col>
                Billing State: {{ row.item.customer.billing_state }}
              </b-col>
              <b-col>
                Billing Zip: {{ row.item.customer.billing_zip }}
              </b-col>
            </template>
            <template v-slot:cell(id)="row">
              <b-col>
                Order ID: {{ row.item.id }}
              </b-col>
              <b-col>
                Ordered By: {{ row.item.ordered_by }}
              </b-col>
              <b-col>
                ATTN: {{ row.item.attn }}
              </b-col>
              <b-col>
                Dealer Name: {{ row.item.customer.dealer_name }}
              </b-col>
              <b-col>
                Dealer Number: {{ row.item.customer.dealer_number }}
              </b-col>
              <b-col>
                Email: {{ row.item.customer.email }}
              </b-col>
              <b-col>
                Phone: {{ row.item.customer.home }}
              </b-col>
              <b-col>
                Address: {{ row.item.customer.address1 }}
              </b-col>
              <b-col>
                City: {{ row.item.customer.city }}
              </b-col>
              <b-col>
                State: {{ row.item.customer.state }}
              </b-col>
              <b-col>
                Zip: {{ row.item.customer.zip }}
              </b-col>
            </template>
            <template v-slot:cell(order_status_id)="row">
              <v-select
                :options="status"
                label="name"
                @input="setItem(row.item.id, 'order_status_id', row.item.order_status_id, 'orders')"
                :searchable="true"
                :reduce="status => status.id"
                v-model="row.item.order_status_id">
              </v-select>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </card>
    <el-dialog
      center
      :before-close="handleClose"
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';
import { OrderStatusAPIService } from 'src/servicehandlers/archived_handlers/OrderStatusHandler';
import OrdersAPIService from 'src/servicehandlers/archived_handlers/OrderHandler';
const orderAPIService = new OrdersAPIService();
const orderStatusAPIService = new OrderStatusAPIService();

export default {
  name: 'OrdersMain',
  components: {
    [Dialog.name]: Dialog
  },
  data() {
    return {
      spinning: false,
      orders: [],
      status: [],
      page: 1,
      sortBy: null, // for the b-table
      sortDesc: false, // for the b-table
      filter: null, // for the b-table
      totalOrders: 0,
      currentPage: 1,
      perPage: 50,
      fields: [
        { key: 'id', label: 'Order', sortable: false },
        { key: 'customer', label: 'Billing', sortable: false },
        { key: 'productOrders', label: 'Products', sortable: false },
        { key: 'note', label: 'Instructions', sortable: false },
        { key: 'order_status_id', label: 'Order Status', sortable: false },
      ],
      orderStatus: [],
      error_txt: null,
      modals: {
        error: false,
      },
    };
  },
  watch: {
    currentPage() {
      this.getOrders();
    },
  },

  methods: {
    setItem(id, item, itemValue, table = 'orders') {
      const parms = {
        [item]: itemValue,
      };
      if (table === 'orders') {
        orderAPIService.updateOrder(id, parms, this.$router)
        .then(() => {
          if (item === 'order_status_id') {
            this.getOrders();
          }
        })
        .catch((error) => {
          this.error_txt = 'Error Updating Order Item: ' + error;
          this.openModal('error');
        });
      } else if (table === 'customers') {
        orderAPIService.updateCar(parms, this.$router)
        .then(() => {
          if (item === 'status_id') {
            this.getOrders();
          }
        })
        .catch((error) => {
          this.error_txt = 'Error Updating Order Item: ' + error;
          this.openModal('error');
        });
      }
    },

    getArrayVal(arr, id, column) {
      let rv = '';
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i].id === id) {
          const item = arr[i];
          rv = item[column];
          break;
        }
      }
      return rv;
    },

    refresh() {
      this.getOrders();
    },

    getOrders() {
      this.spinning = true;
      const orderSort = {
        currentPage: this.currentPage,
        perPage: this.perPage,
      };
      if (this.status_id) {
        orderSort.where = {
          status_id: this.status_id
        };
      }
      return orderAPIService.getOrders(orderSort, this.$route)
      .then((response) => {
        this.orders = response.orders;
        this.totalOrders = response.count;
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Error getting orders: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {}
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true
    },
  },
  mounted() {
    this.spinning = true;
    orderStatusAPIService.getOrderStatuses(this.$router)
    .then((res) => {
      // this.orderStatus = statuses.data;
      const statuses = [];
      for (let i = 0; i < res.length; i += 1) {
        statuses.push({ id: res[i].id, name: res[i].name });
      }
      this.status = statuses;
      this.orderStatus = statuses;
      this.spinning = false;
      this.getOrders();
    })
    .catch((error) => {
      this.spinning = false;
      this.error_txt = 'Error getting order statuses: ' + error;
      this.openModal('error');
    });
  },
};
</script>

<style scoped>

</style>
