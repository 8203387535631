<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <span style="font-size: 3em;">Quickbooks page</span>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-md-12 pt-2">
          <b-tabs>
            <b-tab title="Authentication" active>
              <div class="row pt-2">
                <div class="col-md-2">
                </div>
                <div class="col-md-2">
                  <button class="btn btn-primary" @click="authorizeUri">Authenticate Quickbooks</button>
                </div>
                <div class="col-md-2">
                  <button class="btn btn-primary" @click="checkAuthentication">Click to check authentication</button>
                </div>
                <div class="col-md-2">
                  <button class="btn btn-primary" @click="getToken">Click here to refresh Token</button>
                </div>
                <div class="col-md-4">
                    <span style="font-weight: bold;">{{ authenticationMessage }}</span>
                </div>
              </div>
            </b-tab>
            <b-tab title="Batch Upload">
              <div class="row pt-6">
                <div class="col-md-12 text-center">
                  <select class="form-control" v-model="selectedReport27Items" name="report27" id="report27Select" multiple>
                    <option :value="item.name" v-for="item in report27DataItems" v-bind:key="item.id">{{ item.name }}</option>
                  </select>
                </div>
                <div class="col-md-12 text-center pt-4">
                  <button class="btn btn-primary" @click="report27BatchUpload">Click here to upload batch</button>
                </div>
              </div>
              <card v-if="report27Results">
                <div class="row">
                  <div class="col-sm-12">
                    <span style="font-weight: bold; font-size: 2em;">Report 27 Upload Results</span>
                  </div>
                </div>
                <div v-for="(message, i) in report27Message" v-bind:key="i" class="row">
                  <div class="col-sm-4">
                    <label>Claim Upload Successful: {{ message.success }}</label>
                  </div>
                  <div class="col-sm-8">
                    <label>Message: {{ message.message }}</label>
                  </div>
                </div>
              </card>
            </b-tab>
            <b-tab title="Claim Upload">
              <div class="row pt-6">
                <div class="col-md-12 text-center">
                  <select class="form-control" v-model="selectedClaimFiles" name="claimpload" id="claimuploadSelect" multiple>
                    <option :value="item.name" v-for="item in claimUploadDataItems" v-bind:key="item.id">{{ item.name }}</option>
                  </select>
                </div>
                <div class="col-md-12 text-center pt-4">
                  <button class="btn btn-primary" @click="batchUpload">Click here to upload batch</button>
                </div>
              </div>
              <card v-if="claimResults">
                <div class="row">
                  <div class="col-sm-12">
                    <span style="font-weight: bold; font-size: 2em;">Claim Upload Results</span>
                  </div>
                </div>
                <div v-for="(message, i) in batchuploadMessage" v-bind:key="i" class="row">
                  <div class="col-sm-4">
                    <label>Claim Upload Successful: {{ message.success }}</label>
                  </div>
                  <div class="col-sm-8">
                    <label>Message: {{ message.message }}</label>
                  </div>
                </div>
              </card>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <el-dialog
      center
      :before-close="handleClose"
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';
import { QuickbooksAPIService } from 'src/servicehandlers/archived_handlers/QuickbooksHandler';
const quickbooksAPIService = new QuickbooksAPIService();

export default {
  name: 'QuickbooksMain',
  components: {
    [Dialog.name]: Dialog
  },
  data() {
    return {
      spinning: false,
      token: null,
      claimResults: false,
      report27Results: false,
      modals: {
        error: false,
      },
      error_txt: null,
      authenticationMessage: 'Quickbooks is not authenticated',
      authURI: null,
      report27Message: [],
      batchuploadMessage: [],
      report27DataItems: [],
      claimUploadDataItems: [],
      selectedClaimFiles: [],
      selectedReport27Items: [],
    };
  },
  methods: {
    batchUpload() {
      this.batchuploadMessage = [];
      this.spinning = true;
      const responses = [];
      quickbooksAPIService.uploadClaimItems({files: this.selectedClaimFiles}, this.$router)
      .then((response) => {
        for (const res1 of response) {
          for (const res of res1) {
            responses.push(res);
          }
        }
        // console.log('Responses obj: ', responses);
        this.batchuploadMessage = responses;
        this.claimResults = true;
        this.spinning = false;
      })
      .catch((error) => {
        if (error) {
          this.error_txt = 'Unhandled error: ' + error;
          this.openModal('error');
        }
        this.spinning = false;
      })
    },

    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },

    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {
      }
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true
    },

    report27BatchUpload() {
      this.spinning = true;
      this.report27Message = [];
      this.report27Results = false;
      // send the filenames down to the process for downloading and processing
      quickbooksAPIService.uploadReport27Batch({files: this.selectedReport27Items}, this.$router)
      .then((response) => {
        this.spinning = false;
        this.report27Message = response;
        this.report27Results = true;
      })
      .then((error) => {
        if (error) {
          this.error_txt = 'Error uploading error 27 batch: ' + error;
          this.openModal('error');
        }
        this.spinning = false;
      });
    },

    checkAuthentication() {
      this.spinning = true;
      quickbooksAPIService.checkAuth(this.$router)
      .then((response) => {
        this.spinning = false;
        if (response.authenticated === true) {
          this.authenticationMessage = 'Quickbook is authenticated';
        } else {
          this.authenticationMessage = 'Quickbook is not authenticated';
        }
      })
      .catch((error) => {
        this.error_txt = 'Error checking authentication: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },


   async authorizeUri() {
      this.spinning = true;
      return quickbooksAPIService.authURI(this.$router)
      .then(async (authResponse) => {
        // Launch Popup using the JS window Object
        let parameters = "location=100,width=800,height=650";
        parameters += ",left=" + (screen.width - 800) / 2 + ",top=" + (screen.height - 650) / 2;
        const win = window.open(authResponse, 'connectPopup', parameters);
        // const pollOAuth = window.setInterval(function () {
        // }, 100);
        await this.sleep(2000);

        // now try and collect the authenticated response
        return quickbooksAPIService.checkAuth(this.$router);
      })
      .then((res) => {
        if (res.authenticated === true) {
          this.authenticationMessage = 'Quickbook is authenticated';
        } else {
          this.authenticationMessage = 'Quickbook is not authenticated';
        }
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Error in authentication: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },

    getToken() {
      quickbooksAPIService.refreshToken(this.$router)
      .then((token) => {
        this.token = token;
      })
      .catch((error) => {
        this.error_txt = 'Error getting token: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },

    report27Items() {
      quickbooksAPIService.getReport27Items(this.$router)
      .then((dta) => {
        this.report27DataItems = dta;
      })
      .catch((error) => {
        this.error_txt = 'Error getting report 27 items: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },

    getClaimUploadItems() {
      quickbooksAPIService.getClaimUploadItems(this.$router)
      .then((dta) => {
        this.claimUploadDataItems = dta;
      })
      .catch((error) => {
        this.error_txt = 'Error getting claims items: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
  },
  mounted() {
    this.report27Items();
    this.checkAuthentication();
    this.getClaimUploadItems();
    // this.code = this.$route.query.code;
    // this.state = this.$route.query.state;
    // this.realmId = this.$route.query.realmId;
  },
};
</script>

<style scoped>

</style>
