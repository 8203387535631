import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export const store = new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    username: "",
    userId: 0,
    userScopes: [],
    userGroups: [],
    loggedIn: false,
    sideMenu: false,
    userPreferences: {},
    currentRightsArray: [],
    noteData: {},
    seeMoreFirmsOptions: false,
  },
  actions: {
    ADD_USERNAME({ commit }, username) {
      commit('SET_USERNAME', username);
    },

    ADD_USERID({ commit }, userId) {
      commit('SET_USERID', userId);
    },

    ADD_USER_SCOPES({ commit }, userScopes) {
      commit('SET_USER_SCOPES', userScopes);
    },

    ADD_USER_GROUPS({ commit }, userGroups) {
      commit('SET_USER_GROUPS', userGroups);
    },

    SET_LOGGED_IN({ commit }, loggedIn) {
      commit('SET_LOGIN', loggedIn);
    },

    LOGOUT({ commit }) {
      commit('SET_LOGOUT');
    },

    SET_SIDE_MENU({ commit }, sideMenu) {
      commit('SET_SIDE_MENU', sideMenu);
    },

    SET_USER_PREFERENCES({ commit }, userPreferences) {
      commit('SET_USER_PREFERENCES', userPreferences);
    },

    //---------  shared state for firms, notes ---------//

    SET_CURRENT_RIGHTS_ARRAY({ commit }, currentRightsArray) {
      commit('SET_CURRENT_RIGHTS_ARRAY', currentRightsArray);
    },

    SET_NOTES_DATA({ commit }, noteData) {
      commit('SET_NOTES_DATA', noteData);
    },

    SET_FIRMS_SEARCH_OPEN({ commit }, seeMoreFirmsOptions) {
      commit('SET_FIRMS_SEARCH_OPEN', seeMoreFirmsOptions);
    },
  },

  mutations: {

    SET_LOGIN(state, loggedIn) {
      state.loggedIn = loggedIn;
    },

    SET_USERNAME(state, username) {
      state.username = username;
    },

    SET_USERID(state, userId) {
      state.userId = userId;
    },

    SET_USER_GROUPS(state, userGroups) {
      state.userGroups = [];
      state.userScopes = [];
      for (let i = 0; i < userGroups.length; i += 1) {
        state.userGroups.push(userGroups[i].name);
        const scopes = userGroups[i].scopes;

        // now get the scopes for each item
        for (let k = 0; k < scopes.length; k += 1) {
          const scope = {
            name: scopes[k].name,
            type: scopes[k].description,
            id: scopes[k].id,
          };
          state.userScopes.push(scope);
        }
      }
    },

    SET_SIDE_MENU(state, sideMenu) {
      state.sideMenu = sideMenu;
    },

    SET_USER_PREFERENCES(state, userPreferences) {
      state.userPreferences = userPreferences;
    },

    SET_CURRENT_RIGHTS_ARRAY(state, currentRightsArray) {
      state.currentRightsArray = currentRightsArray;
    },

    SET_NOTES_DATA(state, noteData) {
      state.noteData = noteData;
    },

    SET_FIRMS_SEARCH_OPEN(state, seeMoreFirmsOptions) {
      state.seeMoreFirmsOptions = seeMoreFirmsOptions;
    },

    SET_LOGOUT(state) {
      state.username = '';
      state.userId = '';
      state.userScopes = [];
      state.userGroups = [];
      state.loggedIn = false;
      state.sideMenu = false;
      state.userPreferences = {};
      state.seeMoreFirmsOptions = false;
      state.noteData = {};
      state.currentRightsArray = [];
      sessionStorage.clear();
    },
  },

  getters: {
    username: state => state.username,
    userId: state => state.userId,
    groups: state => state.userGroups,
    scopes: state => state.userScopes,
    loggedIn: state => state.loggedIn,
    sideMenu: state => state.sideMenu,
    userPreferences: state => state.userPreferences,
    seeMoreFirmsOptions: state => state.seeMoreFirmsOptions,
    noteData: state => state.noteData,
    currentRightsArray: state => state.currentRightsArray,
  },
});
