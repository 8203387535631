/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.4.0
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import vSelect from "vue-select";
import JsonExcel from 'vue-json-excel';
import VueClipboard from 'vue-clipboard2';
import LightBootstrap from './light-bootstrap-main';
import moment from 'moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import "vue-select/dist/vue-select.css";
import 'vue-search-select/dist/VueSearchSelect.css';
// Plugins
import App from './App.vue'

// router setup
import { router } from './routes/routes'
// plugin setup
import { store } from './store';
// Make BootstrapVue available throughout your project
library.add(faEnvelope);
Vue.component('downloadExcel', JsonExcel);
Vue.prototype.moment = moment;
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueRouter);
Vue.use(LightBootstrap);
Vue.use(VueClipboard);
Vue.component("v-select", vSelect);
Vue.component('font-awesome-icon', FontAwesomeIcon);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
})
