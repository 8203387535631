import { CommonAPIService } from './CommonHandler';

export class ContractAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  update(parms, router) {
    const url = '/api/contracts/update';
    return this.CommonAPIService.postCall(url, parms, router);
  }

  getContractStatus(router) {
    const url = '/api/contractstatus';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getContractDetailer(router) {
    const url = '/api/detailers';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getContractInfoFromVIN(vin, router) {
    const url = `/api/contracts/${vin}/contractinfo`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getContractSchedulers(router) {
    const url = '/api/schedulers';
    return this.CommonAPIService.getCall(url, '', router);
  }

  updateCar(parms, router) {
    const url = '/api/cars/update';
    return this.CommonAPIService.postCall(url, parms, router);
  }
  updateCustomer(parms, router) {
    const url = '/api/customers/update';
    return this.CommonAPIService.postCall(url, parms, router);
  }

  getAutolandContracts(router, parms) {
    const url = '/api/contracts';
    return this.CommonAPIService.postCall(url, parms, router);
  }

  searchAutolandContracts(router, parms) {
    const url = '/api/contracts/search';
    return this.CommonAPIService.postCall(url, parms, router);
  }

  emailDetailer(id, subject, message, router) {
    const url = '/api/contracts/email';
    return this.CommonAPIService.postCall(
      url,
      { id, type: 'detailer', subject, message },
      router,
    );
  }
}

export default ContractAPIService;
