<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-md-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-12 text-center"><span style="font-size: 2em; font-weight: bold;">Contract Lookup</span></div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4">
          <fg-input label="VIN Number:" placeholder="Enter a VIN number to lookup" v-model="vin"></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <button class="btn btn-primary" @click="vinLookup">VIN Lookup</button>
        </div>
      </div>
    </card>
    <card v-if="results">
      <div class="row">
        <div class="col-sm-4">
          <label>Contract Status:</label>
          {{ contract['Status'] }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label>Contract Number:</label>
          {{ contract['Contract Number'] }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label>Contract VIN:</label>
          {{ contract.VIN }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label>Sale Date:</label>
          {{ contract['Sale Date'] }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label>Expiration Date:</label>
          {{ contract['Contract Expiration Date'] }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label>Active Date:</label>
          {{ contract['Contract Active Date'] }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label>Cancellation Date:</label>
          {{ contract['Contract Cancellation Date'] }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label>Effective Date:</label>
          {{ contract['Contract Effective Date'] }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label>First Name:</label>
          {{ contract['Customer First Name'] }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label>Last Name:</label>
          {{ contract['Customer Last Name'] }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label>Home Phone:</label>
          {{ contract['Customer Home Phone'] }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label>Work Phone:</label>
          {{ contract['Customer Work Phone'] }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label>Plan Description:</label>
          {{ contract['Plan Description'] }}
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ContractAPIService } from 'src/servicehandlers/ContractHandler';
const contractAPIService = new ContractAPIService();
import { Dialog } from 'element-ui'

export default {
  name: "ContractLookupByVIN",
  data() {
    return {
      dates: [],
      contract: {},
      spinning: false,
      vin: '',
      results: false,
      error_txt: null,
      result_txt: '',
      modals: {
        basic: false,
        error: false,
      },
    }
  },
  components: {
    [Dialog.name]: Dialog,
  },
  created() {
  },
  methods: {
    vinLookup() {
      if (!this.vin) {
        this.spinning = false;
        this.error_txt = 'No VIN number supplied';
        this.openModal('error');
        return;
      }
      this.spinning = true;
      this.contract = {};
      this.results = false;
      return contractAPIService.getContractInfoFromVIN(this.vin, this.$router)
      .then((result) => {
        if (result && result.Status) {
          // console.log('Result: ', result);
          this.contract = result;
          this.results = true;
        }
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Failed to get contract! :' + error;
        this.openModal('error');
      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  },
}
</script>

<style scoped>

</style>
