import { CommonAPIService } from '../CommonHandler';

export class DealerAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getById(router, id) {
    const url = `/api/dealer/${id}`;
    return this.CommonAPIService.getCall(url, id, router);
  }

  getDealerList(router) {
    const url = '/api/dealer';
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default DealerAPIService;
