import { CommonAPIService } from '../CommonHandler';

export class UserAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getUserList(router) {
    const url = '/api/users';
    return this.CommonAPIService.getCall(url, '', router);
  }

  createUser(router, parms) {
    const url = '/api/users';
    return this.CommonAPIService.postCall(url, parms, router);
  }
}

export default UserAPIService;
