import { CommonAPIService } from './CommonHandler';

export class ReportsAPIHandler {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getRollingReport(date1, date2, router) {
    const url = `/api/reports/${date1}/${date2}/rolling`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getSalesData(start_date, end_date, router) {
    const url = `/api/reports/${start_date}/${end_date}/sales`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getOpenClaims(parms, router) {
    const url = `/api/reports/openclaims`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  getTreatyClaims(parms, router) {
    const url = `/api/reports/treatyclaims`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  getDuplicateVINS(date1, date2, carrier, router) {
    const url = `/api/reports/${date1}/${date2}/${carrier}/duplicatevins`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getContractData(parms, router) {
    const url = `/api/reports/contractdata`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  getFileStatus(short_name, router) {
    const url = `/api/reports/${short_name}/filestatus`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getTreatyData(parms, router) {
    const url = `/api/reports/treaty`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  getClaimData(parms, router) {
    const url = `/api/reports/claims`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  getNwapNONNwapData(parms, router) {
    const url = `/api/reports/nwapreport`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  getMonthlyCounts(year, router) {
    const url = `/api/reports/${year}/monthlycounts`;
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default ReportsAPIHandler;
