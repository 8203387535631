import { CommonAPIService } from './CommonHandler';

export class CustomersAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  update(id, parms, router) {
    const url = '/api/customers/' + id;
    return this.CommonAPIService.putCall(url, parms, router);
  }
}

export default CustomersAPIService;
