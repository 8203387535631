import { CommonAPIService } from '../CommonHandler';

export class QuickbooksAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  authCallback(callbackurl, router) {
    const url = `/api/quickbooks/${callbackurl}/url`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  authURI(router) {
    const url = '/api/quickbooks/authuri';
    return this.CommonAPIService.getCall(url, '', router);
  }

  retrieveToken(router) {
    const url = '/api/quickbooks/token';
    return this.CommonAPIService.getCall(url, '', router);
  }

  refreshToken(router) {
    const url = '/api/quickbooks/refresh';
    return this.CommonAPIService.getCall(url, '', router);
  }

  companyInfo(router) {
    const url = '/api/quickbooks/companyinfo';
    return this.CommonAPIService.getCall(url, '', router);
  }

  checkAuth(router) {
    const url = '/api/quickbooks/authenticated';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getReport27Items(router) {
    const url = '/api/quickbooks/report27';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getClaimUploadItems(router) {
    const url = '/api/quickbooks/claimuploaditems';
    return this.CommonAPIService.getCall(url, '', router);
  }

  uploadReport27Batch(files, router) {
    const url = '/api/quickbooks/report27upload';
    return this.CommonAPIService.postCall(url, files, router);
  }

  uploadClaimItems(files, router) {
    const url = '/api/quickbooks/claimupload';
    return this.CommonAPIService.postCall(url, files, router);
  }
}

export default QuickbooksAPIService;
