import { CommonAPIService } from '../CommonHandler';

export class QuickbooksAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  authenticate(router) {
    const url = `/api/reports/authenticate`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  createContract(parms, router) {
    const url = `/api/reports/contract`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  createClaim(parms, router) {
    const url = `/api/reports/claim`;
    return this.CommonAPIService.postCall(url, parms, router);
  }
}

export default QuickbooksAPIService;
