<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <div class="row">
      <div class="col-sm-12 text-center">
        <span style="font-size: 2em; font-weight: bold">Authgroup Creation</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <fg-input label="Authgroup Name:" placeholder="Authgroup Name" v-model="authgroup_name"></fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <button class="btn btn-primary" @click="createAuthGroup">Create Auth Group</button>
      </div>
    </div>
  </card>
  <card>
    <div class="row">
      <div class="col-sm-12 text-center">
        <span style="font-size: 2em; font-weight: bold">Authgroup Deletion</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4"><span style="font-weight: bold; font-size: 1em;">ID</span></div>
      <div class="col-sm-4"><span style="font-weight: bold; font-size: 1em;">Name</span></div>
      <div class="col-sm-4"><span style="font-weight: bold; font-size: 1em;">Action</span></div>
    </div>
    <div class="row" v-for="group in authGroups" v-bind:key="group.id">
      <div class="col-sm-4">{{ group.id }}</div>
      <div class="col-sm-4">{{ group.name }}</div>
      <div class="col-sm-4"><button class="btn btn-danger" @click="preDeleteAuthgroup(group.id, group.name)">-</button></div>
    </div>
  </card>
  <card>
    <div class="row">
      <div class="col-sm-12 text-center">
        <span style="font-size: 2em; font-weight: bold">User Authgroup Assign</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <label>Find User:</label>
        <model-select class="form-control select-primary" :options="users"
                      v-model="selected_user_id"
                      placeholder="Select a User"
                      @input="selectUser">
        </model-select>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-sm-6">
        <label>Select the Group(s) to add to the user</label>
        <multi-select class="form-control" :options="authGroups"
                      :selected-options="selected_auth_group_ids"
                      @select="onSelect"
                      placeholder="Select Authgroup(s) to put user into">
        </multi-select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 text-center">
        <button class="btn btn-primary" @click="save">Save</button>
      </div>
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <button class="btn btn-primary" @click="clear">Clear</button>
      </div>
    </div>
  </card>
  <el-dialog
    center
    title="Confirm Deletion of Auth Group"
    :visible.sync="modals.basic">
    <span>Are you sure you want to delete the auth group: {{ delete_authgroup_name }}?</span>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal('basic')">Cancel</button>&nbsp;
              <button class="btn btn-success" @click="deleteAuthGroup">Confirm</button>
            </span>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>Success: {{ success_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { Dialog } from 'element-ui';
import { ModelSelect, MultiSelect } from 'vue-search-select';
import { UserAPIService } from 'src/servicehandlers/archived_handlers/UsersAPIService';
import { AuthGroupAPIService } from 'src/servicehandlers/archived_handlers/AuthGroupAPIService';
const usersAPIService = new UserAPIService();
const authGroupAPIService = new AuthGroupAPIService();

export default {
  name: "UserAuthgroupsAdmin",
  components: {
    [Dialog.name]: Dialog,
    ModelSelect,
    MultiSelect
  },
  data() {
    return {
      selected_authgroups: [],
      stringItem: '',
      modals: {
        error: false,
        success: false,
      },
      users: [],
      authgroup_name: null,
      authGroups: [],
      delete_authgroup_id: null,
      delete_authgroup_name: null,
      lastSelectItem: null,
      selected_auth_group_ids: [],
      selected_user_id: null,
      selected_authgroup_id: null,
      results: false,
      result_txt: '',
      success_txt: '',
      error_txt: null,
      spinning: false,
    };
  },
  created() {
    this.getAllAuthGroups();
    this.getAllUsers();
  },
  methods: {
    preDeleteAuthgroup(group_id, group_name) {
      this.delete_authgroup_name = group_name;
      this.delete_authgroup_id = group_id;
      this.openModal('basic');
    },
    deleteAuthGroup() {
      this.closeModal('basic');
      this.spinning = true;
      if (!this.delete_authgroup_id) {
        this.error_txt = 'No Authgroup was selected!';
        this.openModal('error');
        this.spinning = false;
        return;
      }

      return authGroupAPIService.deleteAuthGroup(this.$router, this.delete_authgroup_id)
      .then(() => {
        this.getAllAuthGroups();
        this.success_txt = 'Successfully deleted authgroup!';
        this.openModal('success');
        this.selected_authgroup_id = null;
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Authgroup deletion failed: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    createAuthGroup() {
      this.spinning = true;
      if (!this.authgroup_name) {
        this.error_txt = 'No auth group name input!';
        this.openModal('error');
        this.spinning = false;
        return;
      }

      const parms = {
        name: this.authgroup_name
      };
      return authGroupAPIService.addAuthGroup(this.$router, parms)
      .then((authgroup) => {
        this.getAllAuthGroups();
        this.success_txt = 'Successfully created group: ' + authgroup.name;
        this.openModal('success');
        this.authgroup_name = null;
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Error creating auth group: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    save() {
      this.spinning = true;
      // save the IDS to the database for the user
      if (!this.selected_user_id) {
        this.error_txt = 'No user selected!';
        this.openModal('error');
        this.spinning = false;
        return;
      }
      if (this.selected_auth_group_ids.length <= 0) {
        this.error_txt = 'No auth groups selected!';
        this.openModal('error');
        this.spinning = false;
        return;
      }
      return authGroupAPIService.addUserToAuthGroups(this.selected_user_id, this.selected_auth_group_ids, this.$router)
      .then(() => {
        this.success_txt = 'Successfully updated groups for user';
        this.openModal('success');
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error saving users to groups: ' + error;
        this.openModal('error');
      });
    },
    clear() {
      this.selected_user_id = null;
      this.selected_auth_group_ids = [];
    },
    onSelect (items, lastSelectItem) {
      this.selected_auth_group_ids = items
      this.lastSelectItem = lastSelectItem
    },
    getAllUsers() {
      this.spinning = true;
      return usersAPIService.getEmailAdminList(this.$router)
      .then((allUsers) => {
        for (const user of allUsers) {
          user.text = user.full_name;
          user.value = user.id;
        }
        this.users = allUsers;
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = error;
        this.openModal('error');
      });
    },

    selectUser() {
      // get the full user by ID with scopes, etc, from the DB
      this.spinning = true;
      return usersAPIService.getUserWithAuthgroups(this.selected_user_id, this.$router)
      .then((selectedUser) => {
        this.spinning = false;
        const authgroups = selectedUser.authgroups;
        for (const group of authgroups) {
          group.text = group.name;
          group.value = group.id;
        }
        this.selected_auth_group_ids = authgroups;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting user from DB: ' + error;
        this.openModal('error');
      });
    },

    getAllAuthGroups() {
      this.spinning = true;
      return authGroupAPIService.getAuthGroups(this.$router)
      .then((agps) => {
        this.authGroups = agps;
        for (const group of this.authGroups) {
          group.text = group.name;
          group.value = group.id;
        }
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = error;
        this.spinning = false;
        this.openModal('error');
      });
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
  }
}
</script>

<style scoped>

</style>
