<template>
<div>
  <card>
    <div class="row">
      <div class="col-sm-12 text-center">
        <span style="font-weight: bold; font-size: 3em;">Welcome to Xzilon Intweb</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-center">
        <img class="img-responsive" src="@/assets/xzilon_logo.png" alt="Xzilon Logo"/>
      </div>
    </div>
  </card>
</div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>
