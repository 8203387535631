import Vue from 'vue';
import Router from 'vue-router';
import { store } from '../store';
import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
// Dashboard pages
import Overview from 'src/pages/Dashboard/Dashboard/Overview.vue'
import Stats from 'src/pages/Dashboard/Dashboard/Stats.vue'

// Pages
import Home from 'src/pages/Dashboard/Pages/Home.vue'
import About from 'src/pages/Dashboard/Pages/About.vue'
import TimeLine from 'src/pages/Dashboard/Pages/TimeLinePage.vue'
import Login from 'src/pages/Dashboard/Pages/Login.vue'
import Register from 'src/pages/Dashboard/Pages/Register.vue'
import Lock from 'src/pages/Dashboard/Pages/Lock.vue'

// Autoland pages
import AutoLand from 'src/pages/Dashboard/Pages/Autoland/Autoland.vue'

// Claims Pages
import ClaimsMain from 'src/pages/Dashboard/Pages/Claims/ClaimsMain.vue'
import ClaimUpload from 'src/pages/Dashboard/Pages/Claims/ClaimUpload.vue'
import ClaimSearch from 'src/pages/Dashboard/Pages/Claims/ClaimSearch.vue'
import ClaimCreation from 'src/pages/Dashboard/Pages/Claims/ClaimCreation.vue'
import ClaimStatus from 'src/pages/Dashboard/Pages/Claims/ClaimStatus.vue'

// order pages
import OrdersMain from 'src/pages/Dashboard/Pages/Order/OrdersMain.vue'
import OrderSearch from 'src/pages/Dashboard/Pages/Order/OrderSearch.vue'

// Checklog Pages
import CheckLogMain from 'src/pages/Dashboard/Pages/CheckLog/CheckLogMain.vue'
import CheckLogStatus from 'src/pages/Dashboard/Pages/CheckLog/CheckLogStatus.vue'
import CheckLogSearch from 'src/pages/Dashboard/Pages/CheckLog/CheckLogSearch.vue'

// User Pages
import User from 'src/pages/Dashboard/Pages/UserProfile.vue'
import UserAdmin from 'src/pages/Dashboard/Pages/Users/UserAdmin'
import UserAuthgroupsAdmin from 'src/pages/Dashboard/Pages/Users/UserAuthgroupsAdmin'
import AuthGroupsScopesAdmin from 'src/pages/Dashboard/Pages/Users/AuthGroupsScopesAdmin'

// Reports pages
import PCMI from 'src/pages/Dashboard/Pages/Reports/PCMI/PCMI.vue'
import PCMIContract from 'src/pages/Dashboard/Pages/Reports/PCMI/PCMIContract.vue'
import PCMIClaim from 'src/pages/Dashboard/Pages/Reports/PCMI/PCMIClaim.vue'
import PCMIAuthenticate from 'src/pages/Dashboard/Pages/Reports/PCMI/PCMIAuthentication.vue'
import PCMIRollingReport from 'src/pages/Dashboard/Pages/Reports/PCMI/PCMIRollingReport.vue'
import ContractsSold from 'src/pages/Dashboard/Pages/Reports/SCS/ContractsSold.vue'
import ContractLookupByVIN from 'src/pages/Dashboard/Pages/Reports/SCS/ContractLookupByVIN.vue'
import PCMIDuplicateVIN from 'src/pages/Dashboard/Pages/Reports/PCMI/PCMIDuplicateVIN.vue'
import PCMIContractData from 'src/pages/Dashboard/Pages/Reports/PCMI/PCMIContractData.vue'
import PCMIClaims from 'src/pages/Dashboard/Pages/Reports/PCMI/PCMIClaims.vue'
import PCMINWAPReport from 'src/pages/Dashboard/Pages/Reports/PCMI/PCMINWAPReport.vue'
import PCMIDailyCharts from 'src/pages/Dashboard/Pages/Reports/PCMI/PCMIDailyCharts.vue'
import PCMITreatyReport from 'src/pages/Dashboard/Pages/Reports/PCMI/PCMITreatyReport.vue'
import PCMITreatyClaimsReport from 'src/pages/Dashboard/Pages/Reports/PCMI/PCMITreatyClaimsReport.vue'
import SalesByDate from 'src/pages/Dashboard/Pages/Reports/PCMI/SalesByDate.vue'
import PCMIOpenClaims from 'src/pages/Dashboard/Pages/Reports/PCMI/PCMIOpenClaims.vue'

// Components pages
import Buttons from 'src/pages/Dashboard/Components/Buttons.vue'
import GridSystem from 'src/pages/Dashboard/Components/GridSystem.vue'
import Panels from 'src/pages/Dashboard/Components/Panels.vue'
import Notifications from 'src/pages/Dashboard/Components/Notifications.vue'
import Icons from 'src/pages/Dashboard/Components/Icons.vue'
import Typography from 'src/pages/Dashboard/Components/Typography.vue'
import QuickbooksMain from "../pages/Dashboard/Pages/Quickbooks/QuickbooksMain";

const SweetAlert = () => import('src/pages/Dashboard/Components/SweetAlert.vue')

// Forms pages
const RegularForms = () => import('src/pages/Dashboard/Forms/RegularForms.vue')
const ExtendedForms = () => import('src/pages/Dashboard/Forms/ExtendedForms.vue')
const ValidationForms = () => import('src/pages/Dashboard/Forms/ValidationForms.vue')
const Wizard = () => import('src/pages/Dashboard/Forms/Wizard.vue')

// TableList pages
const RegularTables = () => import('src/pages/Dashboard/Tables/RegularTables.vue')
const ExtendedTables = () => import('src/pages/Dashboard/Tables/ExtendedTables.vue')
const PaginatedTables = () => import('src/pages/Dashboard/Tables/PaginatedTables.vue')
// Maps pages
const GoogleMaps = () => import('src/pages/Dashboard/Maps/GoogleMaps.vue')
const FullScreenMap = () => import('src/pages/Dashboard/Maps/FullScreenMap.vue')
const VectorMaps = () => import('src/pages/Dashboard/Maps/VectorMapsPage.vue')

// Calendar
const Calendar = () => import('src/pages/Dashboard/Calendar/CalendarRoute.vue')
// Charts
const Charts = () => import('src/pages/Dashboard/Charts.vue')
Vue.use(Router);

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'panels',
      name: 'Panels',
      component: Panels
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      component: SweetAlert
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }

  ]
}
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      component: RegularForms
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      component: ExtendedForms
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      component: ValidationForms
    },
    {
      path: 'wizard',
      name: 'Wizard',
      component: Wizard
    }
  ]
}

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }]
}

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      component: FullScreenMap
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
}

let usersMenu = {
  path: '/usersmain',
  component: DashboardLayout,
  redirect: '/usersmain/useradmin',
  children: [
    {
      path: 'useradmin',
      component: UserAdmin,
      name: 'User Administration'
    },
    {
      path: 'authgroupsscopes',
      component: AuthGroupsScopesAdmin,
      name: 'Auth Group to Scopes '
    },
    {
      path: 'userauthgroups',
      component: UserAuthgroupsAdmin,
      name: 'Users to Auth Groups'
    },
  ]
};

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let aboutPage = {
  path: '/about',
  name: 'About',
  component: DashboardLayout,
  redirect: '/about/abt',
  children: [
    {
      path: 'abt',
      name: 'Abt',
      component: About
    },
  ]
}

let autolandMenu = {
  path: '/autolandmain',
  name: 'AutoLandMain',
  component: DashboardLayout,
  redirect: '/autolandmain/autoland',
  children: [
    {
      path: 'autoland',
      name: 'Autoland',
      component: AutoLand
    },
  ]
}

let orderMenu = {
  path: '/ordermain',
  name: 'Order Main',
  component: DashboardLayout,
  redirect: '/ordermain/order',
  children: [
    {
      path: 'order',
      name: 'Order Page',
      component: OrdersMain
    },
    {
      path: 'ordersearch',
      name: 'Order Search',
      component: OrderSearch
    },
  ]
}

let checklogMenu = {
  path: '/checklogmain',
  name: 'ChecklogMain',
  component: DashboardLayout,
  redirect: '/checklogmain/checkmain',
  children: [
    {
      path: 'checkmain',
      name: 'Check Log',
      component: CheckLogMain
    },
    {
      path: 'checkstatus',
      name: 'Check Log Status',
      component: CheckLogStatus
    },
    {
      path: 'checksearch',
      name: 'Check Log Search',
      component: CheckLogSearch
    },
  ]
}

let reportsMenu = {
  path: '/reportsmain',
  name: 'ReportsMain',
  component: DashboardLayout,
  redirect: '/reportsmain/pcmi',
  children: [
    {
      path: 'pcmi',
      name: 'PCMI Contract',
      component: PCMI
    },
    {
      path: 'pcmicontract',
      name: 'PCMI Create Contract',
      component: PCMIContract
    },
    {
      path: 'pcmiauthenticate',
      name: 'PCMI Authenticate',
      component: PCMIAuthenticate
    },
    {
      path: 'rollingreport',
      name: 'Rolling Report',
      component: PCMIRollingReport
    },
    {
      path: 'duplicatevins',
      name: 'Duplicate Vins',
      component: PCMIDuplicateVIN,
    },
    {
      path: 'contractdata',
      name: 'Contract Data',
      component: PCMIContractData,
    },
    {
      path: 'claims',
      name: 'PCMI Claims',
      component: PCMIClaims,
    },
    {
      path: 'nwapreport',
      name: 'NWAP Report',
      component: PCMINWAPReport
    },
    {
      path: 'pcmiclaim',
      name: 'PCMI Claim',
      component: PCMIClaim
    },
    {
      path: 'conractssold',
      name: 'Contracts Sold',
      component: ContractsSold
    },
    {
      path: 'contractlookup',
      name: 'Contract Lookup',
      component: ContractLookupByVIN
    },
    {
      path: 'contractcount',
      name: 'Contract Counts',
      component: PCMIDailyCharts
    },
    {
      path: 'treaty',
      name: 'Treaty Report',
      component: PCMITreatyReport
    },
    {
      path: 'treatyclaims',
      name: 'Cession Treaty Report',
      component: PCMITreatyClaimsReport
    },
    {
      path: 'sales',
      name: 'Sales Report',
      component: SalesByDate
    },
    {
      path: 'openclaims',
      name: 'Open Claims',
      component: PCMIOpenClaims
    },
  ]
}

let quickbooksMenu = {
  path: '/quickbooksmain',
  name: 'QuickbooksMain',
  component: DashboardLayout,
  redirect: '/quickbooksmain/quickbooks',
  children: [
    {
      path: 'quickbooks',
      name: 'Quickbooks',
      component: QuickbooksMain
    },
  ]
}

let claimsMenu = {
  path: '/claimsmain',
  name: 'ClaimsMain',
  component: DashboardLayout,
  redirect: '/claimsmain/claims',
  children: [
    {
      path: 'claims',
      name: 'Claims',
      component: ClaimsMain
    },
    {
      path: 'search',
      name: 'Claim Search',
      component: ClaimSearch
    },
    {
      path: 'create',
      name: 'Claim Creation',
      component: ClaimCreation
    },
  ]
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register
}

let claimuploadPage = {
  path: '/claimupload',
  name: 'Claim Upload',
  component: ClaimUpload
}

let claimStatus = {
  path: '/claimstatus',
  name: 'Claim Status',
  component: ClaimStatus
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

export const router = new Router({
  linkActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'main',
      component: DashboardLayout,
      redirect: '/home',
      children: [
        {
          path: 'home',
          name: 'Home Page',
          component: Home
        },
      ]
    },
    usersMenu,
    loginPage,
    aboutPage,
    componentsMenu,
    checklogMenu,
    reportsMenu,
    autolandMenu,
    quickbooksMenu,
    claimsMenu,
    claimuploadPage,
    claimStatus,
    orderMenu,
    { path: '*', component: NotFound }
  ]
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login','/claimupload','/claimstatus'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.state.loggedIn;

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});
