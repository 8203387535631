import { CommonAPIService } from '../CommonHandler';

export class ChecklogAPIService {
  constructor() {
    this.checklog = null;
    this.checklogID = null;
    this.date = null;
    this.CommonAPIService = new CommonAPIService();
  }

  searchCheckLog(parms, router) {
    const url = '/api/checklog/search';
    return this.CommonAPIService.postCall(url, parms, router);
  }

  updateChecklog(checklog, router) {
    this.checklog = checklog;
    const url = '/api/checklog/update';
    return this.CommonAPIService.postCall(url, this.checklog, router);
  }

  deleteChecklog(checklogId, router) {
    this.checklogID = checklogId;
    const url = `/api/checklog/${this.checklogID}`;
    return this.CommonAPIService.deleteCall(url, this.checklogID, router);
  }

  getUnresolvedLogs(parms, router) {
    const url = '/api/checklog';
    return this.CommonAPIService.postCall(url, parms, router);
  }

  update(parms, router) {
    this.checklog = parms;
    const url = '/api/checklog/update';
    return this.CommonAPIService.postCall(url, this.checklog, router);
  }

  addChecklog(checklog, router) {
    this.checklog = checklog;
    const url = '/api/checklog/create';
    return this.CommonAPIService.postCall(url, this.checklog, router);
  }

  getCheckLog(date, router) {
    this.date = date;
    const url = `/api/checklog/bydate/${this.date}`;
    return this.CommonAPIService.getCall(url, this.date, router);
  }
}

export default ChecklogAPIService;
