<template>
  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <side-bar>
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item :link="{name: 'Home', icon: 'nc-icon nc-app', path: '/home'}"></sidebar-item>
        <sidebar-item v-if="autoland_link === true" :link="{name: 'Autoland', icon: 'nc-icon nc-pin-3', path: '/autolandmain'}"></sidebar-item>
        <sidebar-item v-if="checklog_link === true" :link="{name: 'Check Log', icon: 'nc-icon nc-notes', path: '/checklogmain'}">
          <sidebar-item v-if="checklog_main_page === true" :link="{name: 'Check Log', icon: 'nc-icon nc-notes', path: '/checklogmain/checkmain'}"></sidebar-item>
          <sidebar-item v-if="checklog_status_page === true" :link="{name: 'Check Log Status', icon: 'nc-icon nc-notes', path: '/checklogmain/checkstatus'}"></sidebar-item>
          <sidebar-item v-if="checklog_search_page === true" :link="{name: 'Check Log Search', icon: 'nc-icon nc-notes', path: '/checklogmain/checksearch'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="claims_link === true" :link="{name: 'Claims', icon: 'nc-icon nc-chart-bar-32', path: '/claimsmain'}">
          <sidebar-item v-if="claims_main_page === true" :link="{name: 'Claims Page', path: '/claimsmain/claims'}"></sidebar-item>
          <sidebar-item v-if="claims_search_page === true" :link="{name: 'Claims Search', path: '/claimsmain/search'}"></sidebar-item>
          <sidebar-item v-if="claims_creation_page === true" :link="{name: 'Claims Creation', path: '/claimsmain/create'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="reports_link === true" :link="{name: 'Reports', icon: 'nc-icon nc-paper-2', path: '/reportsmain'}">
          <sidebar-item v-if="pcmi_main_page === true" :link="{name: 'PCMI', icon: 'nc-icon nc-notes'}">
            <sidebar-item v-if="pcmi_main_page === true" :link="{name: 'PCMI Main Page', icon: 'nc-icon nc-notes', path: '/reportsmain/pcmi'}"></sidebar-item>
            <sidebar-item v-if="pcmi_rollout_report_page === true" :link="{name: 'PCMI Rolling Report', icon: 'nc-icon nc-notes', path: '/reportsmain/rollingreport'}"></sidebar-item>
            <sidebar-item v-if="pcmi_duplicate_vins === true" :link="{name: 'PCMI Duplicate VINS', icon: 'nc-icon nc-notes', path: '/reportsmain/duplicatevins'}"></sidebar-item>
            <sidebar-item v-if="pcmi_contract_data === true" :link="{name: 'PCMI Contract data', icon: 'nc-icon nc-notes', path: '/reportsmain/contractdata'}"></sidebar-item>
            <sidebar-item v-if="pcmi_claims_data === true" :link="{name: 'PCMI Claims', icon: 'nc-icon nc-notes', path: '/reportsmain/claims'}"></sidebar-item>
            <sidebar-item v-if="pcmi_nwap_page === true" :link="{name: 'NWAP NON NWAP', icon: 'nc-icon nc-notes', path: '/reportsmain/nwapreport'}"></sidebar-item>
            <sidebar-item v-if="pcmi_contract_states === true" :link="{name: 'Contracts by State', icon: 'nc-icon nc-notes', path: '/reportsmain/contractsbystate'}"></sidebar-item>
            <sidebar-item v-if="pcmi_treaty_page === true" :link="{name: 'PCMI Treaty', icon: 'nc-icon nc-notes', path: '/reportsmain/treaty'}"></sidebar-item>
            <sidebar-item v-if="pcmi_treaty_claims === true" :link="{name: 'PCMI Cession Treaty', icon: 'nc-icon nc-notes', path: '/reportsmain/treatyclaims'}"></sidebar-item>
            <sidebar-item v-if="pcmi_contract_count === true" :link="{name: 'Contract Count', icon: 'nc-icon nc-notes', path: '/reportsmain/contractcount'}"></sidebar-item>
            <sidebar-item v-if="pcmi_sales === true" :link="{name: 'Sales Report', icon: 'nc-icon nc-notes', path: '/reportsmain/sales'}"></sidebar-item>
            <sidebar-item v-if="pcmi_open_claims === true" :link="{name: 'Open Claims', icon: 'nc-icon nc-notes', path: '/reportsmain/openclaims'}"></sidebar-item>
          </sidebar-item>
          <sidebar-item v-if="scs_reports_link === true" :link="{name: 'SCS', icon: 'nc-icon nc-notes'}">
            <sidebar-item v-if="scs_contractssold_page === true" :link="{name: 'Contracts Sold', icon: 'nc-icon nc-notes', path: '/reportsmain/contractssold'}"></sidebar-item>
            <sidebar-item v-if="scs_contractlookup_page === true" :link="{name: 'Contract Lookup', icon: 'nc-icon nc-notes', path: '/reportsmain/contractlookup'}"></sidebar-item>
          </sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="orders_link === true" :link="{name: 'Orders', icon: 'nc-icon nc-android', path: '/ordermain'}">
          <sidebar-item v-if="orders_main_page === true" :link="{name: 'Orders', path: '/ordermain/order'}"></sidebar-item>
          <sidebar-item v-if="orders_search_page === true" :link="{name: 'Order Search', path: '/ordermain/ordersearch'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="quickbooks_link === true" :link="{name: 'Quickbooks', icon: 'nc-icon nc-puzzle-10', path: '/quickbooksmain'}"></sidebar-item>
        <sidebar-item v-if="users_link === true" :link="{name: 'Users', icon: 'nc-icon nc-notes', path: '/usersmain'}">
          <sidebar-item v-if="users_main_page === true" :link="{name: 'User Admin', icon: 'nc-icon nc-puzzle-10', path: '/usersmain/useradmin'}"></sidebar-item>
          <sidebar-item v-if="users_scopes_page === true" :link="{name: 'Scopes Admin', icon: 'nc-icon nc-puzzle-10', path: '/usersmain/authgroupsscopes'}"></sidebar-item>
          <sidebar-item v-if="users_authgroups_page === true" :link="{name: 'User Groups Admin', icon: 'nc-icon nc-puzzle-10', path: '/usersmain/userauthgroups'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'About', icon: 'nc-icon nc-album-2', path: '/about'}"></sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">
      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import MobileMenu from './Extra/MobileMenu.vue'
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    name: 'DashboardLayout',
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      MobileMenu,
    },
    data() {
      return {
        'reports_link': false,
        'pcmi_main_page': false,
        'pcmi_authentication_page': false,
        'pcmi_contracts_page': false,
        'pcmi_claims_page': false,
        'pcmi_rollout_report_page': false,
        'checklog_link': false,
        'checklog_main_page': false,
        'checklog_status_page': false,
        'checklog_search_page': false,
        'orders_link': false,
        'orders_main_page': false,
        'orders_search_page': false,
        'autoland_link': false,
        'claims_link': false,
        'claims_main_page': false,
        'claims_search_page': false,
        'quickbooks_link': false,
        'users_link': false,
        'users_main_page': false,
        'users_scopes_page': false,
        'users_authgroups_page': false,
        'contractssold_page': false,
        'scs_reports_link': false,
        'scs_contractssold_page': false,
        'scs_contractlookup_page': false,
        'claims_creation_page': false,
        'pcmi_duplicate_vins': false,
        'pcmi_contract_data': false,
        'pcmi_claims_data': false,
        'pcmi_nwap_page': false,
        'pcmi_contract_states': false,
        'pcmi_treaty_page': false,
        'pcmi_treaty_claims': false,
        'pcmi_contract_count': false,
        'pcmi_sales': false,
        'pcmi_open_claims': false,
      };
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
      initScrollbar() {
        let docClasses = document.body.classList;
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          // if we are on windows OS we activate the perfectScrollbar function
          initScrollbar('main-panel');

          docClasses.add('perfect-scrollbar-on');
        } else {
          docClasses.add('perfect-scrollbar-off');
        }
      }
    },
    mounted() {
      const scopes = this.$store.getters.scopes;
      for (const scope of scopes) {
        if (scope.name === 'autoland-link') {
          this.autoland_link = true;
        }
        if (scope.name === 'checklog-link') {
          this.checklog_link = true;
        }
        if (scope.name === 'orders-link') {
          this.orders_link = true;
        }
        if (scope.name === 'reports-link') {
          this.reports_link = true;
        }
        if (scope.name === 'claims-link') {
          this.claims_link = true;
        }
        if (scope.name === 'quickbooks-link') {
          this.quickbooks_link = true;
        }
        if (scope.name === 'users-link') {
          this.users_link = true;
        }
        if (scope.name === 'pcmi-main-page') {
          this.pcmi_main_page = true;
        }
        if (scope.name === 'pcmi-authentication-page') {
          this.pcmi_authentication_page = true;
        }
        if (scope.name === 'pcmi-contract-page') {
          this.pcmi_contracts_page = true;
        }
        if (scope.name === 'pcmi-claim-page') {
          this.pcmi_claims_page = true;
        }
        if (scope.name === 'pcmi-rollout-report-page') {
          this.pcmi_rollout_report_page = true;
        }
        if (scope.name === 'checklog-page') {
          this.checklog_main_page = true;
        }
        if (scope.name === 'checklog-status-page') {
          this.checklog_status_page = true;
        }
        if (scope.name === 'checklog-search-page') {
          this.checklog_search_page = true;
        }
        if (scope.name === 'order-main-page') {
          this.orders_main_page = true;
        }
        if (scope.name === 'order-search-page') {
          this.orders_search_page = true;
        }
        if (scope.name === 'user-admin-page') {
          this.users_main_page = true;
        }
        if (scope.name === 'user-authgroups-page') {
          this.users_authgroups_page = true;
        }
        if (scope.name === 'user-scopes-page') {
          this.users_scopes_page = true;
        }
        if (scope.name === 'claims-main-page') {
          this.claims_main_page = true;
        }
        if (scope.name === 'claims-search-page') {
          this.claims_search_page = true;
        }
        if (scope.name === 'scs-contractssold-page') {
          this.scs_contractssold_page = true;
        }
        if (scope.name === 'scs-reports-link') {
          this.scs_reports_link = true;
        }
        if (scope.name === 'scs-contractlookup-page') {
          this.scs_contractlookup_page = true;
        }
        if (scope.name === 'claims-creation-page') {
          this.claims_creation_page = true;
        }
        if (scope.name === 'pcmi-duplicate-vins') {
          this.pcmi_duplicate_vins = true;
        }
        if (scope.name === 'pcmi-contract-data') {
          this.pcmi_contract_data = true;
        }
        if (scope.name === 'pcmi-claims-data') {
          this.pcmi_claims_data = true;
        }
        if (scope.name === 'pcmi-nwap-page') {
          this.pcmi_nwap_page = true;
        }
        if (scope.name === 'pcmi-contract-by-state') {
          this.pcmi_contract_states = true;
        }
        if (scope.name === 'pcmi-treaty-page') {
          this.pcmi_treaty_page = true;
        }
        if (scope.name === 'pcmi-treaty-claims') {
          this.pcmi_treaty_claims = true;
        }
        if (scope.name === 'pcmi-contract-count') {
          this.pcmi_contract_count = true;
        }
        if (scope.name === 'pcmi-sales') {
          this.pcmi_sales = true;
        }
        if (scope.name === 'pcmi-open-claims') {
          this.pcmi_open_claims = true;
        }
      }
      this.initScrollbar();
      // get the rights
    }
  }

</script>
