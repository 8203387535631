<template>
  <div>
    <b-container v-if="spinning">
      <b-row>
        <b-col><b-spinner variant="primary" label="Spinning"></b-spinner></b-col>
      </b-row>
    </b-container>
    <b-tabs content-class="mt-3">
      <b-tab title="New" active>
        <contracts
          title="New Autoland Contracts"
          search_status='New'
          dealer_name="AUTOLAND LLC"
          :plans="plans"
          :status="status"
          :detailers="detailers"
          :schedulers="schedulers">
        </contracts>
      </b-tab>
      <b-tab title="Assigned">
        <contracts
          title="Assigned Autoland Contracts"
          search_status="Assigned"
          dealer_name="AUTOLAND LLC"
          :plans="plans"
          :status="status"
          :detailers="detailers"
          :schedulers="schedulers">
        </contracts>
      </b-tab>
      <b-tab title="Completed">
        <contracts
          title="Completed Autoland Contracts"
          search_status="Completed"
          dealer_name="AUTOLAND LLC"
          :plans="plans"
          :status="status"
          :detailers="detailers"
          :schedulers="schedulers">
        </contracts>
      </b-tab>
      <b-tab title="Paid">
        <contracts
          title="Paid Autoland Contracts"
          search_status="Paid"
          dealer_name="AUTOLAND LLC"
          :plans="plans"
          :status="status"
          :detailers="detailers"
          :schedulers="schedulers">
        </contracts>
      </b-tab>
      <b-tab title="Search">
        <search-contracts
          title="Search Autoland Contracts"
          dealer_name="AUTOLAND LLC"
          :plans="plans"
          :status="status"
          :detailers="detailers"
          :schedulers="schedulers">
        </search-contracts>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { ContractAPIService } from 'src/servicehandlers/ContractHandler';
import { PlansAPIService } from 'src/servicehandlers/PlansHandler';
import Contracts from 'src/pages/Dashboard/Components/Contracts';
import SearchContracts from 'src/pages/Dashboard/Components/SearchContracts';

const contractAPIService = new ContractAPIService();
const plansAPIService = new PlansAPIService();

export default {
  name: 'Autoland',
  components: {
    Contracts,
    SearchContracts,
  },
  data() {
    return {
      loading: false,
      totalContracts: 0,
      totalCompletedContracts: 0,
      totalScheduledContracts: 0,
      totalPaidContracts: 0,
      completedContracts: [],
      scheduledContracts: [],
      paidContracts: [],
      currentPage: 1, // for pagination
      perPage: 20, // for pagination
      sortBy: null, // for the b-table
      sortDesc: false, // for the b-table
      filter: null, // for the b-table
      spinning: false,
      contracts: [],
      detailers: [],
      plans: [],
      schedulers: [],
      status: [],
      title: null,
      id: null,
      fields: [
        { key: 'purchase_date', label: 'Dates', sortable: true },
        { key: 'contract_number', label: 'Contract #', sortable: true },
        { key: 'customer.first', label: 'Customer', sortable: true },
        { key: 'car.make', label: 'Car', sortable: true },
        { key: 'detailers', label: 'Detailers', sortable: true },
        { key: 'schedulers', label: 'Schedulers', sortable: true },
        { key: 'plan', label: 'Plan', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
    };
  },
  watch: {
    currentPage() {
      this.getAutolandContracts();
    },
  },
  mounted() {
    const loggedIn = this.$store.getters.loggedIn;
    if (!loggedIn) {
      this.$router.push({
        name: 'Login',
      });
    }
    if (this.loading) {
      return;
    }
    this.getAutolandContracts();
  },

  methods: {
    getAutolandContracts() {
      this.spinning = true;
      if (this.loading) {
        return;
      }
      this.loading = true;
      plansAPIService.getPlans('', this.$router)
        .then((plans) => {
          this.plans = plans;
          return contractAPIService.getContractStatus(this.$router);
        })
        .then((status) => {
          this.status = status;
          return contractAPIService.getContractDetailer(this.$router);
        })
        .then((detailers) => {
          this.detailers = detailers;
          return contractAPIService.getContractSchedulers(this.$router);
        })
        .then((schedulers) => {
          this.schedulers = schedulers;
          this.loading = false;
          this.spinning = false;
          return true;
        })
        .catch((error) => {
          this.loading = false;
          this.spinning = false;
          // console.log('ERROR: ', error);
        });
    },
  },
};
</script>

<style>
.lgsize {
  width: 5em;
}
</style>
