<template>
  <div>
    <card v-if="spinning">
      <b-row>
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-12 text-center"><span style="font-weight: bold; font-size: 3em;">Claim Search Page</span>
        </div>
      </div>
    </card>

    <card>
      <div class="row pb-4">
        <div class="col-sm-4 text-center">Contract and Claim Information</div>
        <div class="col-sm-4 text-center">Car Information</div>
        <div class="col-sm-4 text-center">Customer Information</div>
      </div>
      <div class="row">
        <div class="col-sm-4">

          <!-- Claim information -->
          <div class="row">
            <div class="col-sm-2">
              <label>Claim Date:&nbsp;</label>
            </div>
            <div class="col-sm-10">
              <datepicker
                :bootstrap-styling="true"
                :format="customFormatter"
                placeholder="Claim Date"
                v-model="searchItems.claimdate">
              </datepicker>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <label>Contract Number:&nbsp;</label>
              <input class="form-control" type="text" v-model="searchItems.contract"
                     placeholder="Contract">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label>SCS Number:&nbsp;</label>
              <input class="form-control" type="text" v-model="searchItems.scs_claim_number"
                     placeholder="SCS Claim Number">
            </div>
          </div>
        </div>
        <!-- End of claim information -->

        <!-- Car information -->
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-3">
              <label>Make:&nbsp;</label>
            </div>
            <div class="col-sm-9">
              <input type="text" class="form-control" v-model="searchItems.make" placeholder="Make">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <label>Model:&nbsp;</label>
            </div>
            <div class="col-sm-9">
              <input type="text" class="form-control" v-model="searchItems.model"
                     placeholder="Model">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <label>Vin:&nbsp;</label>
            </div>
            <div class="col-sm-9">
              <input type="text" class="form-control" v-model="searchItems.vin" placeholder="VIN">
            </div>
          </div>
        </div>
        <!-- End of Car information -->

        <!-- Customer information -->
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-2">
              <label>First Name:&nbsp;</label>
            </div>
            <div class="col-sm-4">
              <input class="form-control" type="text" v-model="searchItems.first"
                     placeholder="First Name">
            </div>
            <div class="col-sm-2">
              <label>Last Name:&nbsp;</label>
            </div>
            <div class="col-sm-4">
              <input class="form-control" type="text" v-model="searchItems.last"
                     placeholder="Last Name">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <label>Address:&nbsp;</label>
            </div>
            <div class="col-sm-4">
              <input class="form-control" type="text" v-model="searchItems.address"
                     placeholder="Address">
            </div>
            <div class="col-sm-2">
              <label>Email:&nbsp;</label>
            </div>
            <div class="col-sm-4">
              <input class="form-control" type="text" v-model="searchItems.email"
                     placeholder="Email">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <label>City:&nbsp;</label>
            </div>
            <div class="col-sm-4">
              <input class="form-control" type="text" v-model="searchItems.city"
                     placeholder="City">
            </div>
            <div class="col-sm-2">
              <label>State:&nbsp;</label>
            </div>
            <div class="col-sm-4">
              <input class="form-control" type="text" v-model="searchItems.state"
                     placeholder="State">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <label>Zip:&nbsp;</label>
            </div>
            <div class="col-sm-4">
              <input class="form-control" type="text" v-model="searchItems.zip" placeholder="Zip">
            </div>
            <div class="col-sm-2">
              <label>Phone:&nbsp;</label>
            </div>
            <div class="col-sm-4">
              <input class="form-control" type="text" v-model="searchItems.phone"
                     placeholder="Phone">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5"></div>
        <div class="col-sm-1">
          <button class="btn btn-primary" @click="searchAll">Search</button>
        </div>
        <div class="col-sm-1">
          <button class="btn btn-primary" @click="clearSearch">Clear Search</button>
        </div>
        <div class="col-sm-5"></div>
      </div>
    </card>
    <!-- End of Customer information -->


    <card>
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-pagination
            :total-rows="totalClaims"
            :per-page="perPage"
            v-model="currentPage">
          </b-pagination>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-center">
          <span style="font-weight: bold; font-size: 2em;">Claim</span>
        </div>
        <div class="col-sm-3 text-center">
          <span style="font-weight: bold; font-size: 2em;">Car</span>
        </div>
        <div class="col-sm-3 text-center">
          <span style="font-weight: bold; font-size: 2em;">Customer</span>
        </div>
        <div class="col-sm-2 text-center">
          <span style="font-weight: bold; font-size: 2em;">Operations</span>
        </div>
      </div>
      <div class="row packetclass pt-3 pb-3" v-for="claim in claims" v-bind:key="claim.id">
        <div class="col-sm-4">


          <div class="row">
            <div class="col-sm-12">
              <label>Claimant:&nbsp;</label>
              <label>{{ claim.customer.claimant }}</label>
            </div>
          </div>


          <div class="row">
            <div class="col-sm-1"><label>Claim Date:&nbsp;</label></div>
            <div class="col-sm-5">
              <datepicker
                :bootstrap-styling="true"
                @input="setItem(claim.id, 'claimdate', claim.claimdate)"
                :format="customFormatter"
                placeholder="Claim Date"
                v-model="claim.claimdate">
              </datepicker>
            </div>
            <div class="col-sm-5"></div>
          </div>


          <div class="row">
            <div class="col-sm-4">
              <label>Contract Number:&nbsp;</label>
            </div>
            <div class="col-sm-8">
              <input class="form-control" type="text"
                     @change="setItem(claim.car_id, 'warranty_num', claim.car.warranty_num, 'cars')"
                     v-model="claim.car.warranty_num"
                     placeholder="Contract Number">
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <label>SCS Claim #:&nbsp;</label>
              <label>{{ claim.scs_claim_number }}</label>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <label>PCRS Claim #:&nbsp;</label>
              <label>{{ claim.pcmi_claim_number }}</label>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3">
              <label>Ready: &nbsp;</label><input type="checkbox"
                                                 @click="setItem(claim.id, 'ready', claim.ready)"
                                                 v-model="claim.ready">
            </div>
            <div class="col-sm-3">
              <label>Waiting:&nbsp;</label><input type="checkbox"
                                                  @click="setItem(claim.id, 'waiting', claim.waiting)"
                                                  v-model="claim.waiting">
            </div>
            <div class="col-sm-3">
              <label>Uploaded:&nbsp;</label><input type="checkbox"
                                                   @click="setItem(claim.id, 'uploaded', claim.uploaded)"
                                                   v-model="claim.uploaded">
            </div>
            <div class="col-sm-3">
              <label>Processed:&nbsp;</label><input type="checkbox"
                                                    @click="setItem(claim.id, 'processed', claim.processed)"
                                                    v-model="claim.processed">
            </div>
          </div>


          <div class="row pb-3">
            <div class="col-sm-12 text-center">
              <label>Description:</label>
            </div>
            <div class="col-sm-12 text-center">
               <textarea v-model="claim.description" class="form-control" rows="5"
                         @input="setItem(claim.id, 'description', claim.description)"
               ></textarea>
            </div>
          </div>
        </div>

        <!-- Begin Car Information -->
        <div class="col-sm-3">
          <div class="row">
            <div class="col-sm-2">
              <label>Make:&nbsp;</label>
            </div>
            <div class="col-sm-10">
              <input type="text" class="form-control"
                     @change="setItem(claim.car_id, 'make', claim.car.make, 'cars')"
                     v-model="claim.car.make" placeholder="Make">
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2">
              <label>Model:&nbsp;</label>
            </div>
            <div class="col-sm-10">
              <input type="text" class="form-control"
                     @change="setItem(claim.car_id, 'model', claim.car.model, 'cars')"
                     v-model="claim.car.model" placeholder="Model">
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2">
              <label>VIN:&nbsp;</label>
            </div>
            <div class="col-sm-10">
              <input type="text" class="form-control"
                     @change="setItem(claim.car_id, 'vin', claim.car.vin, 'cars')"
                     v-model="claim.car.vin" placeholder="VIN">
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2">
              <label>Year:&nbsp;</label>
            </div>
            <div class="col-sm-10">
              <input type="text" class="form-control"
                     @change="setItem(claim.car_id, 'year', claim.car.year, 'cars')"
                     v-model="claim.car.year" placeholder="Year">
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2">
              <label>Mileage:&nbsp;</label>
            </div>
            <div class="col-sm-10">
              <input type="text" class="form-control"
                     @change="setItem(claim.car_id, 'mileage', claim.car.mileage, 'cars')"
                     v-model="claim.car.mileage" placeholder="Mileage">
            </div>
          </div>

        </div>
        <!-- End Car Information -->

        <!-- Begin Customer Information -->
        <div class="col-sm-3">
          <div class="row">
            <div class="col-md-3">
              <label>First Name:&nbsp;</label>
            </div>
            <div class="col-md-9">
              <input type="text" class="form-control"
                     @change="setItem(claim.customer_id, 'first', claim.customer.first, 'customers')"
                     v-model="claim.customer.first" placeholder="First Name">
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <label>Last Name:&nbsp;</label>
            </div>
            <div class="col-md-9">
              <input type="text" class="form-control"
                     @change="setItem(claim.customer_id, 'last', claim.customer.last, 'customers')"
                     v-model="claim.customer.last" placeholder="Last Name">
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <label>Address1:&nbsp;</label>
            </div>
            <div class="col-md-9">
              <input type="text" class="form-control"
                     @change="setItem(claim.customer_id, 'address1', claim.customer.address1, 'customers')"
                     v-model="claim.customer.address1" placeholder="Address1">
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <label>Address2:&nbsp;</label>
            </div>
            <div class="col-md-9">
              <input type="text" class="form-control"
                     @change="setItem(claim.customer_id, 'address2', claim.customer.address2, 'customers')"
                     v-model="claim.customer.address2" placeholder="Address2">
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <label>City:&nbsp;</label>
            </div>
            <div class="col-md-9">
              <input type="text" class="form-control"
                     @change="setItem(claim.customer_id, 'city', claim.customer.city, 'customers')"
                     v-model="claim.customer.city" placeholder="City">
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <label>State:&nbsp;</label>
            </div>
            <div class="col-md-9">
              <input type="text" class="form-control"
                     @change="setItem(claim.customer_id, 'state', claim.customer.state, 'customers')"
                     v-model="claim.customer.state" placeholder="State">
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <label>Zip:&nbsp;</label>
            </div>
            <div class="col-md-9">
              <input type="text" class="form-control"
                     @change="setItem(claim.customer_id, 'city', claim.customer.zip, 'customers')"
                     v-model="claim.customer.zip" placeholder="Zip">
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <label>Phone Number:&nbsp;</label>
            </div>
            <div class="col-md-9">
              <input type="text" class="form-control"
                     @change="setItem(claim.customer_id, 'contact_number', claim.customer.contact_number, 'customers')"
                     v-model="claim.customer.contact_number"
                     placeholder="Phone Number">
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <label>Email:&nbsp;</label>
            </div>
            <div class="col-md-9">
              <input type="text" class="form-control"
                     @change="setItem(claim.customer_id, 'email', claim.customer.email, 'customers')"
                     v-model="claim.customer.email"
                     placeholder="Email">
            </div>
          </div>
        </div>
        <!-- End Customer Information -->

        <div class="col-sm-2">
          <div class="row">
            <div class="col-sm-12 text-center">
              <label>Delete Claim:&nbsp;</label>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-sm-12 text-center">
              <button v-on:click="openBasicModal(claim.id, claim.scs_claim_number, 'basic')" class="btn btn-danger">-</button>
              <el-dialog
                center
                title="Confirm Deletion of Claim"
                :visible.sync="modals.basic">
                <span>Are you sure you want to delete the claim with id: {{ modal_claim_number }}?</span>
                <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal('basic')">Cancel</button>&nbsp;
              <button class="btn btn-success" @click="deleteRow(claim_id_to_delete)">Confirm</button>
            </span>
              </el-dialog>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-center">
              <label>Change Directory:</label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-center">
              <input type="text" class="form-control"
                     v-model="claim.directory" placeholder="Directory">
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-sm-12 text-center">
              <button class="btn btn-primary"
                      v-on:click="changeDirectory(claim.id, claim.directory)">Change Directory
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-center">
              <label>Resend Customer Email:&nbsp;</label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-center">
              <button class="btn btn-primary"
                      v-on:click="emailCustomer(claim.id, claim)">Email Customer
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-center">
              <label>Modify Customer Files:&nbsp;</label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-center">
              <button class="btn btn-primary"
                      v-on:click="modifyFiles(claim.id)">Manage Files
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-center">
              <label>Push to PCRS:&nbsp;</label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-center">
              <button class="btn btn-primary"
                      v-on:click="pushToPCRS(claim.id, claim)">Push to PCRS
              </button>
            </div>
          </div>
        </div>
      </div>
    </card>
    <el-dialog
      center
      width="80%"
      height="80%"
      title="File Manager"
      :visible.sync="modals.files">
      <card v-if="spinning">
        <b-row class="text-center">
          <b-col>
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </b-col>
        </b-row>
      </card>
      <div class="card">
        <div class="row">
          <div class="col-md-12 text-center">
            <span style="font-size: 2em; font-weight: bold;">
              File List
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="row" v-for="file in fileList" v-bind:key="file">
              <div class="col-sm-2">
                <input type="checkbox" :value="file" v-model="selectedFiles">
              </div>
              <div class="col-sm-8">
                <a class="btn-download" @click.prevent="getFile(file)">{{ file }}</a>
              </div>
              <div class="col-sm-2 text-center">
                <button class="btn btn-danger" @click="deleteFile(file)">-</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-sm-4">
          </div>
          <div class="col-sm-4 text-center">
            <fg-input label="subject" placeholder="Subject" v-model="subject"></fg-input>
          </div>
          <div class="col-sm-4"></div>
        </div>
        <div class="row text-center">
          <div class="col-sm-4">
          </div>
          <div class="col-sm-4 text-center">
            <textarea cols="10" rows="10" class="form-control" v-model="message"></textarea>
          </div>
          <div class="col-sm-4"></div>
        </div>
        <div class="row text-center">
          <div class="col-sm-4">
          </div>
          <div class="col-sm-4 text-center">
            <fg-input label="email" placeholder="Email" v-model="email"></fg-input>
          </div>
          <div class="col-sm-4"></div>
        </div>
        <div class="row">
          <div class="col-sm-4"></div>
          <div class="col-sm-4 text-center">
            <button class="btn btn-primary" @click="emailFiles">
              Email Files
            </button>
          </div>
          <div class="col-sm-4"></div>
        </div>
      </div>
      <div class="card">
        <div class="row">
          <div class="col-md-12 text-center">
            <span style="font-size: 2em; font-weight: bold;">
              File Upload
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <input type="file" ref="uploadfiles" name="uploadfiles" multiple/> <br>
            <button type="button" @click='uploadFiles()'>Upload files</button>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('files')">Close</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{success_text}}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';
import axios from 'axios';
import moment from 'moment';
import Datepicker from 'vue-moment-datepicker';
import ClaimsAPIService from 'src/servicehandlers/ClaimsHandler';
import CarAPIService from 'src/servicehandlers/CarsHandler';
import CustomerAPIService from 'src/servicehandlers/CustomersHandler';
import { FileAPIService } from 'src/servicehandlers/FileHandler';

const claimAPIService = new ClaimsAPIService();
const carAPIService = new CarAPIService();
const customerAPIService = new CustomerAPIService();
const fileAPI = new FileAPIService();
export default {
  name: "ClaimSearch",
  data() {
    return {
      fileList: [],
      selectedFiles: [],
      spinning: false,
      searchItems: {},
      claims: [],
      modal_claim_number: null,
      claim_id_to_delete: null,
      error_txt: null,
      modals: {
        basic: false,
        error: false,
        success: false,
        files: false,
      },
      page: 1,
      success_text: null,
      email: null,
      subject: null,
      message: null,
      sortBy: null, // for the b-table
      sortDesc: false, // for the b-table
      filter: null, // for the b-table
      totalClaims: 0,
      checked: false,
      count: 0,
      currentPage: 1,
      perPage: 20,
      fields: [
        { key: 'id', label: 'Claim', sortable: false, width: 150 },
        { key: 'car_id', label: 'Car', sortable: false },
        { key: 'customer_id', label: 'Customer', sortable: false },
        { key: 'delete', label: 'Delete', sortable: false },
      ],
    }
  },
  components: {
    Datepicker,
    [Dialog.name]: Dialog,
  },
  watch: {
    currentPage() {
      this.claims = [];
      this.searchAll();
    },
  },
  methods: {
    customFormatter(date) {
      return moment(date).utc().format('MM/DD/YYYY');
    },
    emailCustomer(id, row) {
      // loop through each item depending on what type it is
      if (id) {
        claimAPIService.resendEmail(row, this.$router)
        .then(() => {
          this.success_text = 'Successfully sent email';
          this.openModal('success');
        })
        .catch((error) => {
          this.error_txt = 'Error updating item in DB: ' + error;
          this.openModal('error');
        });
      }
    },

    deleteRow(id) {
      // loop through each item depending on what type it is
      if (id) {
        claimAPIService.destroyClaim(id, this.$router)
        .then(() => {
          this.modal_claim_number = null;
          this.closeModal('basic');
          this.searchAll();
        })
        .catch((error) => {
          this.error_txt = 'Error deleting row: ' + error;
          this.closeModal('basic');
          this.openModal('error');
        });
      }
    },

    changeDirectory(id, directory) {
      this.spinning = true;
      const parms = {
        item: 'directory',
        value: directory,
      };
      return claimAPIService.getClaim(id, this.$router)
      .then((currentClaim) => {
        const oldDir = currentClaim.directory;
        return claimAPIService.renameDir(oldDir, directory, this.$router);
      })
      .then(() => {
        // rename the directory
        return claimAPIService.updateClaim(id, parms, this.$router);
      })
      .then(() => {
        this.getClaims();
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error updating directory: ' + error;
        this.openModal('error');
      });
    },

    getArrayVal(arr, id, column) {
      let rv = '';
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i].id === id) {
          const item = arr[i];
          rv = item[column];
          break;
        }
      }
      return rv;
    },

    searchAll() {
      this.spinning = true;
      const claimSort = {
        page: this.currentPage,
        perPage: this.perPage,
      };
      claimSort.searchItems = this.searchItems;
      return claimAPIService.searchClaims(claimSort, this.$router)
      .then((response) => {
        this.claims = response.data.claims;
        this.count = response.data.count;
        this.totalClaims = response.data.count;
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Error searching claims: ' + error;
        this.openModal('error');
        this.spinning = false;
      });

    },

    getClaims() {
      this.spinning = true;
      const claimSort = {
        page: this.currentPage,
        perPage: this.perPage,
      };

      claimSort.processed = this.processed;
      claimSort.ready = this.ready;
      claimSort.waiting = this.waiting;
      return claimAPIService.getAllClaims(claimSort, this.$router)
      .then((response) => {
        this.claims = response.data.claims;
        this.count = response.data.count;
        this.totalClaims = response.data.count;
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Error getting claims: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    getFiles() {
      this.spinning = true;
      // get a list of files to display
      return claimAPIService.getFileList(this.selectedClaimId, this.$router)
      .then((response) => {
        this.fileList = response;
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Unable to retrieve file list: ' + error;
        this.openModal('error');
      });
    },
    deleteFile(filename) {
      // get a list of files to display
      return fileAPI.deleteFile(this.selectedClaimId, filename, this.$router)
      .then((response) => {
        this.getFiles();
        this.success_text = 'Successfully deleted file';
        this.openModal('success');
      })
      .catch((error) => {
        this.error_txt = 'Unable to delete file: ' + error;
        this.openModal('error');
      });
    },

    getFile(filename) {
      const api_url = process.env.VUE_APP_API_URL;
      const url = `${api_url}/api/claims/${this.selectedClaimId}/${filename}/getfile`;
      axios.get(url, {
        responseType: 'blob'
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        this.error_txt = 'Failed to download file: ' + error;
        this.openModal('error');
      });
    },
    emailFiles() {
      const parms = {
        id: this.selectedClaimId,
        email: this.email,
        subject: this.subject,
        message: this.message,
        files: this.selectedFiles
      };
      return claimAPIService.emailFiles(parms, this.$router)
      .then((response) => {
        this.success_text = 'Successfully emailed: ' + this.email;
        this.openModal('success');
        this.email = null;
        this.subject = null;
        this.message = null;
        this.selectedFiles = [];
      })
      .catch((error) => {
        this.error_txt = 'Error sending out email: ' + error;
        this.openModal('error');
      });
    },

    modifyFiles(id) {
      this.selectedClaimId = id;
      // get a list of files to display
      return claimAPIService.getFileList(id, this.$router)
      .then((response) => {
        this.fileList = response;
        this.openModal('files');
      })
      .catch((error) => {
        this.error_txt = 'Unable to retrieve file list: ' + error;
        this.openModal('error');
      });
    },

    uploadFiles() {
      this.spinning = true;
      let formData = new FormData();
      for (const fle of this.$refs.uploadfiles.files) {
        formData.append('uploadfiles', fle);
      }
      return fileAPI.uploadClaimsFile(this.selectedClaimId, formData, this.$router)
      .then(() => {
        this.spinning = false;
        this.getFiles();
      })
      .catch(function (error) {
        this.spinning = false;
        this.error_txt = 'Error uploading files: ' + error;
        this.openModal('error');
      });
    },

    pushToPCRS(id, claim) {
      this.spinning = true;
      return claimAPIService.uploadPCMIClaim(claim, this.$router)
      .then((response) => {
        this.success_text = response;
        this.openModal('success');
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error creating claim in PCRS: ' + error.response.data;
        this.openModal('error');
      });
    },

    uploadPCMIClaim(row) {
      claimAPIService.uploadPCMIClaim(item)
      .then((response) => {

      })
      .catch((error) => {
        this.error_txt = 'Error uploading to PCMI: ' + error;
        this.openModal('error');
      });
    },
    clearSearch() {
      this.searchItems = {};
      this.claims = [];
      this.totalClaims = 0;
      this.currentPage = 1;
    },
    openBasicModal(claim_id, scs_id, name) {
      this.modal_claim_number = scs_id;
      this.claim_id_to_delete = claim_id;
      this.openModal(name);
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
    setItem(id, item, itemValue, table = 'claims') {
      this.spinning = true;
      if (item === 'ready' || item === 'processed' || item === 'waiting') {
        if (itemValue === false) {
          itemValue = 1;
        } else {
          itemValue = 0;
        }
      }
      const parms = {
        item,
        value: itemValue,
      };
      if (table === 'claims') {
        claimAPIService.updateClaim(id, parms, this.$router)
        .then(() => {
          this.spinning = false;
        })
        .catch((error) => {
          this.spinning = false;
          this.error_txt = 'Error updating item in DB: ' + error;
          this.openModal('error');
        });
      } else if (table === 'cars') {
        carAPIService.update(id, parms, this.$router)
        .then(() => {
          this.spinning = false;
        })
        .catch((error) => {
          this.error_txt = 'Error updating item in DB: ' + error;
          this.openModal('error');
        });
      } else if (table === 'customers') {
        customerAPIService.update(id, parms, this.$router)
        .then(() => {
          this.spinning = false;
        })
        .catch((error) => {
          this.spinning = false;
          this.error_txt = 'Error updating item in DB: ' + error;
          this.openModal('error');
        });
      }
    },
  }
}
</script>

<style>
.packetclass:nth-child(even) {
  background-color: lightgray;
}
</style>
