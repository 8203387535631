<template>
  <div>
    <b-container v-if="spinning">
      <b-row>
        <b-col><b-spinner variant="primary" label="Spinning"></b-spinner></b-col>
      </b-row>
    </b-container>
    <b-card>
      <b-tabs>
        <b-tab
          title="New" active>
          <claims title="New" :waiting="false" :ready="false" :processed="false"></claims>
        </b-tab>
        <b-tab
            title="Waiting">
          <claims title="Waiting" :waiting="true" :ready="false" :processed="false"></claims>
        </b-tab>
        <b-tab
            title="Ready">
          <claims title="Ready" :waiting="false" :ready="true" :processed="false"></claims>
        </b-tab>
        <b-tab
            title="Processed">
          <claims title="processed" :waiting="false" :ready="true" :processed="true"></claims>
        </b-tab>
        <b-tab
          title="Search">
          <claim-search></claim-search>
        </b-tab>
        <b-tab
            title="Import">
          <button class="btn btn-primary" @click="importer()">Click here to prepare for import</button>
        </b-tab>
      </b-tabs>
    </b-card>
    <div id="status">{{ importstatus }}</div>
  </div>
</template>

<script>
import ClaimSearch from 'src/pages/Dashboard/Pages/Claims/ClaimSearch';
import Claims from 'src/pages/Dashboard/Pages/Claims/Claims';
import { ClaimsAPIService } from 'src/servicehandlers/ClaimsHandler';

const claimsAPIService = new ClaimsAPIService();

export default {
  name: 'ClaimsMain',
  components: {
    Claims,
    ClaimSearch,
  },
  data() {
    return {
      claims: [],
      spinning: false,
      importstatus: 'Nothing Imported',
    };
  },
  methods: {
    importer() {
      return claimsAPIService.uploadClaims(this.$router)
      .then((rval) => {
        if (rval.rv === 0) {
          this.importstatus = 'SUCCESS';
        } else {
          this.importstatus = 'ERROR!';
        }
      })
      .catch((error) => {
        this.importstatus = 'Failed to import: ' + error;
      });
    },
  },
};
</script>

<style scoped>

</style>
