<template>
<div>
  <card v-if="spinning">
    <b-row class="text-center">
      <b-col>
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </b-col>
    </b-row>
  </card>
  <chart-card
    ref="chart"
    :chart-data="barChart.data"
    :chart-options="barChart.options"
    :chart-responsive-options="barChart.responsiveOptions"
    chart-type="Bar">
    <template slot="header">
      <h4 class="card-title">{{ year }} Contract Count</h4>
      <p class="card-category">All contracts for year {{ year }}</p>
    </template>
  </chart-card>
  <chart-card
    ref="chart2"
    :chart-data="barChart2.data"
    :chart-options="barChart2.options"
    :chart-responsive-options="barChart2.responsiveOptions"
    chart-type="Bar">
    <template slot="header">
      <h4 class="card-title">{{ last_year }} Contract Count</h4>
      <p class="card-category">All contracts for year {{ last_year }}</p>
    </template>
  </chart-card>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <span>{{success_txt}}</span>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
  </el-dialog>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import {ChartCard} from 'src/components/index'
import moment from 'moment';
import ReportsAPIHandler from "../../../../../servicehandlers/ReportsAPIHandler";
const reportsAPIHandler = new ReportsAPIHandler();
import { Dialog } from 'element-ui';

export default {
  components: {
    ChartCard,
    [Dialog.name]: Dialog,
  },
  name: "PCMIDailyCharts",
  data() {
    return {
      spinning: false,
      year: null,
      last_year: null,
      barChart: {
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          series: [0,0,0,0,0,0,0,0,0,0,0,0]
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false
          },
          // height: '245px'
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc (value) {
                return value[0]
              }
            }
          }]
        ]
      },
      barChart2: {
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          series: [0,0,0,0,0,0,0,0,0,0,0,0]
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false
          },
          // height: '245px'
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc (value) {
                return value[0]
              }
            }
          }]
        ]
      },
      is_ran: false,
      totalCount: 0,
      start_date: null,
      end_date: null,
      success_txt: null,
      error_txt: null,
      modals: {
        basic: false,
        error: false,
        success: false,
        files: false,
      },
    }
  },
  mounted() {
    this.year = new Date().getFullYear();
    this.last_year = this.year - 1;
    this.getMonthlyCount();
  },
  methods: {
    getMonthlyCount: function () {
      return reportsAPIHandler.getMonthlyCounts(this.year.toString(), this.$route)
      .then(async (counts) => {
        this.barChart.data.series = [];
        let reportDataItems = [];
        for (const count of counts) {
          reportDataItems.push(count['Count']);
        }
        if (reportDataItems.length <= 0) {
          reportDataItems = [0,0,0,0,0,0,0,0,0,0,0,0];
        }
        this.barChart.data.series.push(reportDataItems);
        // this.$refs.chart.series.push(reportDataItems);
        this.$refs.chart.updateChart();
        return reportsAPIHandler.getMonthlyCounts(this.last_year.toString(), this.$route);
      })
      .then((newCounts) => {
        this.barChart2.data.series = [];
        let reportDataItems = [];
        for (const count of newCounts) {
          reportDataItems.push(count['Count']);
        }
        if (reportDataItems.length <= 0) {
          reportDataItems = [0,0,0,0,0,0,0,0,0,0,0,0];
        }
        this.barChart2.data.series.push(reportDataItems);
        this.$refs.chart2.updateChart();
      })
      .catch((error) => {
        this.error_txt = error;
        this.openModal('error');
      });
    },
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>

</style>
