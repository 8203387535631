var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-layout',{attrs:{"pageClass":"login-page"}},[_c('div',{staticClass:"row d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-8"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('fade-render-transition',[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h3',{staticClass:"card-title text-center"},[_vm._v("Login")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Email or user field is required': null,"hasSuccess":passed,"label":"Email or User ID","name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'The Password field is required': null,"hasSuccess":passed,"name":"password","label":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-fill btn-info btn-round btn-wd ",attrs:{"type":"submit"}},[_vm._v("Login")]),_c('br'),_c('div',{staticClass:"forgot"},[_c('router-link',{staticClass:"card-category",attrs:{"to":"/register"}},[_vm._v(" Forgot your password? ")])],1)])])],1)],1)]}}])})],1)]),_c('el-dialog',{attrs:{"center":"","title":"Error","visible":_vm.modals.error},on:{"update:visible":function($event){return _vm.$set(_vm.modals, "error", $event)}}},[_c('span',[_c('h5',[_vm._v("Error Occurred")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"})]),_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-md-12"},[_c('label',[_vm._v(_vm._s(_vm.error_text))])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.closeModal('error')}}},[_vm._v("Error")]),_vm._v("  ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }