<template>
  <div>
    <card>
      <div class="row">
        <div class="col-sm-12 text-center"><span style="font-size: 2em; font-weight: bold;">Get Products Sold</span></div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4">
          <label>Please Select a search date:</label>
          {{ dates }}
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui'
import moment from 'moment';

export default {
  name: "ContractsSold",
  data() {
    return {
      dates: [],
      contractsSold: {},
      error_txt: null,
      modals: {
        basic: false,
        error: false,
      },
    }
  },
  components: {
    [Dialog.name]: Dialog,
  },
  created() {
    this.createSelectDates();
  },
  methods: {
    createSelectDates() {
      const currentMonth = moment().format('YYYYMM01');
      this.dates.push(currentMonth);
      for (let i = 0; i <= 30; i += 1) {
        const month = moment().subtract(i, 'months').format('YYYYMM01');
        this.dates.push(month);
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  },
}
</script>

<style scoped>

</style>
