<template>
  <div>
    <card>
      <b-row>
        <b-col md="6">
          <b-modal ref="my-modal" hide-footer title="Upload Window">
            <div class="d-block text-center">
              <h3>{{ successOrError }}</h3>
              <h3>{{ successOrErrorMessage }}</h3>
            </div>
            <b-button class="mt-3" variant="outline-danger" block @click="hideModal">OK</b-button>
          </b-modal>
        </b-col>
      </b-row>
    </card>
    <card>
      <div class="row">
        <div class="col-md-2 text-center">
          <img class="img-responsive" width="100%" height="100%" src="@/assets/xzilon_logo.png" alt="Xzilon Logo"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <span style="font-size: 3em; font-weight: bold;">Xzilon Claim Upload Page</span>
        </div>
      </div>
    </card>
    <card>
      <b-row>
        <b-col class="text-center">
          <input type="file" ref="vinpics" name="vinpics" multiple/> <br>
          <button type="button"
                  class="btn btn-primary"
                  @click='uploadVIN()'>Upload VIN Picture
          </button>
        </b-col>
      </b-row>
      <b-row class="pt-4 text-center">
        <b-col>
          <input type="file" ref="claimfiles" name="claimfiles" multiple/> <br>
          <button type="button"
                  class="btn btn-primary"
                  @click='uploadFile()'>Upload Car Damage Pictures
          </button>
        </b-col>
      </b-row>
    </card>
    <card>
      <b-row>
        <b-col class="text-center">
          <div v-html="vinmsg"></div>
        </b-col>
      </b-row>
    </card>
    <card>
      <b-row>
        <b-col class="text-center">
          <div v-html="msg"></div>
        </b-col>
      </b-row>
    </card>
  </div>
</template>

<script>
import { FileAPIService } from 'src/servicehandlers/FileHandler';
const fileAPI = new FileAPIService();

export default {
  name: 'ClaimUpload',
  data() {
    return {
      spinning: false,
      successOrError: '',
      successOrErrorMessage: '',
      params: {},
      files: {},
      msg: 'No damage pics uploaded yet',
      vinmsg: 'No VIN pics uploaded yet',
    };
  },
  created() {
    this.params = this.$route.query.claim;
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    uploadVIN: function() {
      this.files = this.$refs.vinpics.files[0];
      let formData = new FormData();
      for (const fle of this.$refs.vinpics.files) {
        formData.append('claimfiles', fle);
      }
      formData.append('claim', this.params);
      formData.append('pictype', 'VIN');
      return fileAPI.uploadFile(formData, this.$router)
      .then((response) => {
        if (!response) {
          this.vinmsg = '<span style="color: red">File Uploading failed</span>';
          this.successOrError = 'Error';
          this.successOrErrorMessage = 'An unexpected error has occurred!';
          this.$refs['my-modal'].show();
        } else {
          if (response && response.rv !== 0) {
            this.vinmsg = '<span style="color: red">' + response.message + '</span>';
            this.successOrError = 'Error';
            this.successOrErrorMessage = 'An unexpected error has occurred: ' + response.message;
            this.$refs['my-modal'].show();
          } else if (response && response.rv === 0) {
            this.vinmsg = '<span style="color: green">VIN: ' + response.message + '</span>';
            this.successOrError = 'Success';
            this.successOrErrorMessage = 'VIN picture Successful file upload';
            this.$refs['my-modal'].show();
          } else {
            this.vinmsg = '<span style="color: red">Upload file failure</span>';
            this.successOrError = 'Error';
            this.successOrErrorMessage = 'Upload File Failure';
            this.$refs['my-modal'].show();
          }
        }
      })
      .catch((error) => {
        this.vinmsg = '<span style="color: red">' + error + '</span>';
        this.successOrError = 'Error';
        this.successOrErrorMessage = 'Upload File Failure: ' + error;
        this.$refs['my-modal'].show();
      });
    },

    uploadFile: function () {
      this.files = this.$refs.claimfiles.files[0];
      let formData = new FormData();
      for (const fle of this.$refs.claimfiles.files) {
        formData.append('claimfiles', fle);
      }
      formData.append('claim', this.params);
      formData.append('pictype', 'damage');
      fileAPI.uploadFile(formData, this.$router)
      .then((response) => {
        if (!response) {
          this.msg = '<span style="color: red">File Uploading failed</span>';
        } else {
          if (response && response.rv !== 0) {
            this.successOrError = 'Error';
            this.successOrErrorMessage = 'An unexpected error has occurred: ' + response.message;
            this.msg = '<span style="color: red">Damage: ' + response.message + '</span>';
            this.$refs['my-modal'].show();
          } else if (response && response.rv === 0) {
            this.msg = '<span style="color: green">' + response.message + '</span>';
            this.successOrError = 'Success';
            this.successOrErrorMessage = 'File upload successful!';
            this.$refs['my-modal'].show();
          } else {
            this.msg = '<span style="color: red">Upload file failure</span>';
            this.successOrError = 'Error';
            this.successOrErrorMessage = 'Upload File Failure';
            this.$refs['my-modal'].show();
          }
        }
      })
      .catch((error) => {
        // console.log('ERROR: ', error);
        this.msg = '<span style="color: red">' + error + '</span>';
        this.successOrError = 'Error';
        this.successOrErrorMessage = 'File upload error!: ' + error;
        this.$refs['my-modal'].show();
      });
    },
  }
};
</script>

<style>
</style>
