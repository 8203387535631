import { render, staticRenderFns } from "./ClaimsMain.vue?vue&type=template&id=69b5b1a5&scoped=true&"
import script from "./ClaimsMain.vue?vue&type=script&lang=js&"
export * from "./ClaimsMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69b5b1a5",
  null
  
)

export default component.exports