import { CommonAPIService } from '../CommonHandler';

export class AgentAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getAgentById(router, id) {
    const url = `/api/agent/${id}`;
    return this.CommonAPIService.getCall(url, id, router);
  }

  getAgents(router) {
    const url = '/api/agent';
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default AgentAPIService;
