<template>
  <div>
    <card v-if="spinning">
      <b-row class="text-center">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4 text-center">
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="Start Date"
            v-model="start_date">
          </datepicker>
        </div>
        <div class="col-sm-4 text-center">
          <datepicker
            class="editable editable-click"
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="End Date"
            v-model="end_date">
          </datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8 text-center">
          <button class="btn btn-primary" @click="getContractData">Get Sales Data</button>
        </div>
      </div>
    </card>
    <card v-if="is_ran">
      <div class="row">
        <div class="col-sm-8 text-center">
          <a v-if="downloadLink" :download="shortName" :href="downloadLink">Click here to get the
            report</a>
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{ success_txt }}</span>
      <span slot="footer" class="dialog-footer">
          <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
      </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vue-moment-datepicker';
import ReportsAPIHandler from "../../../../../servicehandlers/ReportsAPIHandler";
import { Dialog } from 'element-ui';

const reportsAPIHandler = new ReportsAPIHandler();

export default {
  components: {
    Datepicker,
    [Dialog.name]: Dialog,
  },
  name: "SalesByDate",
  data() {
    return {
      downloadLink: null,
      shortName: null,
      spinning: false,
      timerId: null,
      reportData: [],
      is_ran: false,
      totalCount: 0,
      start_date: null,
      end_date: null,
      success_txt: null,
      error_txt: null,
      treaty_id: null,
      carrier: 'ALL',
      modals: {
        basic: false,
        error: false,
        success: false,
        files: false,
      },
    }
  },
  methods: {
    getContractData() {
      this.downloadLink = null;
      this.shortName = null;
      if (!this.start_date) {
        this.error_txt = 'No start date!';
        this.openModal('error');
        return;
      }
      if (!this.end_date) {
        this.error_txt = 'No end date!';
        this.openModal('error');
        return;
      }
      this.is_ran = false;
      this.spinning = true;
      const start_date = moment(this.start_date).format('MM-DD-YYYY 00:00:00');
      const end_date = moment(this.end_date).format('MM-DD-YYYY 23:59:59');
      return reportsAPIHandler.getSalesData(start_date, end_date, this.$route)
      .then((resp) => {
        this.spinning = false;
        this.downloadLink = resp.url;
        this.shortName = resp.short_name;
        // this.is_ran = true;

        // now setup an interval to keep waiting for it to finish because this is a MASSIVE report
        this.timerId = setInterval(this.getFileStatus, 1000)
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'error: ' + error;
        this.openModal('error');
      });
    },

    async getFileStatus() {
      this.spinning = true;
      await reportsAPIHandler.getFileStatus(this.shortName, this.$router)
      .then((resp) => {
        if (resp.success === true) {
          this.is_ran = true;
          this.downloadLink = resp.url;
          this.shortName = resp.shortName;
          clearInterval(this.timerId);
          this.spinning = false;
        }
      })
      .catch((error) => {
        this.error_txt = 'File status call failed!: ' + error;
        this.openModal('error');
        clearInterval(this.timerId);
        this.spinning = false;
      });
    },

    customFormatter(date) {
      return moment(date).utc().format('YYYY-MM-DD');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>

</style>
