import { CommonAPIService } from './CommonHandler';

export class ClaimsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  updateClaim(id, parms, router) {
    const url = '/api/claims/' + id;
    return this.CommonAPIService.putCall(url, parms, router);
  }

  destroyClaim(id, router) {
    const url = '/api/claims/' + id;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  uploadClaims(router) {
    const url = '/api/claims/all/import';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getAllClaims(parms, router) {
    const url = '/api/claims/allclaims';
    return this.CommonAPIService.postCall(url, parms, router);
  }

  getClaim(id, router) {
    const url = `/api/claims/${id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  renameDir(oldDir, newDir, router) {
    const url = `/api/claims/${oldDir}/${newDir}/rename`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  searchClaims(parms, router) {
    const url = '/api/claims/search';
    return this.CommonAPIService.postCall(url, parms, router);
  }

  resendEmail(parms, router) {
    const url = '/api/claims/resend';
    return this.CommonAPIService.postCall(url, parms, router);
  }

  uploadPCMIClaim(pcmiRow, router) {
    const url = '/api/claims/pcmi';
    return this.CommonAPIService.postCall(url, pcmiRow, router);
  }

  getDamageTypes(router) {
    const url = '/api/claims/damagetypes';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getDamageLocations(router) {
    const url = '/api/claims/damagelocations';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getSingleClaim(claim_number, router) {
    const url = `/api/claims/${claim_number}/claimstatus`;
    return this.CommonAPIService.getCall(url, this.claim, router);
  }

  emailFiles(parms, router) {
    const url = `/api/claims/emailcustomer`;
    return this.CommonAPIService.postCall(url, parms, router);
  }

  createClaim(claim, router) {
    const url = '/api/claims/newclaim';
    return this.CommonAPIService.postCall(url, claim, router);
  }

  getFileList(claim_id, router) {
    const url = `/api/claims/${claim_id}/filelist`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  getFile(id, filename, router) {
    const url = `/api/claims/${id}/${filename}/getfile`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  uploadFiles(id, parms, router) {
    const url = `/api/claims/${id}/upload`;
    return this.CommonAPIService.fileCall(url, parms, router);
  }

}

export default ClaimsAPIService;
