import { CommonAPIService } from './CommonHandler';

export class CarsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  update(id, parms, router) {
    const url = '/api/cars/' + id;
    return this.CommonAPIService.putCall(url, parms, router);
  }
}

export default CarsAPIService;
