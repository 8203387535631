import CommonAPIService from '../CommonHandler';

export class ScopesAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  createScope(scopeData, router) {
    const url = '/api/scopes';
    return this.CommonAPIService.postCall(url, scopeData, router);
  }

  deleteScope(id, router) {
    const url = `/api/scopes/${id}`;
    return this.CommonAPIService.deleteCall(url, null, router);
  }

  getScopes(router) {
    const url = '/api/scopes';
    return this.CommonAPIService.getCall(url, null, router);
  }

  getScopeById(id, router) {
    const url = `/api/scopes/${id}`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  searchScopes(search, router) {
    const url = `/api/scopes?search=${search}`;
    return this.CommonAPIService.getCall(url, null, router);
  }

  addScopesToAuthgroup(authgroup_id, scopes, router) {
    const url = `/api/scopes/${authgroup_id}/scopeadd`;
    return this.CommonAPIService.putCall(url, {scopes}, router);
  }
}

export default ScopesAPIService;
