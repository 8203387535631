<template>
  <div>
    <card v-if="spinning">
      <b-row class="text-center">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </card>
    <card>
      <div class="row">
        <div class="col-md-2 text-center">
          <img class="img-responsive" width="100%" height="100%" src="@/assets/xzilon_logo.png" alt="Xzilon Logo"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <span style="font-size: 3em; font-weight: bold;">Xzilon Claim Status Page</span>
        </div>
      </div>
    </card>
    <card v-if="success_text">
      <div class="row">
        <div class="col-sm-12 text-center">
          <span style="font-weight: bold; font-size: 2em;">Claim Status</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          {{ success_text.message }}
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{success_text}}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ClaimsAPIService } from 'src/servicehandlers/ClaimsHandler';
const claimsAPIService = new ClaimsAPIService();
import { Dialog } from 'element-ui';

export default {
  name: 'ClaimStatus',
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      spinning: false,
      successOrError: null,
      successOrErrorMessage: null,
      params: {},
      error_txt: null,
      success_text: null,
      files: {},
      msg: null,
      vinmsg: null,
      modals: {
        basic: false,
        error: false,
        success: false,
        files: false,
      },
    };
  },
  mounted() {
    this.params = this.$route.query.claim;
    this.getClaimStatus(this.$route.query.claim);
  },
  methods: {
    getClaimStatus(claim_number) {
      this.spinning = true;
      if (!claim_number) {
        this.error_txt = 'No claim number was set';
        this.openModal('error');
        this.spinning = false;
        return;
      }
      return claimsAPIService.getSingleClaim(claim_number, this.$router)
      .then((response) => {
        this.success_text = response;
        // this.openModal('success');
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Error getting claim: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
  }
};
</script>

<style>
</style>
