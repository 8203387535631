import { CommonAPIService } from './CommonHandler';

export class PlansAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getPlans(plans, router) {
    const url = '/api/plans';
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default PlansAPIService;
