<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-md-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <b-row>
        <b-col class="col-sm-2">
          <label>Select a Check Log</label>
          <datepicker
            :value="currentdate"
            @input="logdateSelected"
            :bootstrap-styling="true"
          >
            <div slot="beforeCalendarHeader" class="calender-header">
              Checklog Date
            </div>
          </datepicker>
        </b-col>
      </b-row>
      <div class="row">
        <div class="col-sm-4">
          <button @click="refresh()" class="btn btn-primary">Refresh</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <download-excel
            class="btn btn-primary"
            :data="downloadablePackages"
            :fields="json_fields"
            worksheet="Checklog"
            :before-generate="beforeGenerate"
            type="csv"
            name="Checklog.csv">

            Download Excel
          </download-excel>
        </div>
      </div>
    </card>
    <card>
      <b-row>
        <b-col>
          <span style="font-weight: bold; font-size: 2em;">Packets&nbsp;&nbsp;
            <b-btn class="btn btn-primary" @click="addPackage">+</b-btn>
          </span>
        </b-col>
      </b-row>

      <!--Headers-->
      <div class="row">
        <div class="col-sm-1 text-center">
          <span style="font-weight: bold; font-size: 1em;">Delete</span>
        </div>
        <div class="col-sm-5 text-center">
          <span style="font-weight: bold; font-size: 1em;">Dealers & Agents</span>
        </div>
        <div class="col-sm-3 text-center">
          <span style="font-weight: bold; font-size: 1em;">Packet Info</span>
        </div>
        <div class="col-sm-2 text-center">
          <span style="font-weight: bold; font-size: 1em;">Check Info</span>
        </div>
        <div class="col-sm-1 text-center">
          <span style="font-weight: bold; font-size: 1em;">Additional</span>
        </div>
      </div>
      <!--End of Headers-->

      <div class="row packetclass pt-2 pb-2" v-for="pck of packages" v-bind:key="pck.id">
        <!--Beginning of for loop-->
        <div class="col-sm-1 text-center">
          <button v-on:click="deleteRow(pck.id)" class="btn btn-danger">-</button>
        </div>


        <!--Dealers and agents row area-->
        <div class="col-sm-5 text-center">
          <div class="row">
            <div class="col-sm-2">
              <label>Dealer</label>
            </div>
            <div class="col-sm-8">
              <v-select
                :options="dealers"
                label="name"
                @input="setItem(pck.id, 'dealer_id', pck.dealer_id)"
                :searchable="true"
                :reduce="dealers => dealers.id"
                v-model="pck.dealer_id">
              </v-select>
            </div>
            <div class="col-sm-2">
              <button type="button" @click="copyDealer(pck, pck.dealer_id)">Copy</button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2">
              <label>Agent</label>
            </div>
            <div class="col-sm-8">
              <v-select
                :options="agents"
                label="name"
                @input="setItem(pck.id, 'agent_id', pck.agent_id)"
                :searchable="true"
                :reduce="agents => agents.id"
                v-model="pck.agent_id">
              </v-select>
            </div>
            <div class="col-sm-2">
              <button type="button" @click="copyAgent(pck, pck.agent_id)">Copy</button>
            </div>
          </div>
        </div>

        <!--Begin of packet info-->
        <div class="col-sm-3 text-center">
          <div class="row text-center pb-2">
            <div class="col-sm-3 text-center">
              <label>Owner</label>
            </div>
            <div class="col-sm-9 text-center">
              <v-select
                :options="users"
                @input="setItem(pck.id, 'user_id', pck.user_id)"
                label="full_name"
                :searchable="true"
                :reduce="users => users.id"
                v-model="pck.user_id">
              </v-select>
            </div>
          </div>
          <div class="row text-center pb-2">
            <div class="col-sm-3 text-center">
              <label>Status</label>
            </div>
            <div class="col-sm-9 text-center">
              <v-select
                :options="status"
                label="name"
                @input="setItem(pck.id, 'status_id', pck.status_id)"
                :searchable="true"
                :reduce="status => status.id"
                v-model="pck.status_id">
              </v-select>
            </div>
          </div>
          <div class="row text-center pb-2">
            <div class="col-sm-3 text-center">
              <label>Received Via</label>
            </div>
            <div class="col-sm-9 text-center">
              <v-select
                :options="mailcarriers"
                label="name"
                @input="setItem(pck.id, 'mailcarrier_id', pck.mailcarrier_id)"
                :searchable="true"
                :reduce="mailcarriers => mailcarriers.id"
                v-model="pck.mailcarrier_id">
              </v-select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-1">
              <label>CCnt:&nbsp;</label>
            </div>
            <div class="col-sm-3">
              <input type="number" id="numcontracts" class="form-control-sm"
                     style="width:4em"
                     min="0"
                     @change="setItem(pck.id, 'numcontracts', pck.numcontracts)"
                     v-model="pck.numcontracts"/>
            </div>
            <div class="col-sm-1">
              <label>ECnt:&nbsp;</label>
            </div>
            <div class="col-sm-3">
              <input type="number" id="numecontracts" class="form-control-sm"
                     style="width:4em"
                     @change="setItem(pck.id, 'numecontracts', pck.numecontracts)"
                     v-model="pck.numecontracts"/>
            </div>
            <div class="col-sm-1">
              <label>Rust:&nbsp;</label>
            </div>
            <div class="col-sm-3">
              <input type="number" id="numrustcontracts" class="form-control-sm"
                     style="width:4em"
                     @change="setItem(pck.id, 'numrustcontracts', pck.numrustcontracts)"
                     v-model="pck.numrustcontracts"/>
            </div>
          </div>
        </div>

        <div class="col-sm-2 text-center">
          <div class="row">
            <div class="col-sm-4 text-center">
              <label>Check Date</label>
            </div>
            <div class="col-sm-8 text-center">
              <datepicker
                :bootstrap-styling="true"
                @input="setItem(pck.id, 'checkdate', pck.checkdate)"
                placeholder="Check Date"
                v-model="pck.checkdate">
              </datepicker>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 text-center">
              <label>Posted Date</label>
            </div>
            <div class="col-sm-8 text-center">
              <datepicker
                :bootstrap-styling="true"
                placeholder="Posted Date"
                @input="setItem(pck.id, 'postedDate', pck.postedDate)"
                v-model="pck.postedDate">
              </datepicker>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 text-center">
              <label>Check Number</label>
            </div>
            <div class="col-sm-8">
              <input type="text" class="form-control"
                     min="0"
                     @change="setItem(pck.id, 'checkNum', pck.checkNum)"
                     v-model="pck.checkNum"/>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 text-center">
              <label>Check Amount</label>
            </div>
            <div class="col-sm-8">
              <input type="text" class="form-control"
                     min="0"
                     @change="setItem(pck.id, 'checkamount', pck.checkamount)"
                     v-model="pck.checkamount"/>
            </div>
          </div>
        </div>


        <!--Additional Information-->
        <div class="col-sm-1 text-center">
          <div class="row">
            <div class="col-md-12">
              <b-button variant="primary"
                        v-b-modal="'myModal' + pck.id">Show Comments
              </b-button>
              <b-modal :id="'myModal' + pck.id"
                       size="lg"
                       title="Comments"
                       @ok="setItem(pck.id,
                              'comments',
                              pck.comments)"
                       centered>
                 <textarea rows="10" cols="50"
                           name="comments"
                           placeholder="comments"
                           v-model="pck.comments">
                </textarea>
              </b-modal>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button class="btn btn-primary" @click="createDealerForm(pck)">Generate Form</button>
            </div>
          </div>
        </div>
      </div>

      <!--End of For Loop Rows-->
    </card>
    <card>
      <b-row>
        <b-col>Totals</b-col>
        <b-table
          :small="true"
          :items="totals"
          :fields="totalsFields"
        >
        </b-table>
      </b-row>
      <b-row>
        <b-col class="col-md-10">
        </b-col>
        <b-col class="col-md-2"><label>Warranty Transfers</label>
          <input type="number" class="form-control" id="warranty" :value="warrantyTransfers">
        </b-col>
      </b-row>
    </card>
    <el-dialog
      center
      :before-close="handleClose"
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import jsPDF from 'jspdf';
import { Dialog } from 'element-ui';
import { ChecklogAPIService } from 'src/servicehandlers/archived_handlers/ChecklogHandler';
import { UserAPIService } from 'src/servicehandlers/archived_handlers/UserHandler';
import { StatusAPIService } from 'src/servicehandlers/archived_handlers/StatusHandler';
import { DealerAPIService } from 'src/servicehandlers/archived_handlers/DealerHandler';
import { AgentAPIService } from 'src/servicehandlers/archived_handlers/AgentHandler';
import { MailcarrierAPIService } from 'src/servicehandlers/archived_handlers/MailcarrierHandler';

moment.prototype.toMySqlDateTime = function () {
  return this.format('YYYY-MM-DD');
};

const checklogApiService = new ChecklogAPIService();
const userApiService = new UserAPIService();
const statusApiService = new StatusAPIService();
const dealerApiService = new DealerAPIService();
const agentApiService = new AgentAPIService();
const mailcarrierAPIService = new MailcarrierAPIService();

export default {
  name: 'CheckLogMain',
  components: {
    Datepicker,
    [Dialog.name]: Dialog
  },
  data() {
    return {
      spinning: false,
      currentdate: null,
      warrantyTransfers: null,
      packages: [],
      downloadablePackages: [],
      mailcarriers: [],
      statuses: [],
      status: [],
      error_txt: '',
      dealers: [],
      agents: [],
      totals: [],
      users: [],
      modals: {
        error: false,
      },
      fields: [
        { key: 'users', label: 'Owner', sortable: false },
        { key: 'dealers', label: 'Dealer', sortable: true },
        { key: 'agent', label: 'Agent', sortable: true },
        { key: 'mailcarrier', label: 'Received Via', sortable: true },
        { key: 'checkNum', label: 'Check Num', sortable: true },
        { key: 'dates', label: 'Checks', sortable: true },
        { key: 'checkamount', label: 'Check Amnt', sortable: true },
        { key: 'nums', label: 'Counts', sortable: true },
        { key: 'comments', label: 'Comments', sortable: true },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'generate', label: 'Form', sortable: false },
        { key: 'delete', label: 'Delete', sortable: false },
      ],
      totalsFields: [
        { key: 'totalamount', label: 'Total Check Amount', sortable: true },
        { key: 'totalcontracts', label: 'Total Contracts', sortable: true },
        { key: 'totalecontracts', label: 'Total EContracts', sortable: true },
        { key: 'totalrustcontracts', label: 'Total Rust', sortable: true },
      ],
      json_fields: {
        Owner: 'users',
        Dealer: 'dealers',
        Agent: 'agent',
        'Received Via': 'mailcarrier',
        'Check Num': 'checkNum',
        'Check Amnt': 'checkamount',
        'Check Date': 'checkdate',
        'Posted Date': 'postedDate',
        Contracts: 'numcontracts',
        Econtracts: 'numecontracts',
        'Rust Contracts': 'numrustcontracts',
        Comments: 'comments',
        Status: 'status',
      },
    };
  },
  methods: {
    createDealerForm(rowItem) {
      const dealer = this.getArrayVal(this.dealers, rowItem.dealer_id, 'dealer');
      const dealerNum = this.getArrayVal(this.dealers, rowItem.dealer_id, 'dealer_id');
      const agent = this.getArrayVal(this.agents, rowItem.agent_id, 'agent');
      const pdfName = 'xzilon';
      const doc = new jsPDF();
      const fontSize = 14;
      const x1 = 30;
      let y1 = 10;
      const x2 = 180;
      let y2 = 10;
      const lineAdd = 3;
      doc.setFontSize(fontSize);

      // beginning area
      doc.line(x1, y1, x2, y2);
      y1 += 20;
      // const imgData = 'data:image/png;base64,'+ base.encode('../assets/xzilon_logo.png');
      // doc.addImage(imgData, 'JPEG', 15, 40, 180, 160);
      doc.text('XZILON', 90, y1);
      y1 += 10;
      doc.text('DEALER PACKET INFORMATION', 65, y1);
      y1 += 10;
      y2 = y1;
      doc.line(30, y1, 180, y2);
      y1 += 10;

      doc.text('DEALER NAME: ', 10, y1);
      doc.setFontSize(11);
      doc.text(dealer, 50, y1);
      doc.setFontSize(14);
      y1 += 3;
      y2 = y1;
      doc.line(50, y1 + lineAdd, 120, y2 + lineAdd);

      y1 += 9;
      y2 = y1;
      doc.text('AGENT: ', 10, y1);
      doc.setFontSize(11);
      doc.text(agent, 50, y1);
      doc.setFontSize(14);
      doc.line(50, y1 + lineAdd, 120, y2 + lineAdd);

      y1 += 9;
      y2 = y1;
      doc.text('DEALER NUMBER: ', 10, y1);
      doc.setFontSize(11);
      doc.text(dealerNum, 57, y1);
      doc.setFontSize(14);
      doc.line(57, y1 + lineAdd, 120, y2 + lineAdd);

      y1 += 9;
      y2 = y1;
      doc.text('DATA ENTRY COUNT: ', 10, y1);
      doc.setFontSize(11);
      doc.text(rowItem.numcontracts.toString(), 65, y1);
      doc.setFontSize(14);
      doc.line(65, y1 + lineAdd, 70, y2 + lineAdd);
      doc.text('ECONTRACTING COUNT: ', 75, y1);
      doc.setFontSize(11);
      doc.text(rowItem.numecontracts.toString(), 138, y1);
      doc.setFontSize(14);
      doc.line(138, y1 + lineAdd, 143, y2 + lineAdd);
      doc.text('RUST COUNT: ', 148, y1);
      doc.setFontSize(11);
      doc.text(rowItem.numrustcontracts.toString(), 184, y1);
      doc.setFontSize(14);
      doc.line(184, y1 + lineAdd, 189, y2 + lineAdd);
      y1 += 9;
      y2 = y1;
      doc.text('DATA ENTRY NOTES: ', 10, y1);
      doc.setFontSize(11);
      doc.text(rowItem.comments, 65, y1);
      doc.setFontSize(14);
      y1 += 5;
      y2 = y1;
      doc.line(65, y1, 200, y2);
      y1 += 5;
      y2 = y1;
      doc.line(65, y1, 200, y2);
      y1 += 5;
      y2 = y1;
      doc.line(65, y1, 200, y2);

      y1 += 5;
      y2 = y1;
      doc.line(30, y1, 180, y2);

      y1 += 5;
      y2 = y1;
      doc.text('PAYMENT INFORMATION (IF NO CHECK STUB ATTACHED): ', 35, y1);
      doc.line(30, y1 + lineAdd, 180, y2 + lineAdd);

      y1 += 9;
      y2 = y1;
      doc.text('CHECK DATE: ', 10, y1);
      doc.setFontSize(11);
      doc.text(moment(
        rowItem.checkdate).utc().format('YYYY-MM-DD').toString(), 45, y1);
      doc.setFontSize(14);
      doc.line(45, y1 + lineAdd, 200, y2 + lineAdd);

      y1 += 9;
      y2 = y1;
      doc.text('CHECK NUMBER: ', 10, y1);
      doc.setFontSize(11);
      doc.text(rowItem.checkNum.toString(), 54, y1);
      doc.setFontSize(14);
      doc.line(54, y1 + lineAdd, 200, y2 + lineAdd);

      y1 += 9;
      y2 = y1;
      doc.text('CHECK AMOUNT: ', 10, y1);
      doc.setFontSize(11);
      doc.text(rowItem.checkamount.toString(), 54, y1);
      doc.setFontSize(14);
      doc.line(54, y1 + lineAdd, 200, y1 + lineAdd);

      y1 += 9;
      y2 = y1;
      doc.text('PAYMENT NOTES: ', 10, y1);
      y1 += 5;
      y2 = y1;
      doc.line(55, y1, 200, y2);
      y1 += 5;
      y2 = y1;
      doc.line(55, y1, 200, y2);
      y1 += 5;
      y2 = y1;
      doc.line(55, y1, 200, y2);

      doc.save(`${pdfName}.pdf`);
    },
    getArrayVal(arr, id, column) {
      let rv = '';
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i].id === id) {
          const item = arr[i];
          rv = item[column];
          break;
        }
      }
      return rv;
    },
    copyDealer(row, id) {
      const dealer = this.getArrayVal(this.dealers, id, 'name');
      this.$copyText(dealer).then(() => {
      }, (e) => {
        // console.log(e);
      });
    },
    setItem(id, item, itemValue) {
      let value = itemValue;
      if (item === 'checkdate' || item === 'postedDate') {
        value = moment(itemValue).format('YYYY-MM-DD');
      }
      if (item === 'dealer_id') {
        // find the package ID and get the agent ID that we're supposed to have
        let agent_id = null;
        for (let i = 0; i < this.dealers.length; i += 1) {
          // find the dealer's agent, set it in the package
          if (itemValue === this.dealers[i].id) {
            agent_id = this.dealers[i].agent_id;
          }
        }

        // now that we have the agent ID, find the package and set it,
        // update the DB with the correct values
        for (let i = 0; i < this.packages.length; i += 1) {
          if (id === this.packages[i].id) {
            this.packages[i].agent_id = agent_id;
            checklogApiService.update(
              { value: agent_id, id, item: 'agent_id' },
            )
            .then(() => {
              const parms = {
                id,
                item,
                value,
              };
              checklogApiService.update(parms, this.$router)
              .then(() => {
              })
              .catch((error) => {
                this.error_txt = 'Error Updating Check Log Item: ' + error;
                this.openModal('error');
              });
            })
            .catch((error) => {
              this.error_txt = 'Error Updating Check Log Item: ' + error;
              this.openModal('error');
            });
          }
        }
      } else {
        const parms = {
          id,
          item,
          value,
        };
        checklogApiService.update(parms, this.$router)
        .then(() => {
        })
        .catch((error) => {
          this.error_txt = 'Error Updating Check Log Item: ' + error;
          this.openModal('error');
        });
      }
    },
    deleteRow(id) {
      // loop through each item depending on what type it is
      if (id) {
        checklogApiService.deleteChecklog(id, this.$router)
        .then(() => {
          // TODO, add successful modal
        })
        .catch((error) => {
          this.error_txt = 'Error Deleting Check Log row: ' + error;
          this.openModal('error');
        });
      }
      for (let i = 0; i < this.packages.length; i += 1) {
        if (this.packages[i].id === id) {
          this.packages.splice(i, 1);
          break;
        }
      }
    },
    addPackage() {
      const item = {
        user_id: null,
        status_id: 1, // new
        dealer_id: null,
        agent_id: null,
        mailcarrier_id: null,
        checkNum: 0,
        logdate: moment(this.logdate).format('YYYY-MM-DD'),
        checkdate: null,
        checkamount: 0,
        numcontracts: 0,
        numecontracts: 0,
        numrustcontracts: 0,
        comments: 0,
        postedDate: null,
        cleared: false,
      };
      checklogApiService.addChecklog(item, this.$route)
      .then((result) => {
        this.packages.push(result.data);
      })
      .catch((error) => {
        this.error_txt = 'Error getting packet data from the database: ' + error;
        this.openModal('error');
      });
    },
    copyAgent(row, id) {
      const agent = this.getArrayVal(this.agents, id, 'name');
      this.$copyText(agent).then(() => {
      }, (e) => {
        // console.log(e);
      });
    },
    logdateSelected(date) {
      this.currentdate = date;
      this.loadChecklog();
    },
    beforeGenerate() {
      // compile packages to match what we see on the screen
      this.downloadablePackages = [];
      for (let i = 0; i < this.packages.length; i += 1) {
        // need to loop through every packages ID to get the correct values
        const tmpItem = {
          logdate: moment(this.packages[i].logdate).utc().format('YYYY-MM-DD'),
          users: this.getArrayVal(this.users, this.packages[i].user_id, 'full_name'),
          dealers: this.getArrayVal(this.dealers, this.packages[i].dealer_id, 'name'),
          agent: this.getArrayVal(this.agents, this.packages[i].agent_id, 'name'),
          mailcarrier: this.getArrayVal(this.mailcarriers, this.packages[i].mailcarrier_id, 'name'),
          checkNum: this.packages[i].checkNum,
          checkamount: this.packages[i].checkamount,
          checkdate: moment(this.packages[i].checkdate).utc().format('YYYY-MM-DD'),
          postedDate: moment(this.packages[i].postedDate).utc().format('YYYY-MM-DD'),
          numcontracts: this.packages[i].numcontracts,
          numecontracts: this.packages[i].numecontracts,
          numrustcontracts: this.packages[i].numrustcontracts,
          comments: this.packages[i].comments,
          status: this.getArrayVal(this.statuses, this.packages[i].status_id, 'name'),
        };
        this.downloadablePackages.push(tmpItem);
      }
    },
    customFormatter(date) {
      return moment(date).local().format('YYYY-MM-DD');
    },
    refresh() {
      this.loadChecklog();
    },
    loadChecklog() {
      if (this.loading) {
        return;
      }
      this.spinning = true;
      this.loading = true;
      this.packages = [];
      this.totals = [];
      // convert the date to UTC for a search
      const searchDate = moment(this.currentdate).format('YYYY-MM-DD');
      checklogApiService.getCheckLog(searchDate, this.$router)
      .then((result) => {
        this.packages = result.data.packages;
        this.totals.push({
          totalamount: result.data.totalamount,
          totalcontracts: result.data.totalcontracts,
          totalecontracts: result.data.totalecontracts,
          totalrustcontracts: result.data.totalrustcontracts
        });
        this.warrantyTransfers = result.data.warranty_transfers;
        this.loading = false;

        // loop through the packages and convert to a date object
        // for some odd reason this has to happen
        for (let i = 0; i < this.packages.length; i += 1) {
          if (this.packages[i].postedDate) {
            const postedDate = this.packages[i].postedDate;
            this.packages[i].postedDate = moment(postedDate, 'YYYY-MM-DD').toDate();
          }
          if (this.packages[i].checkdate) {
            const checkdate = this.packages[i].checkdate;
            this.packages[i].checkdate = moment(checkdate, 'YYYY-MM-DD').toDate();
          }
          const logdate = this.packages[i].logdate;
          this.packages[i].logdate = moment(logdate, 'YYYY-MM-DD').toDate();
        }
      })
      .then(() => userApiService.getUserList(this.router))
      .then((userResult) => {
        this.users = userResult;
      })
      .then(() => statusApiService.getStatuses(this.router))
      .then((statusResult) => {
        this.statuses = statusResult;
        this.status = statusResult;
      })
      .then(() => dealerApiService.getDealerList(this.router))
      .then((dealersResult) => {
        const tmpDealers = [];
        for (let i = 0; i < dealersResult.length; i += 1) {
          const newname = `${dealersResult[i].dealer_id} - ${dealersResult[i].name}`;
          const tmpDealer = {
            id: dealersResult[i].id,
            dealer: dealersResult[i].name,
            name: newname,
            agent_id: dealersResult[i].agent_id,
            dealer_id: dealersResult[i].dealer_id,
          };
          tmpDealers.push(tmpDealer);
        }
        this.dealers = tmpDealers;
      })
      .then(() => agentApiService.getAgents(this.router))
      .then((agentsResult) => {
        const tmpAgents = [];
        for (let i = 0; i < agentsResult.length; i += 1) {
          const newname = `${agentsResult[i].num} - ${agentsResult[i].name}`;
          const tmpAgent = {
            id: agentsResult[i].id,
            num: agentsResult[i].num,
            agent: agentsResult[i].name,
            name: newname,
          };
          tmpAgents.push(tmpAgent);
        }
        this.agents = tmpAgents;
      })
      .then(() => mailcarrierAPIService.getMailcarriers('', this.router))
      .then((mailcarriers) => {
        this.mailcarriers = mailcarriers;
        this.spinning = false;
        this.loading = false;
      })
      .catch((error) => {
        this.error_txt = 'Error getting check log data: ' + error;
        this.openModal('error');
        this.spinning = false;
        this.loading = false;
      });
    },
    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {}
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true
    },
  },
  mounted() {
    // get the checklog for today
    this.currentdate = new Date();
    this.loadChecklog();
  },
};
</script>

<style>
.vdp-datepicker input {
  width: 100px;
}

.packetclass:nth-child(even) {
  background-color: lightgray;
}
</style>
