<template>
<div>
  <button class="btn btn-primary" @click="createPCMIContract">Create PCMI Contract</button>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { Dialog } from 'element-ui';
import PCMIHandler from "../../../../../servicehandlers/archived_handlers/PCMIHandler";
const pcmiAPIService = new PCMIHandler();

export default {
  name: "PCMIContract",
  data() {
    return {
      error_txt: null,
      modals: {
        error: false,
      },
    }
  },
  components: {
    [Dialog.name]: Dialog
  },
  methods: {
    createPCMIContract() {
      const parms = {
      };
      pcmiAPIService.createContract(parms, this.$router)
      .then((response) => {
        // console.log('Response: ', response);
      })
      .catch((error) => {
        // console.log('Error: ', error);
      });
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true
    },
  }
}
</script>

<style scoped>

</style>
