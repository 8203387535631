import { CommonAPIService } from '../CommonHandler';

export class MailcarrierAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getMailcarriers(mailcarrier, router) {
    const url = '/api/mailcarriers';
    return this.CommonAPIService.getCall(url, '', router);
  }
}

export default MailcarrierAPIService;
