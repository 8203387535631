<template>
  <div>
    <card v-if="spinning">
      <b-row class="text-center">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4 text-center">
          <datepicker
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="Start Date"
            v-model="start_date">
          </datepicker>
        </div>
        <div class="col-sm-4 text-center">
          <datepicker
            :bootstrap-styling="true"
            :format="customFormatter"
            placeholder="End Date"
            v-model="end_date">
          </datepicker>
        </div>
        <div class="col-sm-4 text-center">
          <label>Select an Insurance Carrier:</label>
          <select class="form-control" v-model="carrier">
            <option value="ALL">ALL</option>
            <option value="D-FI">US Bank</option>
            <option value="VS">Virginia Surety</option>
            <option value="JMA">JMA</option>
            <option value="ORC">Old Republic</option>
            <option value="NC">NOVA</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8 text-center">
          <button class="btn btn-primary" @click="getClaimData">Get Claim Data</button>
        </div>
      </div>
    </card>
    <card v-if="is_ran">
      <div class="row">
        <div class="col-sm-8 text-center">
          <download-excel
            class="btn btn-primary"
            :data="reportData"
            type="xls"
            worksheet="Claim Data"
            name="ClaimData.xls">

            Download Excel
          </download-excel>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2" style="font-weight: bold;">
          Contract #
        </div>
        <div class="col-sm-2" style="font-weight: bold;">
          Claim #
        </div>
        <div class="col-sm-1" style="font-weight: bold;">
          Claim Status
        </div>
        <div class="col-sm-1" style="font-weight: bold;">
          Date Paid
        </div>
        <div class="col-sm-1" style="font-weight: bold;">
          Date Loss Occurred
        </div>
        <div class="col-sm-1" style="font-weight: bold;">
          Claim Entry Date
        </div>
        <div class="col-sm-1" style="font-weight: bold;">
          Issue State
        </div>
        <div class="col-sm-1" style="font-weight: bold;">
          Total Paid
        </div>
        <div class="col-sm-1" style="font-weight: bold;">
          Insurance Carrier
        </div>
        <div class="col-sm-1" style="font-weight: bold;">
          Contract Sale Date
        </div>
      </div>
      <div class="row" v-for="item in reportData" v-bind:key="item['sclaim_number']">
        <div class="col-sm-2">
          {{ item['Contract #'] }}
        </div>
        <div class="col-sm-2">
          {{ item['Claim #'] }}
        </div>
        <div class="col-sm-1">
          {{ item['Claim Status'] }}
        </div>
        <div class="col-sm-1">
          {{ item['Payment Processed Date'] }}
        </div>
        <div class="col-sm-1">
          {{ item['Date Loss Occurred'] }}
        </div>
        <div class="col-sm-1">
          {{ item['Claim System Entry Date'] }}
        </div>
        <div class="col-sm-1">
          {{ item['Issue State'] }}
        </div>
        <div class="col-sm-1">
          {{ item['Total Paid'] }}
        </div>
        <div class="col-sm-1">
          {{ item['Insurance Carrier'] }}
        </div>
        <div class="col-sm-1">
          {{ item['Contract Sale Date'] }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-9"></div>
        <div class="col-sm-1"><span style="font-weight: bold;">Total: </span>{{ total_paid }}</div>
        <div class="col-sm-2"></div>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{ success_txt }}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vue-moment-datepicker';
import ReportsAPIHandler from "../../../../../servicehandlers/ReportsAPIHandler";
import { Dialog } from 'element-ui';

const reportsAPIHandler = new ReportsAPIHandler();

export default {
  components: {
    Datepicker,
    [Dialog.name]: Dialog,
  },
  name: "PCMIClaims",
  data() {
    return {
      spinning: false,
      reportData: [],
      is_ran: false,
      totalCount: 0,
      start_date: null,
      end_date: null,
      success_txt: null,
      error_txt: null,
      carrier: 'ALL',
      total_paid: 0,
      modals: {
        basic: false,
        error: false,
        success: false,
        files: false,
      },
      json_fields: {
        'Contract #': 'Contract #',
        'Contract Sequence': 'Contract Sequence',
        'Claim #': 'Claim #',
        'Claim Status': 'Claim Status',
        'Claim Type': 'Claim Type',
        'Date Paid': 'Date Paid',
        'Date Loss Occurred': 'Date Loss Occurred',
        'Claim System Entry Date': 'Claim System Entry Date',
        'Check #': 'Check #',
        'Business Partner Book': 'Business Partner Book',
        'Issue State': 'Issue State',
        'Vehicle Purchase Date': 'Vehicle Purchase Date',
        'Payment Date': 'Payment Date',
        'Contract Sale Date': 'Contract Sale Date',
        'Contract Effective Date': 'Contract Effective Date',
        'Total Paid': 'Total Paid',
        'Parts Paid': 'Parts Paid',
        'Labor Paid': 'Labor Paid',
        'Deductible Paid': 'Deductible Paid',
        'Trip Paid': 'Trip Paid',
        'Shipping Amount': 'Shipping Amount',
        'Miscellaneous Paid': 'Miscellaneous Paid',
        'Tax Paid': 'Tax Paid',
        'Tech Admin': 'Tech Admin',
        'Base Admin': 'Base Admin',
        'Salvage Amount': 'Salvage Amount',
        'Subro Amount': 'Subro Amount',
        'Misc Admin': 'Misc Admin',
        'Misc Admin2': 'Misc Admin2',
        'Payment Code': 'Payment Code',
        'Contract type': 'Contract Type',
        'Priority': 'Priority',
        'Insurance Carrier': 'Insurance Carrier'
      }
    }
  },
  methods: {
    getClaimData() {
      this.total_paid = 0;
      this.reportData = [];
      if (!this.start_date) {
        this.error_txt = 'No start date!';
        this.openModal('error');
        return;
      }
      if (!this.end_date) {
        this.error_txt = 'No end date!';
        this.openModal('error');
        return;
      }
      if (!this.carrier) {
        this.error_txt = 'No carrier!';
        this.openModal('error');
        return;
      }
      this.is_ran = false;
      this.spinning = true;
      const start_date = moment(this.start_date).format('YYYY-MM-DD 00:00:00');
      const end_date = moment(this.end_date).format('YYYY-MM-DD 23:59:59');
      const parms = {
        start_date: start_date,
        end_date: end_date,
        carrier: this.carrier,
      };
      reportsAPIHandler.getClaimData(parms, this.$route)
      .then((resp) => {
        this.reportData = [];
        const keys = Object.keys(resp);
        for (const key of keys) {
          const item = resp[key];
          this.total_paid += parseFloat(item['Total Paid']);
          this.reportData.push(item);
        }
        this.total_paid = this.total_paid.toFixed(2);
        this.is_ran = true;
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'error: ' + error;
        this.openModal('error');
      });
    },
    customFormatter(date) {
      return moment(date).utc().format('YYYY-MM-DD');
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
  }
}
</script>

<style scoped>

</style>
