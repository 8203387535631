<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-md-12 text-center">
          <b-spinner variant="primary" label="Spinning">
          </b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-md-12 text-center">
          <span style="font-size: 2em; font-weight: bold;">Check Log Search</span>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-md-2">
          <div class="row">
            <div class="col-sm-12">
              <label>Owner</label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <v-select
                :options="users"
                label="full_name"
                :searchable="true"
                :reduce="users => users.id"
                v-model="searchParams.user_id">
              </v-select>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <label>Dealer</label>
          <v-select
            :options="dealers"
            label="name"
            :searchable="true"
            :reduce="dealers => dealers.id"
            v-model="searchParams.dealer_id">
          </v-select>
        </div>
        <div class="col-md-2">
          <label>Agent</label>
          <v-select
            :options="agents"
            label="name"
            :searchable="true"
            :reduce="agents => agents.id"
            v-model="searchParams.agent_id">
          </v-select>
        </div>
        <div class="col-md-2">
          <label>Mail Carrier</label>
          <v-select
            :options="mailcarriers"
            label="name"
            :searchable="true"
            :reduce="mailcarriers => mailcarriers.id"
            v-model="searchParams.mailcarrier_id">
          </v-select>
        </div>
        <div class="col-md-2">
          <label>Status</label>
          <v-select
            :options="status"
            label="name"
            :searchable="true"
            :reduce="status => status.id"
            v-model="searchParams.status_id">
          </v-select>
        </div>
        <div class="col-md-2">
          <div class="row">
            <div class="col-md-12">
              <label>Check Search</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <input type="text" class="form-control-sm"
                     v-model="searchParams.checkNum" placeholder="Check Number"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <input type="text" class="form-control-sm" placeholder="Check Amount"
                     v-model="searchParams.checkAmount"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <button @click="searchPackets" class="btn btn-primary">
            Search
          </button>
        </div>
      </div>
    </card>
    <card>
      <b-row>
        <b-col>
          <h1>Packages</h1>
          <b-pagination
            :total-rows="totalRecords"
            :per-page="perPage"
            v-model="currentPage">
          </b-pagination>
          <!--Headers-->
          <div class="row">
            <div class="col-sm-1 text-center">
              <span style="font-weight: bold; font-size: 1em;">Delete</span>
            </div>
            <div class="col-sm-5 text-center">
              <span style="font-weight: bold; font-size: 1em;">Dealers & Agents</span>
            </div>
            <div class="col-sm-3 text-center">
              <span style="font-weight: bold; font-size: 1em;">Packet Info</span>
            </div>
            <div class="col-sm-2 text-center">
              <span style="font-weight: bold; font-size: 1em;">Check Info</span>
            </div>
            <div class="col-sm-1 text-center">
              <span style="font-weight: bold; font-size: 1em;">Additional</span>
            </div>
          </div>
          <!--End of Headers-->

          <div class="row packetclass pt-2 pb-2" v-for="pck of packages" v-bind:key="pck.id">
            <!--Beginning of for loop-->
            <div class="col-sm-1 text-center">
              <div class="row">
                <div class="col-md-12">
                  {{ pck.logdate }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button v-on:click="deleteRow(pck.id)" class="btn btn-danger">-</button>
                </div>
              </div>
            </div>


            <!--Dealers and agents row area-->
            <div class="col-sm-5 text-center">
              <div class="row">
                <div class="col-sm-2">
                  <label>Dealer</label>
                </div>
                <div class="col-sm-8">
                  <v-select
                    :options="dealers"
                    label="name"
                    @input="setItem(pck.id, 'dealer_id', pck.dealer_id)"
                    :searchable="true"
                    :reduce="dealers => dealers.id"
                    v-model="pck.dealer_id">
                  </v-select>
                </div>
                <div class="col-sm-2">
                  <button type="button" @click="copyDealer(pck, pck.dealer_id)">Copy</button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-2">
                  <label>Agent</label>
                </div>
                <div class="col-sm-8">
                  <v-select
                    :options="agents"
                    label="name"
                    @input="setItem(pck.id, 'agent_id', pck.agent_id)"
                    :searchable="true"
                    :reduce="agents => agents.id"
                    v-model="pck.agent_id">
                  </v-select>
                </div>
                <div class="col-sm-2">
                  <button type="button" @click="copyAgent(pck, pck.agent_id)">Copy</button>
                </div>
              </div>
            </div>

            <!--Begin of packet info-->
            <div class="col-sm-3 text-center">
              <div class="row text-center pb-2">
                <div class="col-sm-3 text-center">
                  <label>Owner</label>
                </div>
                <div class="col-sm-9 text-center">
                  <v-select
                    :options="users"
                    @input="setItem(pck.id, 'user_id', pck.user_id)"
                    label="full_name"
                    :searchable="true"
                    :reduce="users => users.id"
                    v-model="pck.user_id">
                  </v-select>
                </div>
              </div>
              <div class="row text-center pb-2">
                <div class="col-sm-3 text-center">
                  <label>Status</label>
                </div>
                <div class="col-sm-9 text-center">
                  <v-select
                    :options="status"
                    label="name"
                    @input="setItem(pck.id, 'status_id', pck.status_id)"
                    :searchable="true"
                    :reduce="status => status.id"
                    v-model="pck.status_id">
                  </v-select>
                </div>
              </div>
              <div class="row text-center pb-2">
                <div class="col-sm-3 text-center">
                  <label>Received Via</label>
                </div>
                <div class="col-sm-9 text-center">
                  <v-select
                    :options="mailcarriers"
                    label="name"
                    @input="setItem(pck.id, 'mailcarrier_id', pck.mailcarrier_id)"
                    :searchable="true"
                    :reduce="mailcarriers => mailcarriers.id"
                    v-model="pck.mailcarrier_id">
                  </v-select>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-1">
                  <label>CCnt:&nbsp;</label>
                </div>
                <div class="col-sm-3">
                  <input type="number" id="numcontracts" class="form-control-sm"
                         style="width:4em"
                         min="0"
                         @change="setItem(pck.id, 'numcontracts', pck.numcontracts)"
                         v-model="pck.numcontracts"/>
                </div>
                <div class="col-sm-1">
                  <label>ECnt:&nbsp;</label>
                </div>
                <div class="col-sm-3">
                  <input type="number" id="numecontracts" class="form-control-sm"
                         style="width:4em"
                         @change="setItem(pck.id, 'numecontracts', pck.numecontracts)"
                         v-model="pck.numecontracts"/>
                </div>
                <div class="col-sm-1">
                  <label>Rust:&nbsp;</label>
                </div>
                <div class="col-sm-3">
                  <input type="number" id="numrustcontracts" class="form-control-sm"
                         style="width:4em"
                         @change="setItem(pck.id, 'numrustcontracts', pck.numrustcontracts)"
                         v-model="pck.numrustcontracts"/>
                </div>
              </div>
            </div>

            <div class="col-sm-2 text-center">
              <div class="row">
                <div class="col-sm-4 text-center">
                  <label>Check Date</label>
                </div>
                <div class="col-sm-8 text-center">
                  <datepicker
                    :bootstrap-styling="true"
                    @input="setItem(pck.id, 'checkdate', pck.checkdate)"
                    placeholder="Check Date"
                    v-model="pck.checkdate">
                  </datepicker>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4 text-center">
                  <label>Posted Date</label>
                </div>
                <div class="col-sm-8 text-center">
                  <datepicker
                    :bootstrap-styling="true"
                    placeholder="Posted Date"
                    @input="setItem(pck.id, 'postedDate', pck.postedDate)"
                    v-model="pck.postedDate">
                  </datepicker>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4 text-center">
                  <label>Check Number</label>
                </div>
                <div class="col-sm-8">
                  <input type="text" class="form-control"
                         min="0"
                         @change="setItem(pck.id, 'checkNum', pck.checkNum)"
                         v-model="pck.checkNum"/>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4 text-center">
                  <label>Check Amount</label>
                </div>
                <div class="col-sm-8">
                  <input type="text" class="form-control"
                         min="0"
                         @change="setItem(pck.id, 'checkamount', pck.checkamount)"
                         v-model="pck.checkamount"/>
                </div>
              </div>
            </div>


            <!--Additional Information-->
            <div class="col-sm-1 text-center">
              <div class="row">
                <div class="col-md-12">
                  <b-button variant="primary"
                            v-b-modal="'myModal' + pck.id">Show Comments
                  </b-button>
                  <b-modal :id="'myModal' + pck.id"
                           size="lg"
                           title="Comments"
                           @ok="setItem(pck.id,
                              'comments',
                              pck.comments)"
                           centered>
                 <textarea rows="10" cols="50"
                           name="comments"
                           placeholder="comments"
                           v-model="pck.comments">
                </textarea>
                  </b-modal>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <button class="btn btn-primary" @click="createDealerForm(pck)">Generate Form</button>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </card>
    <el-dialog
      center
      :before-close="handleClose"
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { Dialog } from 'element-ui';
import Datepicker from 'vuejs-datepicker';
import { ChecklogAPIService } from 'src/servicehandlers/archived_handlers/ChecklogHandler';
import { UserAPIService } from 'src/servicehandlers/archived_handlers/UserHandler';
import { StatusAPIService } from 'src/servicehandlers/archived_handlers/StatusHandler';
import { DealerAPIService } from 'src/servicehandlers/archived_handlers/DealerHandler';
import { AgentAPIService } from 'src/servicehandlers/archived_handlers/AgentHandler';
import { MailcarrierAPIService } from 'src/servicehandlers/archived_handlers/MailcarrierHandler';

moment.prototype.toMySqlDateTime = function () {
  return this.format('YYYY-MM-DD');
};

const checklogApiService = new ChecklogAPIService();
const userApiService = new UserAPIService();
const statusApiService = new StatusAPIService();
const dealerApiService = new DealerAPIService();
const agentApiService = new AgentAPIService();
const mailcarrierAPIService = new MailcarrierAPIService();

export default {
  name: "CheckLogSearch",
  components: {
    Datepicker,
    [Dialog.name]: Dialog
  },
  data() {
    return {
      spinning: false,
      currentdate: null,
      packages: [],
      totalRecords: 0,
      perPage: 50,
      currentPage: 1,
      searchParams: {
        mailcarrier_id: null,
        user_id: null,
        checkNum: null,
        checkAmount: null,
        agent_id: null,
        dealer_id: null,
        status_id: null,
      },
      downloadablePackages: [],
      mailcarriers: [],
      status: [],
      error_txt: '',
      dealers: [],
      agents: [],
      totals: [],
      users: [],
      modals: {
        error: false,
      },
      fields: [
        { key: 'users', label: 'Owner', sortable: false },
        { key: 'dealers', label: 'Dealer', sortable: true },
        { key: 'agent', label: 'Agent', sortable: true },
        { key: 'mailcarrier', label: 'Received Via', sortable: true },
        { key: 'checkNum', label: 'Check Num', sortable: true },
        { key: 'dates', label: 'Checks', sortable: true },
        { key: 'checkamount', label: 'Check Amnt', sortable: true },
        { key: 'nums', label: 'Counts', sortable: true },
        { key: 'comments', label: 'Comments', sortable: true },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'generate', label: 'Form', sortable: false },
        { key: 'delete', label: 'Delete', sortable: false },
      ],
      totalsFields: [
        { key: 'totalamount', label: 'Total Check Amount', sortable: true },
        { key: 'totalcontracts', label: 'Total Contracts', sortable: true },
        { key: 'totalecontracts', label: 'Total EContracts', sortable: true },
        { key: 'totalrustcontracts', label: 'Total Rust', sortable: true },
      ],
      json_fields: {
        Owner: 'users',
        Dealer: 'dealers',
        Agent: 'agent',
        'Received Via': 'mailcarrier',
        'Check Num': 'checkNum',
        'Check Amnt': 'checkamount',
        'Check Date': 'checkdate',
        'Posted Date': 'postedDate',
        Contracts: 'numcontracts',
        Econtracts: 'numecontracts',
        'Rust Contracts': 'numrustcontracts',
        Comments: 'comments',
        Status: 'status',
      },
    };
  },
  watch: {
    currentPage() {
      this.packages = [];
      this.searchPackets();
    },
  },
  mounted() {
    // get the checklog for today
    this.currentdate = new Date();
    this.getPackageData();
  },
  methods: {
    getPackageData() {
      this.spinning = true;
      // convert the date to UTC for a search
      // const searchDate = moment(this.currentdate).format('YYYY-MM-DD');
      return userApiService.getUserList(this.$router)
      .then((userResult) => {
        this.users = userResult;
      })
      .then(() => statusApiService.getStatuses(this.$router))
      .then((statusResult) => {
        this.status = statusResult;
      })
      .then(() => dealerApiService.getDealerList(this.$router))
      .then((dealersResult) => {
        const tmpDealers = [];
        for (let i = 0; i < dealersResult.length; i += 1) {
          const newname = `${dealersResult[i].dealer_id} - ${dealersResult[i].name}`;
          const tmpDealer = {
            id: dealersResult[i].id,
            dealer: dealersResult[i].name,
            name: newname,
            agent_id: dealersResult[i].agent_id,
            dealer_id: dealersResult[i].dealer_id,
          };
          tmpDealers.push(tmpDealer);
        }
        this.dealers = tmpDealers;
      })
      .then(() => agentApiService.getAgents(this.$router))
      .then((agentsResult) => {
        const tmpAgents = [];
        for (let i = 0; i < agentsResult.length; i += 1) {
          const newname = `${agentsResult[i].num} - ${agentsResult[i].name}`;
          const tmpAgent = {
            id: agentsResult[i].id,
            num: agentsResult[i].num,
            agent: agentsResult[i].name,
            name: newname,
          };
          tmpAgents.push(tmpAgent);
        }
        this.agents = tmpAgents;
      })
      .then(() => mailcarrierAPIService.getMailcarriers('', this.$router))
      .then((mailcarriers) => {
        this.mailcarriers = mailcarriers;
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Error getting check log data: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    customFormatter(date) {
      return moment(date).local().format('YYYY-MM-DD');
    },
    getArrayVal(arr, id, column) {
      let rv = '';
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i].id === id) {
          const item = arr[i];
          rv = item[column];
          break;
        }
      }
      return rv;
    },
    copyDealer(row, id) {
      const dealer = this.getArrayVal(this.dealers, id, 'name');
      this.$copyText(dealer).then(() => {
      }, (e) => {
        // console.log(e);
      });
    },
    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {}
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true
    },
    copyAgent(row, id) {
      const agent = this.getArrayVal(this.agents, id, 'name');
      this.$copyText(agent).then(() => {
      }, (e) => {
        // console.log(e);
      });
    },
    setItem(id, item, itemValue) {
      let value = itemValue;
      if (item === 'checkdate' || item === 'postedDate') {
        value = moment(itemValue).format('YYYY-MM-DD');
      }
      if (item === 'dealer_id') {
        // find the package ID and get the agent ID that we're supposed to have
        let agent_id = null;
        for (let i = 0; i < this.dealers.length; i += 1) {
          // find the dealer's agent, set it in the package
          if (itemValue === this.dealers[i].id) {
            agent_id = this.dealers[i].agent_id;
          }
        }

        // now that we have the agent ID, find the package and set it,
        // update the DB with the correct values
        for (let i = 0; i < this.packages.length; i += 1) {
          if (id === this.packages[i].id) {
            this.packages[i].agent_id = agent_id;
            checklogApiService.update(
              { value: agent_id, id, item: 'agent_id' },
            )
            .then(() => {
              const parms = {
                id,
                item,
                value,
              };
              checklogApiService.update(parms, this.$router)
              .then(() => {
              })
              .catch((error) => {
                this.error_txt = 'Error Updating Check Log Item: ' + error;
                this.openModal('error');
              });
            })
            .catch((error) => {
              this.error_txt = 'Error Updating Check Log Item: ' + error;
              this.openModal('error');
            });
          }
        }
      } else {
        const parms = {
          id,
          item,
          value,
        };
        checklogApiService.update(parms, this.$router)
        .then(() => {
        })
        .catch((error) => {
          this.error_txt = 'Error Updating Check Log Item: ' + error;
          this.openModal('error');
        });
      }
    },
    searchPackets() {
      this.packages = [];
      this.spinning = true;
      // construct the search object
      const searchString = {
        where: {
        }
      };
      if (this.searchParams.mailcarrier_id) {
        searchString.where.mailcarrier_id = this.searchParams.mailcarrier_id;
      }
      if (this.searchParams.status_id) {
        searchString.where.status_id = this.searchParams.status_id;
      }
      if (this.searchParams.user_id) {
        searchString.where.user_id = this.searchParams.user_id;
      }
      if (this.searchParams.dealer_id) {
        searchString.where.dealer_id = this.searchParams.dealer_id;
      }
      if (this.searchParams.agent_id) {
        searchString.where.agent_id = this.searchParams.agent_id;
      }
      if (this.searchParams.checkNum) {
        searchString.where.checkNum = this.searchParams.checkNum;
      }
      if (this.searchParams.checkAmount) {
        searchString.where.checkamount = this.searchParams.checkAmount;
      }

      searchString.currentPage = this.currentPage;
      searchString.perPage = this.perPage;

      checklogApiService.searchCheckLog(searchString, this.$router)
      .then((result) => {
        this.totalRecords = result.count;
        this.packages = result.packages;

        // loop through the packages and convert to a date object
        // for some odd reason this has to happen
        for (let i = 0; i < this.packages.length; i += 1) {
          if (this.packages[i].postedDate) {
            const postedDate = this.packages[i].postedDate;
            this.packages[i].postedDate = moment(postedDate, 'YYYY-MM-DD').toDate();
          }
          if (this.packages[i].checkdate) {
            const checkdate = this.packages[i].checkdate;
            this.packages[i].checkdate = moment(checkdate, 'YYYY-MM-DD').toDate();
          }
          const logdate = this.packages[i].logdate;
          this.packages[i].logdate = moment(logdate, 'YYYY-MM-DD').toDate();
        }
        this.spinning = false;
      })
      .catch((error) => {
        this.error_txt = 'Error searching check log data: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
