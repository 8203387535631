<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              {{ title }}
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="form-group">
                <b-container>
                <b-row>
                  <label for="subject">Subject</label>
                  <input class="form-control"
                         type="text"
                         name="subject"
                         id="subject"
                         :value="subject">
                </b-row>
                <b-row>
                  <label for="message">Message</label>
                  <textarea class="form-control" id="message" name="message" width="400"
                            height="400"
                            :value="message">
                  </textarea>
                </b-row>
              </b-container>
              </div>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button" @click="close">
                Cancel
              </button>
              <button class="modal-default-button" @click="emailScheduler">
                Send Email
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ContractAPIService from 'src/servicehandlers/ContractHandler';

const contractAPIService = new ContractAPIService();

export default {
  name: 'EmailModal',
  props: {
    id: Number,
    type: String,
    title: String,
    message: String,
    subject: String,
    default() {
      return [];
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    emailScheduler() {
      contractAPIService.emailDetailer(this.id, this.subject, this.message, this.$router)
        .then(() => {
          this.$emit('close');
        })
        .catch((error) => {
          // console.log('ERROR: ', error);
        });
    },
  },
};
</script>

<style>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 500px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
